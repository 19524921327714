import { LatLng, Membership } from 'shared/types';
import {
  mapboxTopoStyle,
  mapboxSatelliteStyle,
  mapboxStreetStyle,
} from './styles/mapboxStyles';

// Types defined here because of circular dependency
export type MapLayer = (typeof MapLayers)[keyof typeof MapLayers];

export type MapLayerDetail = {
  titleKey: string;
  subtitleKey: string;
  requiresWebGL2: boolean;
  imageSrc?: string;
  internalOnly?: boolean;
  minMembership?: Membership;
};

const activeTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DEFAULT_CALIFORNIA: LatLng = { lat: 37.5771382, lng: -120.7517127 };
const DEFAULT_HAWAII: LatLng = { lat: 20.7148175, lng: -156.6114244 };

export const DEFAULT_LATLNG: LatLng =
  activeTimezone === 'Pacific/Honolulu' ? DEFAULT_HAWAII : DEFAULT_CALIFORNIA;

export const DEFAULT_ZOOM = 6;

// These values are used both for display and persistance values, so do not change!
export const MapBaseLayerNames = {
  STREET: 'Street Map',
  SATELLITE: 'Satellite Map',
  TOPOGRAPHIC: 'Topographic Map',
} as const;

export const MapBaseLayerStyles = {
  [MapBaseLayerNames.STREET]: mapboxStreetStyle,
  [MapBaseLayerNames.SATELLITE]: mapboxSatelliteStyle,
  [MapBaseLayerNames.TOPOGRAPHIC]: mapboxTopoStyle,
} as const;

export const MapLayers = {
  ACTIVE_FIRE_PERIMETERS: 'ActiveFirePerimetersLayer',
  HISTORICAL_FIRE_PERIMETERS: 'HistoricalFirePerimetersLayer',
  MODIS: 'ModisLayer',
  VIIRS: 'ViirsLayer',
  SMOKE_COVERAGE: 'SmokeCoverageLayer',
  PURPLE_AIR: 'PurpleAir',
  SURFACE_WIND: 'Surface Wind',
  FLIGHT_TRACKER: 'FlightTracker',
  RADIO_TOWERS: 'RadioTowers',
  RED_FLAG_WARNINGS: 'RedFlagWarnings',
  POWER_OUTAGES: 'PowerOutages',
  CAMERAS: 'Cameras',
  EVACUATION_ZONES: 'EvacuationZones',
  ELECTRICAL_LINES: 'ElectricalLines',
  GAS_PIPELINES: 'GasPipelines',
  EXTERNAL_GEO_EVENTS: 'ExternalGeoEvents',
  FEDERAL_STATE_LOCAL: 'FederalStateLocal',
  RESPONSIBILITY_AREAS: 'ResponsibilityAreas',
  ELECTRIC_RETAIL_SERVICE: 'ElectricRetailService',
  PRIVATE_LAND_OWNERSHIP: 'PrivateLandOwnership',
  RIVER_GAUGES: 'RiverGauges',
} as const;

export const MapLayerAttributions = {
  [MapLayers.ACTIVE_FIRE_PERIMETERS]: 'NIFC, FIRIS',
  [MapLayers.HISTORICAL_FIRE_PERIMETERS]: 'CalFire',
  [MapLayers.MODIS]: 'NASA',
  [MapLayers.VIIRS]: 'NASA',
  [MapLayers.SMOKE_COVERAGE]: 'NOAA',
  [MapLayers.PURPLE_AIR]:
    '<a href="https://www2.purpleair.com/?ref=esYmAhN1F4yWLv">PurpleAir</a>',
  [MapLayers.SURFACE_WIND]:
    'NOAA via <a href="https://weatherlayers.com/">WeatherLayers</a>',
  [MapLayers.RADIO_TOWERS]: 'CalFire',
  [MapLayers.FLIGHT_TRACKER]:
    '<a href="https://www.adsbexchange.com/">ADS-B Exchange</a>',
  [MapLayers.POWER_OUTAGES]: 'Cal OES',
} as const;

// Map layers that are visually busy. This tends to be used with the
// `disableMapLayers` attribute on the <Map> component for certain pages.
export const VisuallyBusyMapLayers = [
  MapLayers.SMOKE_COVERAGE,
  MapLayers.PURPLE_AIR,
];

// Map layers we want to disable when in a reporter form
export const DISABLE_MAP_LAYERS_FOR_FORMS = [
  MapLayers.SMOKE_COVERAGE,
  MapLayers.PURPLE_AIR,
  MapLayers.EVACUATION_ZONES,
];

export const AllMapLayers = Object.values(MapLayers);

export const MapLayersDetails: Record<
  Exclude<MapLayer, 'SmokeCoverageLayer'>,
  MapLayerDetail
> = {
  [MapLayers.ACTIVE_FIRE_PERIMETERS]: {
    titleKey: 'map.layers.activeFirePerimeters.title',
    subtitleKey: 'map.layers.activeFirePerimeters.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/fire-perimeters-active-thumb.png',
  },
  [MapLayers.HISTORICAL_FIRE_PERIMETERS]: {
    titleKey: 'map.layers.historicalFirePerimeters.title',
    subtitleKey: 'map.layers.historicalFirePerimeters.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/fire-perimeters-historical-thumb.png',
  },
  [MapLayers.VIIRS]: {
    titleKey: 'map.layers.viirs.title',
    subtitleKey: 'map.layers.viirs.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/satellite-hotspots-viirs-thumb.png',
  },
  [MapLayers.MODIS]: {
    titleKey: 'map.layers.modis.title',
    subtitleKey: 'map.layers.modis.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/satellite-hotspots-modis-thumb.png',
  },
  [MapLayers.SURFACE_WIND]: {
    titleKey: 'map.layers.surfaceWind.title',
    subtitleKey: 'map.layers.surfaceWind.subtitle',
    requiresWebGL2: true,
  },
  [MapLayers.PURPLE_AIR]: {
    titleKey: 'map.layers.purpleAir.title',
    subtitleKey: 'map.layers.purpleAir.subtitle',
    requiresWebGL2: false,
  },
  [MapLayers.FLIGHT_TRACKER]: {
    titleKey: 'map.layers.flightTracker.title',
    subtitleKey: 'map.layers.flightTracker.subtitle',
    requiresWebGL2: false,
    minMembership: 'regular',
  },
  [MapLayers.RADIO_TOWERS]: {
    titleKey: 'map.layers.radioTowers.title',
    subtitleKey: 'map.layers.radioTowers.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/radio-repeaters-thumb.png',
    minMembership: 'pro',
  },
  [MapLayers.RED_FLAG_WARNINGS]: {
    titleKey: 'map.layers.redFlagWarnings.title',
    subtitleKey: 'map.layers.redFlagWarnings.subtitle',
    requiresWebGL2: false,
  },
  [MapLayers.POWER_OUTAGES]: {
    titleKey: 'map.layers.powerOutages.title',
    subtitleKey: 'map.layers.powerOutages.subtitle',
    requiresWebGL2: false,
  },
  [MapLayers.CAMERAS]: {
    titleKey: 'map.layers.cameras.title',
    subtitleKey: 'map.layers.cameras.subtitle',
    requiresWebGL2: false,
  },
  [MapLayers.EVACUATION_ZONES]: {
    titleKey: 'map.layers.evacuationZones.title',
    subtitleKey: 'map.layers.evacuationZones.subtitle',
    requiresWebGL2: false,
    minMembership: 'pro',
  },
  [MapLayers.ELECTRICAL_LINES]: {
    titleKey: 'map.layers.electricalLines.title',
    subtitleKey: 'map.layers.electricalLines.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/electrical-lines-active-thumb.png',
    minMembership: 'pro',
  },
  [MapLayers.GAS_PIPELINES]: {
    titleKey: 'map.layers.gasPipelines.title',
    subtitleKey: 'map.layers.gasPipelines.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/gas-pipelines-active-thumb.png',
    minMembership: 'pro',
  },
  [MapLayers.EXTERNAL_GEO_EVENTS]: {
    titleKey: 'map.layers.externalGeoEvents.title',
    subtitleKey: 'map.layers.externalGeoEvents.subtitle',
    requiresWebGL2: false,
    internalOnly: true,
  },
  [MapLayers.FEDERAL_STATE_LOCAL]: {
    titleKey: 'map.layers.federalStateLocal.title',
    subtitleKey: 'map.layers.federalStateLocal.subtitle',
    requiresWebGL2: false,
    minMembership: 'pro',
    imageSrc: '/images/federal-land-ownership-thumb.png',
  },
  [MapLayers.RESPONSIBILITY_AREAS]: {
    titleKey: 'map.layers.responsibilityAreas.title',
    subtitleKey: 'map.layers.responsibilityAreas.subtitle',
    requiresWebGL2: false,
    minMembership: 'pro',
    imageSrc: '/images/ca-responsibility-thumb.png',
  },
  [MapLayers.ELECTRIC_RETAIL_SERVICE]: {
    titleKey: 'map.layers.electricRetailService.title',
    subtitleKey: 'map.layers.electricRetailService.subtitle',
    requiresWebGL2: false,
    minMembership: 'pro',
    imageSrc: '/images/electric-retail-territory-thumb.png',
  },
  [MapLayers.PRIVATE_LAND_OWNERSHIP]: {
    titleKey: 'map.layers.privateLandOwnership.title',
    subtitleKey: 'map.layers.privateLandOwnership.subtitle',
    requiresWebGL2: false,
    imageSrc: '/images/private-land-ownership-thumb.png',
    minMembership: 'pro',
  },
  [MapLayers.RIVER_GAUGES]: {
    titleKey: 'map.layers.riverGauges.title',
    subtitleKey: 'map.layers.riverGauges.subtitle',
    requiresWebGL2: false,
  },
} as const;

export const PlaceMapMarkerLabelColor = '#1F1F1F';

export const FILL_PATTERN_LAYER_MIN_ZOOM = 10;

export const MOBILE_LAYER_DRAWER_HEIGHT = 610;
export const MOBILE_LAYER_DRAWER_HEIGHT_USER = 670;

export const LAYERS_DRAWER_WIDTH = 375;

export const LAYER_GROUPS = {
  firePerimeters: [
    MapLayers.ACTIVE_FIRE_PERIMETERS,
    MapLayers.HISTORICAL_FIRE_PERIMETERS,
  ],
  satelliteHotspots: [MapLayers.VIIRS, MapLayers.MODIS],
  professional: [MapLayers.EXTERNAL_GEO_EVENTS],
  federalLands: [
    MapLayers.FEDERAL_STATE_LOCAL,
    MapLayers.RESPONSIBILITY_AREAS,
    MapLayers.ELECTRIC_RETAIL_SERVICE,
    MapLayers.PRIVATE_LAND_OWNERSHIP,
  ],
  criticalInfrastructure: [
    MapLayers.ELECTRICAL_LINES,
    MapLayers.GAS_PIPELINES,
    MapLayers.RADIO_TOWERS,
  ],
} as const;

export const MAIN_REFETCH_INTERVAL_MS = 1000 * 60 * 2; // 2 minutes

export const LOCATION_ZOOM_LEVEL = 13; // ~2000 ft

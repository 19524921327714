import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'state';
import { HeaderBar } from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import AccountContent from './AccountContent';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuthState();

  return (
    <>
      <Helmet>
        <title>{t('myAccount.title')}</title>
      </Helmet>
      {user && <AccountContent user={user} />}
    </>
  );
};

const Account = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="AccountPage" data-testid="AccountPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar action="/" title={t('myAccount.title')} />,
      }}
    </PageLayout>
  );
};

export default Account;

import { memo, useRef } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Box,
  Link as MuiLink,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'state';
import { Report, ReportMedia } from 'shared/types';
import RichTextContent from 'components/RichTextContent';
import ReportMetadata from './ReportMetadata';
import CardEmbed from './CardEmbed';

type ReportCardProps = {
  media: ReportMedia | null;
  report: Report;
  className?: string;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: 0,
  },
  message: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    lineHeight: theme.typography.pxToRem(22),
  },
  image: {
    width: '100%',
    minHeight: 250,
    maxHeight: 485,
    objectFit: 'contain',
    backgroundColor: theme.palette.grey[300],
  },
  alertCameraImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  editButtonWrapper: {
    textAlign: 'right',
  },
  notificationType: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  attribution: {
    '& p': {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.text.primary,
      marginTop: 8,
      marginBottom: 0,
    },
  },
}));

const ReportCard = (props: ReportCardProps): JSX.Element => {
  const { report, media, className } = props;
  const { id, geoEventId, message } = report;
  const history = useHistory();
  const { classes, cx } = useStyles();
  const {
    permissions: { canReport },
  } = useAuthState();
  const { t } = useTranslation();
  const triedImgFallback = useRef(false);

  return (
    <Card
      className={cx(classes.root, className)}
      data-testid="reportCard"
      elevation={0}
    >
      <CardContent sx={{ paddingBottom: '8px !important' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={canReport ? 11 : 12}>
              <ReportMetadata report={report} />
            </Grid>

            {canReport && (
              <Grid item xs={1}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (canReport) {
                        history.push(
                          `/incident/${geoEventId}/reports/${id}/edit`,
                        );
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>

          {media && (
            <Grid item xs={12}>
              <MuiLink
                key={id}
                to={`/i/${geoEventId}/reports/${id}`}
                underline="none"
                component={Link}
              >
                <img
                  src={media.thumbnailUrl || media.url}
                  alt={t('geoEvent.reports.image')}
                  className={classes.alertCameraImage}
                  onError={(
                    event: React.SyntheticEvent<HTMLImageElement, Event>,
                  ) => {
                    // provide backup for the case of a thumbnailUrl failing, media.url should always work
                    const target = event.currentTarget as HTMLImageElement;
                    if (target.src !== media.url && !triedImgFallback.current) {
                      target.src = media.url;
                      triedImgFallback.current = true;
                    }
                    target.onerror = null;
                  }}
                  loading="lazy"
                />
                {media.attribution && (
                  <RichTextContent
                    content={media.attribution}
                    className={classes.attribution}
                  />
                )}
              </MuiLink>
            </Grid>
          )}

          {report.embedUrl && (
            <Grid item xs={12}>
              <CardEmbed embedUrl={report.embedUrl} />
            </Grid>
          )}
        </Grid>

        <RichTextContent content={message} className={classes.message} />
      </CardContent>
    </Card>
  );
};

export default memo(ReportCard);

import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'cordova-plugin-purchase';
import { Capacitor } from '@capacitor/core';
import GrayButton from 'components/GrayButton';
import { useMembershipManagement } from 'hooks/useMembershipManagement';
import { formatDate } from 'shared/dates';

export const ExpiryInfo = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { dateActiveUntil, isExpired } = useMembershipManagement();

  if (!dateActiveUntil) return null;

  return (
    <Typography
      sx={{
        fontSize: 16,
        marginBottom: '16px',
      }}
    >
      {t(`supportUs.manageMembership.${isExpired ? 'expired' : 'expires'}`, {
        date: formatDate(new Date(dateActiveUntil), 'MMM d, yyyy'),
      })}
    </Typography>
  );
};

export const ManageMembershipButton = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { hostedLink, isIosSubscription, isLoading } =
    useMembershipManagement();

  if (hostedLink) {
    return (
      <Box
        sx={{
          pt: 1,
        }}
      >
        <GrayButton
          fullWidth
          size="large"
          component={Link}
          to={{ pathname: hostedLink }}
          target="_blank"
          disabled={isLoading || !hostedLink}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('supportUs.manageMembership.button')
          )}
        </GrayButton>
      </Box>
    );
  }

  if (isIosSubscription && Capacitor.getPlatform() === 'ios') {
    return (
      <Box
        sx={{
          pt: 1,
        }}
      >
        <GrayButton
          fullWidth
          size="large"
          onClick={() =>
            CdvPurchase.store.manageSubscriptions(
              CdvPurchase.Platform.APPLE_APPSTORE,
            )
          }
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('supportUs.manageMembership.button')
          )}
        </GrayButton>
      </Box>
    );
  }

  return null;
};

import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { AxiosResponse } from 'axios';
import { UserSupporterStatus } from 'shared/types';
import { useAuthState } from 'state';

const getMembershipStatus = (): Promise<AxiosResponse<UserSupporterStatus>> =>
  API.get<UserSupporterStatus>('/recurly_integration/supporter_status/');

export const useSupporterStatus = (): UserSupporterStatus | undefined => {
  const { isAuthenticated } = useAuthState();

  const { data } = useQuery({
    queryKey: ['membership-status'],
    queryFn: getMembershipStatus,
    staleTime: 1000 * 60 * 60 * 60 * 48, // 48 hours
    gcTime: 1000 * 60 * 60 * 60 * 49, // 49 hours - must be bigger than staleTime
    enabled: isAuthenticated,
  });

  return data?.data;
};

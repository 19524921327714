import { FloodCategory } from './riverGauges.types';

export const NORMAL_LEVEL_CHART_COLOR = 'rgba(129, 186, 128, 0.5)';

export const floodCategoryColorsSolid: Record<FloodCategory, string | null> = {
  major: 'rgba(167, 80, 255, 1)',
  moderate: 'rgba(225, 83, 70, 1)',
  minor: 'rgba(241, 148, 54, 1)',
  action: 'rgba(251, 192, 45, 1)',
  none: null,
};

export const floodCategoryColorsTransparent: Record<
  FloodCategory,
  string | null
> = Object.entries(floodCategoryColorsSolid).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]:
        typeof value === 'string' ? value.replace(', 1)', ', 0.7)') : value,
    };
  },
  {} as Record<FloodCategory, string | null>,
);

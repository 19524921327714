import { useCallback } from 'react';
import { IconButton, MenuItem, TextField, Grid2, Stack } from '@mui/material';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { LinkType } from 'shared/types';
import { formatPhoneNumber } from 'shared/utils';
import GrayButton from 'components/GrayButton';
import { Capacitor } from '@capacitor/core';
import { LinkTypes } from '../../constants';

const typeOptions: { label: string; value: LinkType }[] = [
  { label: 'Phone', value: LinkTypes.phone },
  { label: 'Website', value: LinkTypes.website },
  { label: 'Email', value: LinkTypes.email },
];

const FormLinks = (): JSX.Element => {
  const { t } = useTranslation();
  const { control, formState, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'links' });

  const handleOnChangeValue = (value: string, index: number): void => {
    const linkType = getValues(`links[${index}].linkType`);

    if (linkType !== LinkTypes.phone) {
      setValue(`links[${index}].value`, value, { shouldValidate: true });
      return;
    }

    const nextValue = formatPhoneNumber(value);
    setValue(`links[${index}].value`, nextValue, { shouldValidate: true });
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const links = Array.from(getValues('links'));
      const [reorderedItem] = links.splice(result.source.index, 1);
      links.splice(result.destination.index, 0, reorderedItem);
      setValue('links', links);
    },
    [getValues, setValue],
  );

  return (
    <Stack spacing={2} useFlexGap>
      {fields.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="links">
            {(provided) => (
              <Stack
                spacing={2}
                useFlexGap
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {fields.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(prov) => (
                      <Stack
                        spacing={2}
                        useFlexGap
                        flexDirection="row"
                        {...prov.draggableProps}
                        {...prov.dragHandleProps}
                        ref={prov.innerRef}
                      >
                        <MenuIcon color="secondary" sx={{ mt: { sm: 2 } }} />

                        <Grid2 container spacing={2} size="grow">
                          <Grid2 size={{ xs: 12, sm: 2 }}>
                            <Controller
                              name={`links[${index}].linkType`}
                              control={control}
                              render={({ field, fieldState }) => {
                                const {
                                  ref,
                                  value: val,
                                  ...muiFieldProps
                                } = field;
                                return (
                                  <TextField
                                    select
                                    id={`links[${index}].linkType`}
                                    label={t('formLinks.inputs.linkType.label')}
                                    fullWidth
                                    {...muiFieldProps}
                                    value={val || ''}
                                    inputRef={ref}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    required
                                    disabled={formState.isSubmitting}
                                  >
                                    {typeOptions.map(({ label, value }) => {
                                      return (
                                        <MenuItem
                                          key={label}
                                          value={value}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          {label}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                );
                              }}
                            />
                          </Grid2>

                          <Grid2 size={{ xs: 12, sm: 4 }}>
                            <Controller
                              name={`links[${index}].label`}
                              control={control}
                              render={({ field, fieldState }) => {
                                const { ref, ...muiFieldProps } = field;
                                return (
                                  <TextField
                                    id={`links[${index}].label`}
                                    label={t(
                                      'formLinks.inputs.linkLabel.label',
                                    )}
                                    fullWidth
                                    {...muiFieldProps}
                                    inputRef={ref}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    required
                                    disabled={formState.isSubmitting}
                                  />
                                );
                              }}
                            />
                          </Grid2>

                          <Grid2 size={{ xs: 12, sm: 'grow' }}>
                            <Controller
                              name={`links[${index}].value`}
                              control={control}
                              render={({ field, fieldState }) => {
                                const { ref, onChange, ...muiFieldProps } =
                                  field;
                                return (
                                  <TextField
                                    id={`links[${index}].value`}
                                    label={t(
                                      'formLinks.inputs.linkValue.label',
                                    )}
                                    fullWidth
                                    {...muiFieldProps}
                                    onChange={(e) =>
                                      handleOnChangeValue(e.target.value, index)
                                    }
                                    inputRef={ref}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    required
                                    disabled={formState.isSubmitting}
                                  />
                                );
                              }}
                            />
                          </Grid2>

                          <Grid2
                            size={{ xs: 12, sm: 'auto' }}
                            sx={{ mt: 1, textAlign: 'end' }}
                          >
                            <IconButton
                              onClick={() => remove(index)}
                              sx={{
                                backgroundColor: 'common.black',
                                borderRadius: 2,
                                ...(Capacitor.isNativePlatform() && {
                                  '&:hover': {
                                    backgroundColor: 'common.black',
                                  },
                                }),
                              }}
                            >
                              <CloseIcon color="primary" />
                            </IconButton>
                          </Grid2>
                        </Grid2>
                      </Stack>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <GrayButton
        size="large"
        onClick={() => append({ linkType: null, label: '', value: '' })}
        sx={{
          width: { xs: '100%', sm: 'fit-content' },
        }}
      >
        {t('formLinks.button')}
      </GrayButton>
    </Stack>
  );
};

export default FormLinks;

import { API } from 'api';

export const SYNC_APP_PURCHASE_LOCAL_STORAGE_KEY = 'SYNC_APP_PURCHASE';

const skipSyncExecution = (storageKey: string): boolean => {
  const lastExecution = localStorage.getItem(storageKey);
  if (!lastExecution) return false;

  const lastDate = new Date(lastExecution);
  const now = new Date();

  const startOfLastDate = new Date(lastDate).setHours(0, 0, 0, 0);
  const startOfToday = new Date(now).setHours(0, 0, 0, 0);

  return startOfLastDate === startOfToday;
};

export const syncAppPurchase = async (
  originalTransactionId: string,
): Promise<void> => {
  if (skipSyncExecution(SYNC_APP_PURCHASE_LOCAL_STORAGE_KEY)) {
    return;
  }

  await API.post('/recurly_integration/add_original_transaction_id/', {
    originalTransactionId,
  });

  localStorage.setItem(
    SYNC_APP_PURCHASE_LOCAL_STORAGE_KEY,
    new Date().toISOString(),
  );
};

export const getLatestOriginalTransactionId = (
  transactions: CdvPurchase.Transaction[],
): string | null => {
  const userTransactions = transactions
    .filter(
      (tx) =>
        tx.transactionId !==
          CdvPurchase.AppleAppStore.APPLICATION_VIRTUAL_TRANSACTION_ID &&
        tx.state === CdvPurchase.TransactionState.APPROVED &&
        !!tx.purchaseDate,
    )
    // Sort by date in ascending order - first transaction is the most recent
    .sort(
      (a, b) =>
        new Date(b.purchaseDate!).getTime() -
        new Date(a.purchaseDate!).getTime(),
    );

  if (!userTransactions.length) {
    return null;
  }

  // @ts-expect-error - originalTransactionId is not defined on the type
  return userTransactions[0].originalTransactionId;
};

import { HeaderMenuAction } from 'components/HeaderBar';
import { CameraTimeLapseFrameDetails } from 'hooks/useAlertCameraPlayer';
import { TFunction } from 'i18next';
import { UseMapState } from 'state/useMapState';

export const getIncidentPageMenuItems = (
  t: TFunction,
  data: {
    automatedGeoEvent: boolean;
    geoEventId: number;
    geoEventMapState: {
      lat: number;
      lng: number;
    } | null;
    canCreateLocation: boolean;
  },
): HeaderMenuAction[] => {
  const { automatedGeoEvent, geoEventId, geoEventMapState, canCreateLocation } =
    data;

  const routePrefix = automatedGeoEvent ? 'i' : 'incident';
  const editLink = `/${routePrefix}/${geoEventId}/edit`;

  if (automatedGeoEvent) {
    return [
      {
        label: t('header.actions.editIncident'),
        to: editLink,
      },
    ];
  }

  let createLocationLink = `/location/create?parentGeoEventId=${geoEventId}`;
  if (geoEventMapState?.lat && geoEventMapState?.lng) {
    createLocationLink += `&lat=${geoEventMapState.lat}&lng=${geoEventMapState.lng}`;
  }

  const items = [
    {
      label: t('header.actions.addUpdate'),
      to: `/add_report/${geoEventId}`,
    },
    {
      label: t('header.actions.editIncident'),
      to: editLink,
    },
  ];

  if (canCreateLocation) {
    items.push({
      label: t('header.actions.addLocation'),
      to: createLocationLink,
    });
  }

  return items;
};

export const getLocationPageMenuItems = (
  t: TFunction,
  locationId: number,
): HeaderMenuAction[] => {
  return [
    {
      label: t('header.actions.editLocation'),
      to: `/location/${locationId}/edit`,
    },
  ];
};

export const getDefaultMenuItems = (
  t: TFunction,
  data: {
    incidentMapState: UseMapState['incidentMapState'];
    timelapseFrame: CameraTimeLapseFrameDetails | null;
    cameraId?: string;
    canCreateLocation: boolean;
  },
): HeaderMenuAction[] => {
  const { incidentMapState, timelapseFrame, cameraId, canCreateLocation } =
    data;

  let createIncident = '/incident/create';
  let createLocation = '/location/create';

  if (incidentMapState.center.lat && incidentMapState.center.lng) {
    const params = new URLSearchParams({
      lat: incidentMapState.center.lat.toString(),
      lng: incidentMapState.center.lng.toString(),
    });

    createLocation += `?${params.toString()}`;
    if (incidentMapState.zoom) {
      params.append('zoom', incidentMapState.zoom.toString());
    }
    if (cameraId) {
      params.append('alertCameraId', cameraId);
      if (timelapseFrame?.imageTimestamp) {
        params.append('alertCameraTs', timelapseFrame.imageTimestamp);
      }
    }
    createIncident += `?${params.toString()}`;
  }

  const items = [
    {
      label: t('header.actions.addIncident'),
      to: createIncident,
    },
  ];

  if (canCreateLocation) {
    items.push({
      label: t('header.actions.addLocation'),
      to: createLocation,
    });
  }

  return items;
};

export const getAdminMenuItems = (
  t: TFunction,
  data: {
    automatedGeoEvent: boolean;
    geoEventId?: number;
    locationId?: number;
    geoEventMapState: {
      lat: number;
      lng: number;
    } | null;
    canCreateLocation: boolean;
    incidentMapState: UseMapState['incidentMapState'];
    timelapseFrame: CameraTimeLapseFrameDetails | null;
    cameraId?: string;
  },
): HeaderMenuAction[] => {
  const {
    automatedGeoEvent,
    geoEventId,
    locationId,
    geoEventMapState,
    canCreateLocation,
    incidentMapState,
    timelapseFrame,
    cameraId,
  } = data;

  if (geoEventId) {
    return getIncidentPageMenuItems(t, {
      automatedGeoEvent,
      geoEventId,
      geoEventMapState,
      canCreateLocation,
    });
  }

  if (locationId) {
    return getLocationPageMenuItems(t, locationId);
  }

  return getDefaultMenuItems(t, {
    incidentMapState,
    timelapseFrame,
    cameraId,
    canCreateLocation,
  });
};

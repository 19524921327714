import { Layer } from 'shared/map-exports';
import {
  layerBorderStyle,
  layerFillStyle,
  layerLabelStyle,
} from './layerStyles';
import VisibilityToggledSource from '../VisibilityToggledSource';
import { FEDERAL_LANDS_SOURCE_ID } from './constants';
import { getWatchdutyStaticTileUrl } from '../getWatchdutyStaticTileUrl';
import { BEFORE_LAYER_ID } from '../../styles/constants';

type FederalLandsLayerProps = {
  visible: boolean;
};

const FederalLandsLayer = (props: FederalLandsLayerProps): JSX.Element => {
  const { visible } = props;

  return (
    <VisibilityToggledSource
      url={getWatchdutyStaticTileUrl('land_ownership')}
      visible={visible}
      id={FEDERAL_LANDS_SOURCE_ID}
    >
      <Layer beforeId={BEFORE_LAYER_ID} {...layerFillStyle} />
      <Layer beforeId={BEFORE_LAYER_ID} {...layerBorderStyle} />
      <Layer {...layerLabelStyle} />
    </VisibilityToggledSource>
  );
};

export default FederalLandsLayer;

import { useQueries } from '@tanstack/react-query';
import { RiverGaugeObservation } from '../../riverGauges.types';
import { API } from '../../../../api';

type UseRiverGaugeObservationsProps = {
  id: string | number;
  start: Date;
  end: Date;
  pollInterval?: number;
};

type UseRiverGaugeObservations = {
  observations?: RiverGaugeObservation[];
  forecasts?: RiverGaugeObservation[];
  isLoading: boolean;
  error: Error | null;
  isSuccess: boolean;
  isError: boolean;
};

type ServerRiverGaugeObservation = {
  timestamp: string;
  values: {
    flow: number;
    level: number;
  };
};

type FetchObservationsValues = {
  id: string | number;
  start: Date;
  end: Date;
};

type FetchForecastValues = {
  id: string | number;
};

const fetchRiverGaugeObservations = async (
  values: FetchObservationsValues,
): Promise<RiverGaugeObservation[]> => {
  const { id, start, end } = values;
  const response = await API.get<ServerRiverGaugeObservation[]>(
    `weather/observations/${id}/`,
    {
      params: {
        start: start.toISOString(),
        end: end.toISOString(),
      },
    },
  );

  return response.data.map((observation) => ({
    timestamp: new Date(observation.timestamp),
    ...observation.values,
  }));
};

const fetchRiverGaugeForecasts = async (
  values: FetchForecastValues,
): Promise<RiverGaugeObservation[]> => {
  const { id } = values;
  const response = await API.get<ServerRiverGaugeObservation[]>(
    `weather/forecast/${id}/`,
  );

  return response.data.map((observation) => ({
    timestamp: new Date(observation.timestamp),
    ...observation.values,
  }));
};

const removeObservationsFromForecasts = (
  observations: RiverGaugeObservation[] | undefined,
  forecasts: RiverGaugeObservation[] | undefined,
): RiverGaugeObservation[] | undefined => {
  const latestObservation = observations?.[0];

  if (!latestObservation || !forecasts?.length) {
    return forecasts;
  }

  return forecasts.filter(
    ({ timestamp }) => timestamp > latestObservation.timestamp,
  );
};

export const useRiverGaugeObservations = (
  props: UseRiverGaugeObservationsProps,
): UseRiverGaugeObservations => {
  const { id, start, end, pollInterval } = props;

  const observationsQuery = {
    queryKey: ['riverGaugueObservations', id],
    queryFn: () => fetchRiverGaugeObservations({ id, start, end }),
    refetchInterval: pollInterval ?? undefined,
  };

  const forecastQuery = {
    queryKey: ['riverGaugueForecasts', id],
    queryFn: () => fetchRiverGaugeForecasts({ id }),
    refetchInterval: pollInterval ?? undefined,
  };

  const [observationsResults, forecastsResults] = useQueries({
    queries: [observationsQuery, forecastQuery],
  });

  const {
    data: observations,
    isLoading: isLoadingObservations,
    error: observationsError,
    isSuccess: isObservationsSuccess,
    isError: isObservationsError,
  } = observationsResults;

  const {
    data: forecasts,
    isLoading: isLoadingForecasts,
    error: forecastsError,
    isSuccess: isForecastsSuccess,
    isError: isForecastsError,
  } = forecastsResults;

  return {
    isLoading: isLoadingObservations || isLoadingForecasts,
    error: observationsError || forecastsError,
    observations,
    forecasts: removeObservationsFromForecasts(observations, forecasts),
    isSuccess: isObservationsSuccess && isForecastsSuccess,
    isError: isObservationsError && isForecastsError,
  };
};

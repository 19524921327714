import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { HeaderBar } from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import TestNotificationsContent from './TestNotificationsContent';

const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('testNotifications.title')}</title>
      </Helmet>
      <TestNotificationsContent />
    </>
  );
};

const TestNotifications = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action="back" title={t('testNotifications.title')} />
        ),
      }}
    </PageLayout>
  );
};

export default TestNotifications;

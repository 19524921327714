import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import { useRiverGaugeAlerts } from '../../hooks/useRiverGaugeAlerts';
import { NotificationsDownstate } from './NotificationsDownstate';

type RiverGaugeAlertListProps = {
  riverGaugeId?: number | string;
  fromRoute: string;
  scrollContainer?: HTMLElement;
};

export const RiverGaugeAlertList = (
  props: RiverGaugeAlertListProps,
): JSX.Element => {
  const { t } = useTranslation();
  const { riverGaugeId, fromRoute, scrollContainer } = props;
  const { isPushEnabled } = useNotificationsSettings();
  const {
    riverGaugeAlerts = [],
    isLoading,
    isError,
  } = useRiverGaugeAlerts({
    riverGaugeId,
  });

  if (!isPushEnabled) {
    return (
      <NotificationsDownstate
        scrollContainer={scrollContainer}
        isPushEnabled={false}
      />
    );
  }

  if (isLoading || isError) {
    return <LoadingAndErrors isLoading={isLoading} />;
  }

  if (!riverGaugeAlerts.length) {
    return (
      <NotificationsDownstate
        scrollContainer={scrollContainer}
        gaugeId={riverGaugeId}
      />
    );
  }

  return (
    <Stack gap={1}>
      <List disablePadding>
        {riverGaugeAlerts.map((alert) => (
          <ListItem
            key={alert.id}
            divider
            secondaryAction={
              <Button
                component={Link}
                to={{
                  pathname: `/gauge/${alert.station.id}/alert/${alert.id}`,
                  state: {
                    fromRoute,
                  },
                }}
                variant="watchduty-text-uppercase"
                sx={{ color: 'accent.main' }}
              >
                {t('common.edit')}
              </Button>
            }
          >
            <ListItemText
              primary={alert.station.name}
              secondary={t('riverGauges.alerts.description', {
                level: alert.alertThresholdValue.level,
                units: alert.station.units.level,
              })}
              primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
            />
          </ListItem>
        ))}
      </List>

      {!!riverGaugeId && (
        <Box sx={{ m: 2 }}>
          <Button
            fullWidth
            size="large"
            component={Link}
            to={`/gauge/${riverGaugeId}/alert/create`}
          >
            {t('riverGauges.alerts.addNotification')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

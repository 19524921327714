import { useRouteMatch } from 'react-router-dom';
import { ROUTER_ENTITY_TO_ROUTE_PATH_MAP } from '../../../../constants';

type RiverGaugeParams = {
  gaugeId?: string;
};

export const useSelectedRiverGaugeId = (): number | undefined => {
  // Using `useRouteMatch()` instead of `useParams()` since we're not always inside the River Gauge <Router>
  const match = useRouteMatch<RiverGaugeParams>(
    ROUTER_ENTITY_TO_ROUTE_PATH_MAP.gauge,
  );
  const gaugeIdStr = match?.params.gaugeId;

  return gaugeIdStr ? parseInt(gaugeIdStr, 10) : undefined;
};

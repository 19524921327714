import i18n from 'i18next';
import { enUS, es, vi, zhCN } from 'date-fns/locale';
import { format, formatDistanceToNow } from 'date-fns';
import { Language } from 'i18n/utils';

type DateFnsLocale = keyof typeof locales;

const locales = { enUS, es, vi, zhCN };

const intLangToDateFnsLocale: Record<Exclude<Language, 'tl'>, DateFnsLocale> = {
  en: 'enUS',
  es: 'es',
  vi: 'vi',
  zh: 'zhCN',
};

export const SHORT_MONTH_DAY_12HOUR_FORMAT = 'MMM d, h:mm a';

export const getTimeZoneAbbr = (date: Date, timeZone?: string): string => {
  return (
    new Intl.DateTimeFormat('en-US', {
      timeZone,
      timeZoneName: 'short',
    })
      .formatToParts(date)
      .find((part) => part.type === 'timeZoneName')?.value || ''
  );
};

export const formatDate = (
  date: Date,
  formatStr = 'PP',
  includeTimeZone = false,
): string => {
  const formattedDate = format(date, formatStr, {
    locale: locales[intLangToDateFnsLocale[i18n.resolvedLanguage as Language]],
  });
  if (includeTimeZone) {
    return `${formattedDate} ${getTimeZoneAbbr(date)}`;
  }
  return formattedDate;
};

export const formatDateDistanceToNow = (
  date: Date,
  options: { includeSeconds?: boolean; addSuffix?: boolean } = {},
): string => {
  return formatDistanceToNow(date, {
    locale: locales[intLangToDateFnsLocale[i18n.resolvedLanguage as Language]],
    ...options,
  });
};

export const getTimePass = (time: string): string =>
  formatDateDistanceToNow(new Date(time), {
    addSuffix: true,
  });

export const getDateFormatted = (
  time: string,
  options?: { includeYear?: boolean },
): string => {
  const date = new Date(time);
  const combiner = i18n.t('common.at');
  const dateFormat = options?.includeYear ? 'MMM d, yyyy' : 'MMM d';

  const formattedDate = formatDate(date, dateFormat);
  const formattedTime = formatDate(date, 'h:mm a');

  return `${formattedDate} ${combiner} ${formattedTime}`;
};

export const getDateFormattedInboxMessage = (time: string): string => {
  const date = new Date(time);
  const combiner = i18n.t('common.at');

  const formattedDate = formatDate(date, 'EEEE, MMM d');
  const formattedTime = formatDate(date, 'h:mm a');

  return `${formattedDate} ${combiner} ${formattedTime}`;
};

export const getDateStringWithNoZeroTimeZone = (): string =>
  new Date().toISOString().slice(0, -1);

/**
 * Formats a Date object into a local ISO 8601 string without timezone information.
 *
 * The function preserves the local date and time components exactly as provided,
 * returning them in the format 'YYYY-MM-DDTHH:mm:ss'. Unlike toISOString(),
 * which converts to UTC and appends 'Z', this maintains the local time representation
 * without any timezone indicators.
 */
export const formatLocalDateTimeWithoutTimezone = (date: Date): string => {
  return formatDate(date, "yyyy-MM-dd'T'HH:mm:ss");
};

/**
 * Converts a date to UTC and formats it using the given date-fns format string.
 */
export const formatLocalDateToUtc = (date: Date, formatStr = 'PP'): string => {
  const utcDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 1000 * 60,
  );
  return formatDate(utcDate, formatStr);
};

import { AirCraft, Altitude } from 'shared/types';
import { knotsToMph } from 'shared/utils';
import { AircraftIcons } from './Icons';
import {
  AircraftCache,
  AircraftCacheNoData,
  AircraftLayerFeature,
  FirefightingAircraft,
  AircraftLayerFeatureCollection,
} from './types';
import { AIRCRAFT_CACHE_NO_DATA_EXPIRY_MS } from './constants';

export const aircraftIconNameToDataMap = Object.fromEntries(
  AircraftIcons.map((data) => [data.name, data]),
);

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

export const firefightAirCraftsToObject = (
  firefightingAircrafts: FirefightingAircraft[],
): Record<string, FirefightingAircraft> => {
  return firefightingAircrafts.reduce(
    (prev, item) => {
      // eslint-disable-next-line no-param-reassign
      prev[item.hexCode.toLowerCase()] = item;
      return prev;
    },
    {} as Record<string, FirefightingAircraft>,
  );
};

export const aircraftsToGeoJson = (
  aircrafts: AirCraft[],
  activeAircrafts: Record<string, FirefightingAircraft>,
  activeAircraftHexCodes: string[],
): AircraftLayerFeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: aircrafts.reduce((prev, aircraft) => {
      const firefightingAircraft = activeAircrafts[aircraft.hex];
      if (firefightingAircraft) {
        prev.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [aircraft.lon, aircraft.lat],
          },
          properties: {
            id: aircraft.hex,
            hex: aircraft.hex,
            type: firefightingAircraft.classification,
            icon: firefightingAircraft.classification,
            name: firefightingAircraft.name,
            model: firefightingAircraft.model,
            tailNum: firefightingAircraft.tailNum,
            rotate: aircraft.track ?? 0,
            speed: knotsToMph(aircraft.tas ?? aircraft.ias ?? aircraft.gs ?? 0),
            altitude:
              aircraft.altBaro !== undefined
                ? aircraft.altBaro
                : (aircraft.altGeom ?? 0),
            selected: activeAircraftHexCodes.includes(aircraft.hex),
            shortCallsign: firefightingAircraft.shortCallsign,
            squawk: aircraft.squawk || '-',
          },
        });
      }
      return prev;
    }, [] as AircraftLayerFeature[]),
  };
};

export const mergeDataToCache = (
  newestData: AirCraft[],
  cacheData: AircraftCache | AircraftCacheNoData,
  storeData: boolean,
): AircraftCache => {
  // Add or update values in cachedData with newestData and remove anything that hasn't been seen in N seconds
  const now = new Date().getTime();

  // add or update everything in cacheData - conditionally including the adsb data if storeData is true
  newestData.forEach((item) => {
    if (storeData) {
      // eslint-disable-next-line no-param-reassign
      cacheData[item.hex] = {
        data: item,
        lastSeen: now,
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      cacheData[item.hex] = {
        lastSeen: now,
      };
    }
  });

  // filter out anything not seen in AIRCRAFT_CACHE_NO_DATA_EXPIRY_MS MS
  return Object.keys(cacheData).reduce((acc, key) => {
    if (now - cacheData[key].lastSeen < AIRCRAFT_CACHE_NO_DATA_EXPIRY_MS) {
      acc[key] = cacheData[key];
    }
    return acc;
  }, {});
};

export const parseAltitudeToZIndex = (altitude?: Altitude): number => {
  // zIndex should be at least 1 to make sure markers are visible (above the map).
  if (typeof altitude === 'number') {
    // Altitude sometimes can be negative due to how barometric altitude interacts with the WGS84 earth MSL model.
    return Math.max(altitude, 1);
  }
  return 1;
};

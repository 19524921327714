import {
  Box,
  DialogContent,
  DialogTitle,
  SxProps,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FirePerimeterPoi } from 'shared/types';
import { Launch } from '@mui/icons-material';
import useAuthState from 'state/useAuthState';
import { userInGroups } from 'shared/utils';
import { formatDate } from 'shared/dates';

type ActiveFirePerimeterDialogContentProps = {
  activeFirePerimeter: FirePerimeterPoi;
};

type InfoRowProps = {
  title: string;
  content: string;
  titleSx?: SxProps;
  contentSx?: SxProps;
};

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 1, 2),
    lineHeight: '1 !important',
  },
  content: {
    padding: theme.spacing(0, 4, 2, 2),
    maxWidth: 375,
    lineHeight: '18px',
  },
}));

const formatTimestamp = (timestamp: string | number): string => {
  const date = new Date(timestamp);
  const combiner = i18n.t('common.at');

  const timeZoneName = date
    .toLocaleString(undefined, {
      timeZoneName: 'short',
    })
    .split(' ')
    .reverse()[0];

  return `${formatDate(date, `E, MMM d '${combiner}' h:mm a`)} ${timeZoneName}`;
};

const InfoRow = (props: InfoRowProps): JSX.Element => {
  const { title, content, titleSx, contentSx } = props;
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: 96 }}>
        <Typography variant="body2" color="textSecondary" sx={titleSx}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="body2" sx={contentSx}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

export const ActiveFirePerimeterDialogContent = (
  props: ActiveFirePerimeterDialogContentProps,
): JSX.Element => {
  const { activeFirePerimeter } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { user } = useAuthState();
  const isStaffReporter = userInGroups(user, ['staff_reporters']);

  return (
    <>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2">
          <b>{t('map.poi.activeFirePerimeters.title')}</b>
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <InfoRow
          title={t('map.poi.activeFirePerimeters.created')}
          content={formatTimestamp(activeFirePerimeter.dateCurrent)}
          titleSx={{ fontVariant: 'all-small-caps' }}
        />

        <InfoRow
          title={t('map.poi.activeFirePerimeters.source')}
          content={activeFirePerimeter.source}
          titleSx={{ fontVariant: 'all-small-caps' }}
        />

        <InfoRow
          title={t('map.poi.activeFirePerimeters.notes.title')}
          content={t('map.poi.activeFirePerimeters.notes.content')}
          titleSx={{ fontVariant: 'all-small-caps' }}
        />

        {isStaffReporter && !!activeFirePerimeter.irwinId && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: 96 }}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontVariant: 'all-small-caps' }}
              >
                {t('map.poi.activeFirePerimeters.editStatus')}
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2">
                <a
                  href={`${import.meta.env.VITE_BASE_API_URL.replace(
                    '/api/v1/',
                    '',
                  )}/watchduty-admin/fire_perimeters/fireperimeter/?q=${
                    activeFirePerimeter.irwinId
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  // eslint-disable-next-line i18next/no-literal-string
                >
                  Admin Panel <Launch fontSize="inherit" />
                </a>
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </>
  );
};

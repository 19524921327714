import { Layer } from 'shared/map-exports';
import { useCallback } from 'react';
import { usePoisState } from 'state/usePoisState';
import { useTranslation } from 'react-i18next';
import {
  getSelectedPatternStyle,
  getSelectedStrokeStyle,
} from 'components/Map/utils';
import {
  RESPONSIBILITY_AREAS_LAYER_ID,
  RESPONSIBILITY_AREAS_SOURCE_LAYER,
} from './constants';
import { layerFillStyle, layerStrokeStyle } from './layerStyles';
import { MapboxFeature, useMapLayerEvents } from '../useMapLayerEvents';
import { ResponsibilityArea } from './types';
import ResponsibilityDialogContent from './DialogContent';
import VisibilityToggledSource from '../VisibilityToggledSource';
import { getWatchdutyStaticTileUrl } from '../getWatchdutyStaticTileUrl';
import { BEFORE_LAYER_ID } from '../../styles/constants';

type ResponsibilityAreasProps = {
  visible: boolean;
};

const ResponsibilityAreasLayer = (
  props: ResponsibilityAreasProps,
): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const { t } = useTranslation();

  useMapLayerEvents({
    layerId: RESPONSIBILITY_AREAS_LAYER_ID,
    onClick: useCallback(
      (features: MapboxFeature<ResponsibilityArea>[]) => {
        for (const feature of features) {
          const responsibilityArea: ResponsibilityArea = feature.properties;
          setSelectedPoi({
            type: 'responsibilityArea',
            id: responsibilityArea.OBJECTID,
            PoiDialogContent: () => (
              <ResponsibilityDialogContent
                title={t(
                  `map.layers.responsibilityAreas.${responsibilityArea.SRA}`,
                )}
              />
            ),
          });
        }
      },
      [setSelectedPoi, t],
    ),
  });

  const selectedId =
    (selectedPoi?.type === 'responsibilityArea' && selectedPoi.id!) || '';

  const selectedFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': RESPONSIBILITY_AREAS_SOURCE_LAYER },
    selectedId,
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': RESPONSIBILITY_AREAS_SOURCE_LAYER },
    selectedId,
  });

  return (
    <VisibilityToggledSource
      url={getWatchdutyStaticTileUrl('ca_responsibility_area')}
      visible={visible}
    >
      <Layer
        id={RESPONSIBILITY_AREAS_LAYER_ID}
        beforeId={BEFORE_LAYER_ID}
        {...layerFillStyle}
      />
      <Layer beforeId={BEFORE_LAYER_ID} {...layerStrokeStyle} />
      <Layer beforeId={BEFORE_LAYER_ID} {...selectedFillStyle} />
      <Layer beforeId={BEFORE_LAYER_ID} {...selectedStrokeStyle} />
    </VisibilityToggledSource>
  );
};

export default ResponsibilityAreasLayer;

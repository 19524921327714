import { useHistory, Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import useOnboardingState from 'state/useOnboardingState';
import OnboardingLayout from 'components/OnboardingLayout';
import { DownState } from 'components/DownState';
import TurnOnNotificationsIcon from 'assets/turn_on_notifications.svg?react';
import BlackButton from 'components/BlackButton';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { openNativeAppSettings } from 'shared/utils';
import CountyNotifications from '../CountyNotifications';

const useStyles = makeStyles()((theme) => ({
  descriptionContainer: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

const OnboardingNotificationsContent = (): JSX.Element => {
  const query = useLocationSearchParams();
  const {
    isPushEnabled,
    pushToken,
    subscribedNotificationSettings,
    allNotificationSettings,
  } = useNotificationsSettings({
    notificationsEnabled: query.get('enabled') === 'true',
  });
  const history = useHistory();
  const { setCompleted } = useOnboardingState();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const handleDone = (): void => {
    setCompleted(true);
    history.push('/');
  };

  const title = t('onboardingNotifications.title');
  const buttonTitle = t('onboardingNotifications.button');

  const { data, isLoading, isError } = allNotificationSettings;
  if (!isPushEnabled) {
    return (
      <OnboardingLayout
        title={title}
        buttonTitle={buttonTitle}
        onClick={handleDone}
      >
        <DownState
          states={[
            {
              renderImage: (imageProps) => (
                <TurnOnNotificationsIcon {...imageProps} />
              ),
              description: (
                <Stack spacing={2} className={classes.descriptionContainer}>
                  <Typography variant="h2" align="center">
                    {t('notifications.turnOn.title')}
                  </Typography>
                  <Typography>
                    <Trans i18nKey="notifications.turnOn.description">
                      Never miss important updates in your area by
                      <b>enabling notifications</b>.
                    </Trans>
                  </Typography>
                  <Typography>{t('notifications.counties')}</Typography>
                </Stack>
              ),
              Button: (
                <BlackButton
                  fullWidth
                  size="large"
                  onClick={openNativeAppSettings}
                >
                  {t('notifications.turnOn.title')}
                </BlackButton>
              ),
              containerProps: { sx: { mt: 6 } },
            },
          ]}
        />
      </OnboardingLayout>
    );
  }
  if (!pushToken || isLoading) {
    return (
      <OnboardingLayout title={title}>
        <LoadingAndErrors isLoading />
      </OnboardingLayout>
    );
  }

  if (isError || !data) {
    return (
      <OnboardingLayout
        title={title}
        buttonTitle={buttonTitle}
        onClick={handleDone}
      >
        <LoadingAndErrors />
      </OnboardingLayout>
    );
  }

  const regionSettings = allNotificationSettings.data;
  const isAnyCountySelected =
    !!subscribedNotificationSettings.data?.data.length;

  if (!isAnyCountySelected) {
    return (
      <OnboardingLayout
        title={title}
        buttonTitle={buttonTitle}
        onClick={handleDone}
      >
        <DownState
          states={[
            {
              renderImage: (imageProps) => (
                <TurnOnNotificationsIcon {...imageProps} />
              ),
              description: (
                <Stack spacing={2} className={classes.descriptionContainer}>
                  <Typography variant="h2" align="center">
                    {t('notifications.addCounties.title')}
                  </Typography>
                  <Typography>
                    {t('notifications.addCounties.description')}
                  </Typography>
                </Stack>
              ),
              Button: (
                <BlackButton
                  fullWidth
                  size="large"
                  component={Link}
                  to="/notifications/add_counties"
                >
                  {t('notifications.addCounty')}
                </BlackButton>
              ),
              containerProps: { sx: { mt: 6 } },
            },
          ]}
        />
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title={title}
      buttonTitle={buttonTitle}
      onClick={handleDone}
    >
      <CountyNotifications regionSettings={regionSettings} />
    </OnboardingLayout>
  );
};

export default OnboardingNotificationsContent;

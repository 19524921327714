import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { LatLng } from 'shared/types';
import { RiverGauge } from '../../riverGauges.types';

type UseRiverGaugeProps = {
  id?: number | string;
};

type UseRiverGauge = Omit<UseQueryResult<RiverGauge, Error>, 'data'> & {
  riverGauge?: RiverGauge;
};

export type ServerRiverGauge = {
  id: RiverGauge['id'];
  name: RiverGauge['name'];
  data: {
    usgsId: RiverGauge['usgsId'];
    noaaLid: RiverGauge['noaaLid'];
    floodCategories: RiverGauge['floodCategories'];
    floodImpacts: RiverGauge['floodImpacts'];
  };
  units: RiverGauge['units'];
  location: LatLng;
};

const fetchRiverGauge = async (
  riverGaugeId: number | string,
): Promise<RiverGauge> => {
  const response = await API.get<ServerRiverGauge>(
    `weather/station/${riverGaugeId}/`,
  );

  const { data, ...serverRiverGaugeRoot } = response.data;

  return {
    ...serverRiverGaugeRoot,
    ...data,
  };
};

export const useRiverGauge = (props: UseRiverGaugeProps): UseRiverGauge => {
  const { id } = props;
  const { data, ...results } = useQuery({
    queryKey: ['riverGauge', id],
    queryFn: () => fetchRiverGauge(id!),
    enabled: Boolean(id),
  });

  return {
    ...results,
    riverGauge: data,
  };
};

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { MutableRefObject, useEffect } from 'react';

/**
 * Custom hook to handle scrolling to the active element when the keyboard appears.
 * This is necessary on iOS because the keyboard often hides form elements
 * when the user taps on a form field.
 * The hook ensures the active element is scrolled into view to improve usability.
 * Pass a ref to the scrollable container element where the form fields are rendered.
 */
export const useIosKeyboardScroller = (
  scrollRef: MutableRefObject<HTMLDivElement | null>,
): void => {
  useEffect(() => {
    const platform = Capacitor.getPlatform();

    if (platform !== 'ios') {
      return () => {};
    }

    Keyboard.addListener('keyboardDidShow', (info) => {
      if (!document.activeElement) {
        scrollRef.current?.scrollTo({
          top: info.keyboardHeight,
          behavior: 'smooth',
        });
        return;
      }

      const elementRect = document.activeElement.getBoundingClientRect();
      if (elementRect.y > window.innerHeight) {
        scrollRef.current?.scrollBy({
          top: Math.round(
            // Adding a small extra offset (16px) ensures the element is fully visible within the viewport.
            // This accounts for any minor discrepancies in positioning or layout adjustments.
            elementRect.y - window.innerHeight + elementRect.height + 16,
          ),
          behavior: 'smooth',
        });
      }
    });

    return () => {
      Keyboard.removeAllListeners();
    };
  }, [scrollRef]);
};

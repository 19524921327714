import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Link as MuiLink, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useSnackbarState } from 'state';

export const UpgradeCTA = (): JSX.Element => {
  const { t } = useTranslation();
  const { reset: resetSnackbar } = useSnackbarState();

  const openAppStoreAndResetSnackBar = async (): Promise<void> => {
    await AppUpdate.openAppStore();
    resetSnackbar();
  };
  return (
    <MuiLink
      onClick={openAppStoreAndResetSnackBar}
      sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <Typography
        variant="body2"
        sx={{
          marginBottom: '16px',
        }}
      >
        <b>{t('home.upgrade.message')}</b>
      </Typography>
      <Typography variant="body2">
        <Trans i18nKey="home.upgrade.cta">
          Tap to <b>Update Now</b>.
        </Trans>
      </Typography>
    </MuiLink>
  );
};

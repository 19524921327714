import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { NotificationSetting } from 'shared/types';
import RegionList from 'components/RegionList';
import RegionEditorListItem from './RegionEditorListItem';
import CountyNotificationDialog from './CountyNotificationDialog';

type RegionEditorCardProps = {
  title: string;
  regionSettings: NotificationSetting[];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  subheader: {
    padding: 0,
  },
}));

const RegionEditorCard = (props: RegionEditorCardProps): JSX.Element => {
  const { regionSettings, title } = props;
  const { classes } = useStyles();
  const [selectedSetting, setSelectedSetting] =
    useState<NotificationSetting | null>(null);

  return (
    <>
      <RegionList
        name={title}
        className={classes.container}
        subheaderClassName={classes.subheader}
      >
        {regionSettings.map((regionSetting) => (
          <RegionEditorListItem
            key={regionSetting.region.id}
            regionSetting={regionSetting}
            onEdit={() => setSelectedSetting(regionSetting)}
          />
        ))}
      </RegionList>

      <CountyNotificationDialog
        key={selectedSetting?.region.id}
        open={!!selectedSetting}
        onClose={() => setSelectedSetting(null)}
        notificationSetting={selectedSetting}
      />
    </>
  );
};

export default RegionEditorCard;

import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { MAP_ENTITY_DRAWER_MIN_SNAP_PCT } from './MapEntityDrawer.constants';

type MapEntityDrawerState = {
  open: boolean;
  minSnapPct: number;
};

type UseMapEntityDrawerState = {
  open: boolean;
  minSnapPct: number;
  updateDrawerState: (input: { open: boolean; minSnapPct?: number }) => void;
};

const DEFAULT_MAP_ENTITY_DRAWER_STATE: MapEntityDrawerState = {
  open: false,
  minSnapPct: MAP_ENTITY_DRAWER_MIN_SNAP_PCT,
};

const mapEntityDrawerStateAtom = atom({
  key: 'MAP_ENTITY_DRAWER_STATE',
  default: DEFAULT_MAP_ENTITY_DRAWER_STATE,
});

export const useMapEntityDrawerState = (): UseMapEntityDrawerState => {
  const [mapEntityDrawerState, setMapEntityDrawerState] = useRecoilState(
    mapEntityDrawerStateAtom,
  );

  const updateDrawerState = useCallback(
    (input: { open: boolean; minSnapPct?: number }) => {
      const { open, minSnapPct = MAP_ENTITY_DRAWER_MIN_SNAP_PCT } = input;
      setMapEntityDrawerState({ open, minSnapPct });
    },
    [setMapEntityDrawerState],
  );

  return {
    ...mapEntityDrawerState,
    updateDrawerState,
  };
};

import {
  useMap,
  MapMouseEvent,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  Map,
} from 'shared/map-exports';
import { useEffect } from 'react';
import * as Sentry from '@sentry/capacitor';
import { getDeviceInfo } from 'state/localStorageTyped';

export type MapboxFeature<T> = Omit<MapGeoJSONFeature, 'properties'> & {
  properties: T;
};

export type UseMapLayerEventsProps<T> = {
  layerId: string;
  onClick: (features: MapboxFeature<T>[]) => void;
  onHover?: (features: MapboxFeature<T>[], map: Map) => void;
  onHoverOff?: (map: Map) => void;
  stopPropagation?: boolean;
};

export const useMapLayerEvents = <T>(
  props: UseMapLayerEventsProps<T>,
): void => {
  const { layerId, onClick, onHover, onHoverOff } = props;
  const { current: mapRef } = useMap();
  const deviceInfo = getDeviceInfo();

  const isMobilePlatform = !!deviceInfo?.isMobilePlatform;
  useEffect(() => {
    const map = mapRef?.getMap();

    if (!map) {
      return () => {};
    }

    const handleClick = (event: MapMouseEvent): void => {
      if (event.features?.length) {
        onClick(event.features as MapboxFeature<T>[]);
      }
    };

    const handleMouseEnter = (event: MapLayerMouseEvent): void => {
      const canvas = map.getCanvas();
      if (canvas) {
        canvas.style.cursor = 'pointer';
      }
    };

    const handleMouseLeave = (): void => {
      const canvas = map.getCanvas();
      if (canvas) {
        canvas.style.cursor = 'auto';
      }

      if (onHoverOff) {
        onHoverOff(map);
      }
    };

    const handleMouseMove = (event: MapMouseEvent): void => {
      let features: MapGeoJSONFeature[] = [];
      try {
        // Getting error "Unhandled feature index out of bounds" on Sentry
        // https://trello.com/c/2foNOR9Y/1475-investigate-new-ish-frontend-exception-error-feature-index-out-of-bounds-new-as-of-alert-cameras-release
        features = map.queryRenderedFeatures(event.point, {
          layers: [layerId],
        });

        if (onHover && features.length) {
          onHover(features as MapboxFeature<T>[], map);
        }
      } catch (err) {
        Sentry.withScope((scope): void => {
          scope.setContext('handleMouseMove', {
            eventPoint: event.point,
            layerId,
            featuresLength: features.length,
            mapCenter: map.getCenter(),
            mapZoom: map.getZoom(),
            mapBounds: map.getBounds()?.toString(),
          });
          Sentry.captureException(err);
        });
      }
    };

    map.on('click', layerId, handleClick);
    map.on('mouseenter', layerId, handleMouseEnter);
    map.on('mouseleave', layerId, handleMouseLeave);
    if ((onHover || onHoverOff) && !isMobilePlatform) {
      map.on('mousemove', layerId, handleMouseMove);
    }

    return () => {
      map.off('click', layerId, handleClick);
      map.off('mouseenter', layerId, handleMouseEnter);
      map.off('mouseleave', layerId, handleMouseLeave);
      if ((onHover || onHoverOff) && !isMobilePlatform) {
        map.off('mousemove', layerId, handleMouseMove);
      }
    };
  }, [mapRef, layerId, onClick, onHover, onHoverOff, isMobilePlatform]);
};

import { useTheme } from '@mui/material';
import { EChartsOption, MarkLineComponentOption } from 'echarts';
import { useTranslation } from 'react-i18next';
import { formatDate, getDateFormatted } from 'shared/dates';
import { formatNumber } from '../../../../shared/utils';
import { RiverGauge, RiverGaugeObservation } from '../../riverGauges.types';
import { chartWatermarkBase64 } from './chartWatermarkBase64';
import { CHART_LEFT_RIGHT_PADDING } from './useWaterLevelsChart.constants';
import {
  getCategoriesMarkAreaData,
  getChartBounds,
  observationToSeriesData,
} from './useWaterLevelsChart.utils';

type GetEchartsOptionsArgs = {
  observations: RiverGaugeObservation[];
  forecasts?: RiverGaugeObservation[];
  riverGauge?: RiverGauge;
};

export const useWaterLevelsChart = (
  props: GetEchartsOptionsArgs,
): EChartsOption | null => {
  const { riverGauge, observations, forecasts = [] } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  if (!riverGauge) {
    return null;
  }

  const echartsObservationValues = observations.map(observationToSeriesData);
  const echartsForecastValues = forecasts.map(observationToSeriesData);

  const earliestForecastDate = forecasts?.at(0)?.timestamp;

  const { upperBounds, lowerBounds } = getChartBounds(
    [...observations, ...forecasts],
    riverGauge.floodCategories,
  );

  const commonSeriesValues = {
    name: t('riverGauges.weatherLevelsChart.lineLabel'),
    type: 'line' as const,
    smooth: true,
    showSymbol: false,
    encode: {
      x: 'timestamp',
      y: 'value',
    },
  };

  const nowMarkline: MarkLineComponentOption | undefined =
    forecasts.length && earliestForecastDate
      ? {
          lineStyle: {
            color: '#000000',
            width: 2,
            type: 'solid',
          },
          label: {
            show: false,
          },
          symbol: 'none',
          // echarts typing requires this to be a number or string, even though Date works
          data: [{ xAxis: earliestForecastDate as unknown as number }],
        }
      : undefined;

  return {
    animation: false,
    grid: {
      top: 20,
      bottom: 30,
      // ECharts seems to work best when left/right have the same values,
      // particularly for making sure the background graphic's center positioning
      // is actually center of the chart. We can offset this with HTML outside
      // of ECharts. @see <WaterLevelsChartWrapper />
      left: CHART_LEFT_RIGHT_PADDING,
      right: CHART_LEFT_RIGHT_PADDING,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      // Prevent tooltip from covering sticky header and other elements outside of the chart
      extraCssText: 'z-index: 0',
      formatter: (params) => {
        const { value } = params[0];
        const [date, level] = value;
        const timestamp = getDateFormatted(date);
        const levelFormatted = formatNumber(level, 1);

        const keyword =
          earliestForecastDate && date < earliestForecastDate
            ? t('riverGauges.weatherLevelsChart.observed')
            : t('riverGauges.weatherLevelsChart.forecasted');

        return `
          <div>
            <div><strong>${timestamp}</strong></div>
            <div>${keyword}: <strong>${levelFormatted} ${riverGauge.units.level}</strong></div>
          </div>
        `;
      },
    },
    dataset: [
      {
        source: echartsObservationValues,
        dimensions: ['timestamp', 'value'],
      },
      {
        source: echartsForecastValues,
        dimensions: ['timestamp', 'value'],
      },
    ],
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: (value) => formatDate(new Date(value), 'MMM d'),
        hideOverlap: true,
      },
      axisPointer: {
        snap: true,
        lineStyle: {
          color: '#000000',
          width: 2,
        },
        label: {
          show: false,
        },
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      min: lowerBounds,
      max: upperBounds,
      name: t('riverGauges.levelWithUnit', { unit: riverGauge.units.level }),
      nameLocation: 'middle',
      nameTextStyle: {
        color: theme.palette.text.secondary,
      },
      nameGap: 30,
      axisLabel: {
        formatter: (value: number) => formatNumber(value, 1).replace('.0', ''),
      },
      axisPointer: {
        type: 'none',
        triggerOn: 'none',
        snap: false,
        label: {
          show: false,
        },
      },
      splitLine: {
        lineStyle: {
          color: '#E5E5E5',
        },
      },
    },
    series: [
      {
        ...commonSeriesValues,
        datasetIndex: 0,
        markArea: {
          silent: true,
          emphasis: {
            disabled: true,
          },
          label: {
            fontSize: 12,
            textBorderColor: 'transparent',
            position: 'insideBottomLeft',
            color: '#000000',
          },
          data: getCategoriesMarkAreaData({
            t,
            categoryLevels: riverGauge?.floodCategories || [],
            unit: riverGauge.units.level || '',
            lowerBounds,
            upperBounds,
          }),
        },
        markLine: nowMarkline,
      },
      {
        ...commonSeriesValues,
        datasetIndex: 1,
        lineStyle: {
          color: '#A750FF',
          type: 'dashed',
        },
      },
    ],
    graphic: [
      {
        type: 'image',
        style: {
          image: chartWatermarkBase64,
          width: 200,
          opacity: 0.5,
        },
        left: 'center',
        top: 'center',
        right: 'center',
        bottom: 'center',
        z: -1, // Places it behind other chart elements
      },
    ],
  };
};

import { useCallback, useMemo } from 'react';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { Layer, LayerProps, Source } from 'shared/map-exports';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import destination from '@turf/destination';

import { MapIcon } from 'components/Map/types';
import { useExternalGeoEvents } from '../../../hooks/useExternalGeoEvents';
import {
  AlertWestAiHitExternalGeoEvent,
  ExternalGeoEvent,
} from '../../../shared/types';
import {
  ExternalGeoEventMarker,
  ExternalGeoEventMarkerAlertWestHit,
} from '../Icons';
import { FONT_NAMES } from '../styles/constants';
import { useMapLayerEvents } from './useMapLayerEvents';
import { usePoisState } from '../../../state/usePoisState';
import { ExternalGeoEventDialogContent } from '../ExternalGeoEventDialog';
import addVisible from '../../../shared/addVisible';
import { ExternalGeoNameMap, ExternalSource } from '../../../constants';
import useMapLayersState from '../../../state/useMapLayersState';

const ICONS_LAYER_ID = 'external-geo-event-icons';
const LABELS_LAYER_ID = 'external-geo-events-labels';
const LINES_LAYER_ID = 'external-geo-events-lines';
const POINTS_SOURCE_ID = 'external-geo-event-source-points';
const LINES_SOURCE_ID = 'external-geo-event-source-lines';

const LABELS_MIN_ZOOM = 7;
const CAM_AZIMUTH_LINE_LENGTH_MI = 30;

const MinutesCutoffMap: Record<ExternalSource, number> = {
  pulsepoint: 30,
  wildcad: 30,
  nifc: 30,
  chp: 30,
  aircraft_detection: 30,
  alert_west: 5,
};

const IconsMap: Record<ExternalSource, MapIcon> = {
  pulsepoint: ExternalGeoEventMarker,
  wildcad: ExternalGeoEventMarker,
  nifc: ExternalGeoEventMarker,
  chp: ExternalGeoEventMarker,
  aircraft_detection: ExternalGeoEventMarker,
  alert_west: ExternalGeoEventMarkerAlertWestHit,
};

type ExternalGeoEventsLayerProps = {
  visible: boolean;
};

type FeatureProperties = {
  id: number;
  name: string;
  icon: string;
  scale: number;
  offset: [number, number];
  anchor: 'bottom' | 'center';
  isPrescribed: boolean;
  opacity: number;
  acreage?: number;
  address?: string;
  containment?: number;
  dateStart?: string;
  externalSource: string;
  messageId: string;
  channel: string;
  diffInMinutesStr: string;
  sourceName: string;
  index: number;
  indexInv: number;
};

type LineFeatureProperties = {
  opacity: number;
  strokeWidth: number;
};

const iconStyle: LayerProps = {
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
    'icon-anchor': ['get', 'anchor'],
    'icon-ignore-placement': false,
    'icon-size': ['get', 'scale'],
    'icon-offset': ['get', 'offset'],
    'symbol-sort-key': ['get', 'index'],
    'symbol-z-order': 'source',
  },
  paint: {
    'icon-opacity': ['get', 'opacity'],
  },
};

const labelStyleCommon: LayerProps = {
  type: 'symbol',
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': [
      'format',
      ['get', 'sourceName'],
      { 'text-font': ['literal', FONT_NAMES.regular] },
      '\n',
      {},
      ['get', 'diffInMinutesStr'],
      { 'font-scale': 0.7 },
    ],
    'text-anchor': 'top',
    'text-justify': 'auto',
    'symbol-sort-key': ['get', 'indexInv'],
    'symbol-z-order': 'source',
  },
  paint: {
    'text-halo-width': 1.8,
    'text-opacity': [
      'step',
      ['zoom'],
      0,
      LABELS_MIN_ZOOM, // Zoom level at which opacity changes
      1, // Opacity when zoom is greater than or equal to 7
    ],
  },
};

const labelStyleLight: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#333333',
    'text-halo-color': 'rgba(255,255,255,0.8)',
  },
};

const labelStyleDark: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)',
  },
};

const lineStyle: LayerProps = {
  type: 'line',
  paint: {
    'line-opacity': ['get', 'opacity'],
    'line-width': ['get', 'strokeWidth'],
    'line-color': '#FF55FF',
  },
};

const getDiffInMinutes = (record: ExternalGeoEvent): number => {
  const { dateCreated: dateCreatedStr } = record;
  const now = new Date();
  const dateCreated = new Date(dateCreatedStr);
  return differenceInMinutes(now, dateCreated);
};

const getOpacity = (record: ExternalGeoEvent): number => {
  const diffInMinutes = getDiffInMinutes(record);

  const cutoff = MinutesCutoffMap[record.data.externalSource];
  if (diffInMinutes >= cutoff) {
    return 0;
  }
  return 1 - diffInMinutes / cutoff;
};

const getScale = (record: ExternalGeoEvent): number => {
  const diffInMinutes = getDiffInMinutes(record);
  const baseScale = 0.9;

  const cutoff = MinutesCutoffMap[record.data.externalSource];
  if (diffInMinutes < cutoff * 0.3) {
    return baseScale;
  }
  if (diffInMinutes >= cutoff * 0.3 && diffInMinutes < cutoff * 0.6) {
    return baseScale * 0.7;
  }
  return baseScale * 0.4;
};

const toPointGeojsonFeature = (
  record: ExternalGeoEvent,
): Feature<Geometry, FeatureProperties> => {
  const {
    id,
    lat,
    lng,
    messageId,
    channel,
    data: {
      acreage,
      isPrescribed,
      containment,
      name,
      dateStart,
      address,
      externalSource,
    },
  } = record;

  const icon = IconsMap[externalSource] || ExternalGeoEventMarker;

  const opacity = getOpacity(record);
  const scale = getScale(record) * (icon.scale || 1.0);
  const offset = icon.offset || [0, 0];
  const anchor = icon.offset ? 'bottom' : 'center';
  const sourceName = ExternalGeoNameMap[externalSource] || externalSource;
  const diffInMinutesStr = `${getDiffInMinutes(record)} mins ago`;

  // Always place alert_west events on the bottom since they can be noisy.
  const index =
    externalSource === 'alert_west' ? 0 : Date.parse(record.dateCreated);
  const indexInv = 1 / (index + 0.1);

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat],
    },
    properties: {
      id,
      name,
      icon: icon.name,
      diffInMinutesStr,
      sourceName,
      scale,
      isPrescribed,
      opacity,
      offset,
      anchor,
      acreage,
      address,
      containment,
      dateStart,
      externalSource,
      messageId,
      channel,
      index,
      indexInv,
    },
  };
};

const toLineGeojsonFeature = (
  record: ExternalGeoEvent,
): Feature<Geometry, LineFeatureProperties> | null => {
  const {
    data: { externalSource },
  } = record;
  if (externalSource !== 'alert_west') {
    return null;
  }

  const {
    lat,
    lng,
    data: { hitAz, hitConfidence },
  } = record as AlertWestAiHitExternalGeoEvent;
  const opacity = getOpacity(record);
  const endPoint = destination([lng, lat], CAM_AZIMUTH_LINE_LENGTH_MI, hitAz, {
    units: 'miles',
  });
  const strokeWidth = Math.max(1, (hitConfidence - 0.5) * 10);
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [[lng, lat], endPoint.geometry.coordinates],
    },
    properties: {
      opacity,
      strokeWidth,
    },
  };
};

const ExternalGeoEventsLayer = (
  props: ExternalGeoEventsLayerProps,
): JSX.Element => {
  const { visible } = props;
  const { externalGeoEvents } = useExternalGeoEvents();
  const { setSelectedPoi } = usePoisState();
  const { isLightBaseLayer } = useMapLayersState();

  const geojsonPoints: FeatureCollection<Geometry, FeatureProperties> = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: externalGeoEvents.map((externalGeoEvent) =>
        toPointGeojsonFeature(externalGeoEvent),
      ),
    }),
    [externalGeoEvents],
  );

  const geojsonLines: FeatureCollection<Geometry, LineFeatureProperties> =
    useMemo(
      () => ({
        type: 'FeatureCollection',
        features: externalGeoEvents
          .map(toLineGeojsonFeature)
          .filter(Boolean) as Feature<Geometry, LineFeatureProperties>[],
      }),
      [externalGeoEvents],
    );

  useMapLayerEvents<FeatureProperties>({
    layerId: ICONS_LAYER_ID,
    onClick: useCallback(
      (geoJsonFeatures) => {
        const features = [...geoJsonFeatures];
        const feature = features[0];
        const match = externalGeoEvents.find(
          (extGeo) => extGeo.id === feature.properties.id,
        );
        if (!match) {
          console.error('no-match-found');
          return;
        }
        setSelectedPoi({
          type: 'externalGeoEvent',
          PoiDialogContent: () => (
            <ExternalGeoEventDialogContent externalGeoEvent={match} />
          ),
        });
      },
      [setSelectedPoi, externalGeoEvents],
    ),
  });

  const labelStyle = isLightBaseLayer ? labelStyleLight : labelStyleDark;

  return (
    <>
      <Source id={LINES_SOURCE_ID} type="geojson" data={geojsonLines}>
        <Layer id={LINES_LAYER_ID} {...addVisible(lineStyle, visible)} />
      </Source>
      <Source id={POINTS_SOURCE_ID} type="geojson" data={geojsonPoints}>
        <Layer id={ICONS_LAYER_ID} {...addVisible(iconStyle, visible)} />
        <Layer id={LABELS_LAYER_ID} {...addVisible(labelStyle, visible)} />
      </Source>
    </>
  );
};

export default ExternalGeoEventsLayer;

import {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
  ReactNode,
  RefObject,
  useState,
} from 'react';
import {
  Drawer,
  useTheme,
  useMediaQuery,
  Box,
  Card,
  CardContent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
  RefHandles,
  SpringEvent,
  defaultSnapProps,
} from 'react-spring-bottom-sheet/dist/types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NetworkStatusAlert from 'components/NetworkStatusAlert';
import { usePoisState } from 'state/usePoisState';
import useDrawerSnapPointState from 'state/useDrawerSnapPointState';
import { Capacitor } from '@capacitor/core';
import { MAP_DOM_ELEMENT_ID } from '../../../constants';
import { MAP_ENTITY_DRAWER_WIDTH } from './MapEntityDrawer.constants';
import useScrollPosition from '../../../hooks/useScrollPosition';
import {
  DesktopScrollProvider,
  MobileScrollProvider,
} from './ScrollContextProviders';
import { useMapEntityDrawerState } from './useMapEntityDrawerState';

export type DrawerRefContent = {
  isOpen: () => boolean;
  minimize: () => void;
};

type MapEntityDrawerProps = {
  children: ReactNode;
  open?: boolean;
  snapPointsPct: number[];
  defaultSnapPointPct: number;
  entityId?: string | number;
  onClose?: () => void;
};

const MAP_ENTITY_DRAWER_MOBILE_DRAWER_ID = 'map-entity-drawer-bottom-sheet';

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    paddingTop: 'calc(64px + env(safe-area-inset-top))',
    paddingBottom: `env(safe-area-inset-bottom)`,
    width: MAP_ENTITY_DRAWER_WIDTH,
    pointerEvents: 'auto',
  },
  mobileHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileNetworkAlert: {
    top: 'calc(-100% - 24px)',
  },
  card: {
    borderRadius: 0,
    minHeight: '100%',
    position: 'relative',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.up(660)]: {
      paddingTop: theme.spacing(2),
    },
  },
}));

export const MapEntityDrawer = forwardRef<
  DrawerRefContent,
  MapEntityDrawerProps
>((props, ref): JSX.Element => {
  const {
    children,
    open = false,
    snapPointsPct,
    defaultSnapPointPct,
    entityId,
    onClose,
  } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const sheetRef = useRef<RefHandles | null>(null);
  const scrollElementRef = useRef<Element>();
  const { drawerHeight, updateDrawerSnapPoint } =
    useDrawerSnapPointState('map-entity-drawer');
  const history = useHistory();
  const { selectedPoi, clearSelectedPois } = usePoisState();
  const { t } = useTranslation();
  const { open: openState, updateDrawerState } = useMapEntityDrawerState();
  const [isBottomSheetOpening, setIsBottomSheetOpening] = useState(false);

  if (snapPointsPct.length < 1) {
    throw new Error('snapPointsPct must have at least one element');
  }
  if (!snapPointsPct.includes(defaultSnapPointPct)) {
    throw new Error(
      `defaultSnapPointPct (${defaultSnapPointPct}) must be in snapPointsPct (${snapPointsPct})`,
    );
  }

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'ios') {
      return () => {};
    }

    const statusTapHandler = (): void => {
      if (scrollElementRef.current) {
        scrollElementRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    window.addEventListener('statusTap', statusTapHandler);

    return () => {
      window.removeEventListener('statusTap', statusTapHandler);
    };
  }, []);

  const minSnapPct = snapPointsPct[0];

  // We need to track the open state ourselves since the drawer takes some time to close
  useEffect(() => {
    updateDrawerState({ open, minSnapPct });
  }, [minSnapPct, open, updateDrawerState]);

  useEffect(() => {
    if (!open || !sheetRef.current || isBottomSheetOpening) {
      return;
    }
    // Makes sure bottom sheet starts on the default snap point
    sheetRef.current.snapTo(window.innerHeight * defaultSnapPointPct);
    // Do not remove the entityId dependency. It's essential for ensuring the drawer
    // resets to its default snap point whenever the entityId changes,
    // enabling proper behavior when users select entity drawers consecutively.
  }, [open, defaultSnapPointPct, entityId, isBottomSheetOpening]);

  useEffect(() => {
    if (sheetRef.current && selectedPoi && !isBottomSheetOpening) {
      // Minimize the bottom sheet when a POI is selected
      sheetRef.current.snapTo(window.innerHeight * minSnapPct);
    }
  }, [minSnapPct, selectedPoi, isBottomSheetOpening]);

  useImperativeHandle(ref, () => {
    return {
      isOpen: () => open,
      minimize(): void {
        if (!sheetRef.current) return;

        const minSnapPoint = Math.round(window.innerHeight * snapPointsPct[0]);
        if (sheetRef.current.height > minSnapPoint) {
          sheetRef.current.snapTo(minSnapPoint);
        }
      },
    };
  });

  const {
    scrollPosition: lastScrollPosition,
    handleScroll,
    resetScrollPosition,
  } = useScrollPosition(
    scrollElementRef,
    `geo-event-drawer-${history.location.pathname + history.location.hash}`,
  );

  const handleClose = (): void => {
    updateDrawerState({ open: false });
    updateDrawerSnapPoint(0);
    if (lastScrollPosition) resetScrollPosition();
    if (onClose) onClose();
    // closing animation for the bottom drawer takes longer, so ensure that the animation has finished before we re-route
    setTimeout(() => history.push('/'), isLargeMediaQuery ? 50 : 350);
  };

  const handleSpringStart = (event: SpringEvent): void => {
    if (event.type === 'OPEN') {
      setIsBottomSheetOpening(true);

      const bottomSheetScrollElement = document.querySelector(
        `#${MAP_ENTITY_DRAWER_MOBILE_DRAWER_ID} [data-rsbs-scroll]`,
      );

      if (bottomSheetScrollElement) {
        scrollElementRef.current = bottomSheetScrollElement;

        if (lastScrollPosition) {
          scrollElementRef.current.scrollTo({ top: lastScrollPosition });
        }
      }

      return;
    }

    if (event.type === 'SNAP' && event.source === 'custom') {
      return;
    }

    clearSelectedPois();
  };

  const handleSpringEnd = (event: SpringEvent): void => {
    if (event.type === 'OPEN') {
      setIsBottomSheetOpening(false);
    }

    if (!sheetRef.current || !scrollElementRef.current) return;

    if (event.type === 'SNAP' && event.source === 'dragging') {
      updateDrawerSnapPoint(sheetRef.current.height);
    }

    const defaultSnapPoint = window.innerHeight * snapPointsPct[0];

    if (sheetRef.current.height - 5 > defaultSnapPoint) return;

    scrollElementRef.current.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const getDefaultSnapPoint = useCallback(
    ({ maxHeight }: defaultSnapProps): number => {
      return Math.max(
        drawerHeight,
        Math.round(maxHeight * defaultSnapPointPct),
      );
    },
    [defaultSnapPointPct, drawerHeight],
  );

  if (isLargeMediaQuery) {
    return (
      <Drawer
        sx={(th) => ({ pointerEvents: 'none', zIndex: th.zIndex.drawer + 1 })}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
        open={openState}
        onClose={handleClose}
        PaperProps={{ 'aria-label': t('geoEvent.details') }}
        hideBackdrop
        container={() => document.getElementById(MAP_DOM_ELEMENT_ID)}
      >
        <Box sx={{ position: 'relative', height: '100%' }}>
          <Card
            className={classes.card}
            sx={{
              overflow: 'auto',
              maxHeight: '100%',
            }}
            ref={scrollElementRef as RefObject<HTMLDivElement>}
            onScrollCapture={handleScroll}
            elevation={0}
          >
            <CardContent id="mapdrawercardcontent" sx={{ padding: 0 }}>
              <DesktopScrollProvider
                scrollElementRef={scrollElementRef}
                initialScrollPosition={lastScrollPosition}
                closeDrawer={handleClose}
              >
                {children}
              </DesktopScrollProvider>
            </CardContent>
          </Card>
        </Box>
      </Drawer>
    );
  }

  return (
    <BottomSheet
      id={MAP_ENTITY_DRAWER_MOBILE_DRAWER_ID}
      open={openState}
      onDismiss={handleClose}
      skipInitialTransition
      ref={sheetRef}
      onScrollCapture={handleScroll}
      snapPoints={({ maxHeight }) =>
        snapPointsPct.map((pct) => Math.round(maxHeight * pct))
      }
      defaultSnap={getDefaultSnapPoint}
      expandOnContentDrag
      blocking={false}
      header={
        <div className={classes.mobileHeader}>
          <NetworkStatusAlert containerClassName={classes.mobileNetworkAlert} />
        </div>
      }
      onSpringEnd={handleSpringEnd}
      onSpringStart={handleSpringStart}
      // Locks maxHeight in cases when orientation changes, preventing
      // changes on snap points and interactions issues
      maxHeight={window.innerHeight}
      aria-label={t('geoEvent.details')}
    >
      <Box sx={{ position: 'relative', height: '100%' }}>
        <Card
          className={classes.card}
          sx={{
            overflow: 'unset',
            maxHeight: 'unset',
          }}
          elevation={0}
        >
          <CardContent sx={{ padding: 0 }}>
            <MobileScrollProvider
              scrollElementRef={scrollElementRef}
              closeDrawer={handleClose}
            >
              {children}
            </MobileScrollProvider>
          </CardContent>
        </Card>
      </Box>
    </BottomSheet>
  );
});

MapEntityDrawer.displayName = 'MapEntityDrawer';

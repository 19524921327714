import { useCallback, useContext, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  useMediaQuery,
  Grid,
  Tabs,
  Tab,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { ChildLocation, GeoEvent } from 'shared/types';
import ReportList from 'components/GeoEventReportList';
import { getRegionsDisplay, getResponsiveFontSize } from 'shared/utils';
import { SupportUsBanner } from 'components/SupportUsBanner';
import { TabPanel, a11yProps } from 'components/TabPanel';
import { Helmet } from 'react-helmet';
import { MapEntityDrawerContext } from 'components/Map/MapEntityDrawer';
import { MapEntityDrawerHeader } from 'components/Map/MapEntityDrawerHeader';
import { useGeoEventQuery } from 'features/geoEvents/hooks/useGeoEventQuery';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import { DrawerStickyHeader } from 'components/DrawerStickyHeader';
import GeoEventInfo from './GeoEventInfo';
import GeoEventEvacInfo from './GeoEventEvacInfo';
import AutomatedIncidentDisclaimer from './AutomatedIncidentDisclaimer';
import GeoEventLocations from './GeoEventLocations';
import GeoEventStructuredInfo from './GeoEventStructuredInfo';
import { useGeoEventBanner } from './useGeoEventBanner';
import GeoEventEvacBtn from './GeoEventEvacBtn';

const TAB_PANEL_ID_PREFIX = 'incident-details';

const getChildLocations = (geoEvent: GeoEvent): ChildLocation[] =>
  geoEvent.childGeoEvents.filter(
    (ge) => ge.geoEventType === 'location',
  ) as ChildLocation[];

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    borderRadius: 0,
    minHeight: '100%',
    position: 'relative',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    [theme.breakpoints.up(660)]: {
      paddingTop: theme.spacing(1),
    },
  },
  badge: {
    height: 16,
    minWidth: 17,
    borderRadius: 6,
    marginLeft: 8,
    padding: 0,
    paddingBottom: 1,
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(12)),
    fontWeight: 700,
  },
  stickyEl: {
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 8px)',
      bottom: 0,
      left: 4,
      zIndex: -1,
      boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
}));

const GeoEventDetails = (): JSX.Element => {
  const geoEventId = useSelectedWildfireGeoEventId();
  const { geoEvent, isLoading, isError } = useGeoEventQuery<GeoEvent>();
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up(660), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const { showBanner, handleClose } = useGeoEventBanner({ geoEvent });

  const hasShelters =
    geoEvent &&
    geoEvent.childGeoEvents.filter((ge) => ge.geoEventType === 'location')
      .length > 0;

  const { scrollElementRef } = useContext(MapEntityDrawerContext);

  const [stickyHeaderState, setStickyHeaderState] = useState<{
    isFixed: boolean;
    contentHeight: number;
  }>({ isFixed: false, contentHeight: 0 });
  const [evacTopBtn, setEvacTopBtn] = useState<boolean>(false);

  const handleTop = (): void => {
    if (!scrollElementRef) {
      return;
    }

    if (isLargeMediaQuery) {
      scrollElementRef.current?.scrollTo({ behavior: 'smooth', top: 0 });
    } else {
      scrollElementRef.current?.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  const handleStickyHeaderContentHeightChange = useCallback(
    (contentHeight: number) => {
      setStickyHeaderState((prev) => ({ ...prev, contentHeight }));
    },
    [],
  );

  if (isLoading) {
    return <LoadingAndErrors isLoading />;
  }

  if (isError || !geoEvent || !geoEventId) {
    return <LoadingAndErrors />;
  }

  const complexName = geoEvent.parentGeoEvents?.[0]?.name;

  return (
    <>
      <Helmet>
        <title>{t('home.wildfireTitle', { name: geoEvent.name })}</title>
      </Helmet>

      <Grid container>
        <Grid item xs={12}>
          <DrawerStickyHeader
            scrollContainer={scrollElementRef?.current as HTMLElement}
            onStickyChange={(isFixed) =>
              setStickyHeaderState((prev) => ({ ...prev, isFixed }))
            }
            onStickyContentHeightChange={handleStickyHeaderContentHeightChange}
          >
            <DrawerStickyHeader.ContentContainer>
              <MapEntityDrawerHeader>
                {geoEvent.name}
                {complexName && (
                  <Chip
                    variant="watchduty"
                    label={complexName}
                    sx={{
                      marginLeft: 1,
                      backgroundColor: 'watchdutyComplex.main',
                    }}
                  />
                )}
              </MapEntityDrawerHeader>

              {!!geoEvent.address && !stickyHeaderState.isFixed && (
                <Typography data-testid="geoevent-address">
                  {geoEvent.address}
                </Typography>
              )}

              <Typography variant="body2" color="textSecondary">
                {getRegionsDisplay(geoEvent.regions)}
              </Typography>
            </DrawerStickyHeader.ContentContainer>
          </DrawerStickyHeader>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ padding: '0px 16px 16px' }}>
            <GeoEventInfo geoEvent={geoEvent} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ padding: '0px 16px 16px' }}>
            {geoEvent.reporterManaged ? (
              <GeoEventEvacInfo geoEvent={geoEvent} />
            ) : (
              <AutomatedIncidentDisclaimer geoEvent={geoEvent} />
            )}
          </Box>
        </Grid>

        {showBanner && (
          <Grid item xs={12}>
            <Box sx={{ padding: '0px 16px 16px' }}>
              <SupportUsBanner
                mode="dark"
                geoEventId={geoEventId}
                onClose={handleClose}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <DrawerStickyHeader
            scrollContainer={scrollElementRef?.current as HTMLElement}
            topOffset={stickyHeaderState.contentHeight * -1}
            stickyStyle={{ marginTop: stickyHeaderState.contentHeight }}
            onStickyChange={(isVisible) => setEvacTopBtn(isVisible)}
          >
            {evacTopBtn && (
              <GeoEventEvacBtn geoEvent={geoEvent} handleTop={handleTop} />
            )}

            <Tabs
              variant="fullWidth"
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              aria-label="incident tabs"
              indicatorColor="primary"
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab
                label={t('geoEvent.tabs.updates')}
                {...a11yProps(TAB_PANEL_ID_PREFIX, 0)}
              />
              {hasShelters && (
                <Tab
                  label={
                    <>
                      <span>{t('geoEvent.tabs.shelters')}</span>
                      <Chip
                        color="error"
                        size="small"
                        label={
                          geoEvent.childGeoEvents.filter(
                            (ge) => ge.geoEventType === 'location',
                          ).length
                        }
                        className={classes.badge}
                        sx={{
                          '& .MuiChip-label': {
                            overflow: 'visible',
                          },
                        }}
                      />
                    </>
                  }
                  {...a11yProps(TAB_PANEL_ID_PREFIX, 1)}
                />
              )}
              <Tab
                label={t('geoEvent.tabs.info')}
                {...a11yProps(TAB_PANEL_ID_PREFIX, hasShelters ? 2 : 1)}
              />
            </Tabs>
          </DrawerStickyHeader>
        </Grid>
      </Grid>

      <TabPanel value={activeTab} index={0} idPrefix={TAB_PANEL_ID_PREFIX}>
        <ReportList geoEventId={geoEventId} />
      </TabPanel>

      <TabPanel value={activeTab} index={1} idPrefix={TAB_PANEL_ID_PREFIX}>
        <GeoEventLocations locations={getChildLocations(geoEvent)} />
      </TabPanel>

      <TabPanel
        value={activeTab}
        index={hasShelters ? 2 : 1}
        idPrefix={TAB_PANEL_ID_PREFIX}
      >
        <GeoEventStructuredInfo geoEvent={geoEvent} />
      </TabPanel>
    </>
  );
};

GeoEventDetails.displayName = 'GeoEventDetails';

export default GeoEventDetails;

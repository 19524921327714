import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import InboxMessagePopup from 'components/InboxMessagePopup';
import usePopupMessage from 'hooks/usePopupMessage';
import NetworkStatusAlert from 'components/NetworkStatusAlert';
import { useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import IncidentsMap from 'components/IncidentsMap';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import {
  MapEntityDrawer,
  DrawerRefContent,
  MAP_ENTITY_DRAWER_MIN_SNAP_PCT,
  CAMERA_DRAWER_MIN_SNAP_PCT,
  useMapDrawerEntitySelectorReconciler,
} from 'components/Map/MapEntityDrawer';
import { CameraDrawerDetails } from 'components/CameraDetails';
import { MapProvider } from 'shared/map-exports';
import { useRiverGaugesEnabled } from 'features/riverGauges/hooks/useRiverGaugesEnabled';
import GeoEventDetails from '../GeoEventCard/GeoEventDetails';
import LocationDetails from '../LocationContent/LocationDetails';
import { RiverGaugeDetails } from '../../../../pages/RiverGaugeDetails';
import { ROUTER_ENTITY_TO_ROUTE_PATH_MAP } from '../../../../constants';

const MAP_DRAWER_ENTITY_SNAP_POINTS = [
  MAP_ENTITY_DRAWER_MIN_SNAP_PCT,
  0.47,
  0.8,
];

const CAMERA_MAP_DRAWER_ENTITY_SNAP_POINTS = [
  CAMERA_DRAWER_MIN_SNAP_PCT,
  0.4,
  0.7,
  0.8,
];

const HomeContent = (): JSX.Element => {
  const { t } = useTranslation();
  const { message, setMessageAsRead } = usePopupMessage();
  const drawerRef = useRef<DrawerRefContent | null>(null);
  const { mapDrawerEntity, setMapDrawerEntity } = useMapDrawerEntityState();
  useMapDrawerEntitySelectorReconciler();

  const showRiverGauges = useRiverGaugesEnabled();

  return (
    <>
      <Helmet>
        {Capacitor.isNativePlatform() ? (
          <title>{t('home.title')}</title>
        ) : (
          <title>{t('home.webAppTitle')}</title>
        )}
        <meta property="og:title" content="Watch Duty Wildfire Map" />
        <meta
          property="og:description"
          content="Wildfire map and tracker with reports, wind, satellites, and more"
        />
      </Helmet>

      <MapProvider>
        <IncidentsMap drawerRef={drawerRef} />
      </MapProvider>

      <Switch>
        <Route
          exact
          path="/"
          // Keep <MapEntityDrawer /> mounted so the mobile drawer slides open
          // rather than popping open
          render={() => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={MAP_DRAWER_ENTITY_SNAP_POINTS}
              defaultSnapPointPct={0.47}
              onClose={() => {
                if (mapDrawerEntity) {
                  setMapDrawerEntity(null);
                }
              }}
              open={!!mapDrawerEntity}
              entityId={mapDrawerEntity?.id}
            >
              {mapDrawerEntity && <mapDrawerEntity.Content />}
            </MapEntityDrawer>
          )}
        />
        <Route
          exact
          path={ROUTER_ENTITY_TO_ROUTE_PATH_MAP.wildfire}
          render={(props) => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={MAP_DRAWER_ENTITY_SNAP_POINTS}
              defaultSnapPointPct={0.47}
              open
              entityId={props.match.params.geoEventId}
            >
              <GeoEventDetails />
            </MapEntityDrawer>
          )}
        />
        <Route
          exact
          path={ROUTER_ENTITY_TO_ROUTE_PATH_MAP.location}
          render={(props) => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={MAP_DRAWER_ENTITY_SNAP_POINTS}
              defaultSnapPointPct={0.47}
              open
              entityId={props.match.params.locationId}
            >
              <LocationDetails />
            </MapEntityDrawer>
          )}
        />
        <Route
          path={ROUTER_ENTITY_TO_ROUTE_PATH_MAP.camera}
          render={(props) => (
            <MapEntityDrawer
              ref={drawerRef}
              snapPointsPct={CAMERA_MAP_DRAWER_ENTITY_SNAP_POINTS}
              defaultSnapPointPct={0.7}
              open
              entityId={props.match.params.cameraId}
            >
              <CameraDrawerDetails />
            </MapEntityDrawer>
          )}
        />
        {showRiverGauges && (
          <Route
            exact
            path={ROUTER_ENTITY_TO_ROUTE_PATH_MAP.gauge}
            render={(props) => (
              <MapEntityDrawer
                ref={drawerRef}
                snapPointsPct={[0.207, 0.47, 0.8]}
                defaultSnapPointPct={0.47}
                open
                entityId={props.match.params.gaugeId}
              >
                <RiverGaugeDetails />
              </MapEntityDrawer>
            )}
          />
        )}
      </Switch>

      <NetworkStatusAlert />

      <InboxMessagePopup
        open={!!message}
        onClose={setMessageAsRead}
        message={message}
      />
    </>
  );
};

export default HomeContent;

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { HeaderBar } from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import SupportUsContent from './SupportUsContent';
import { MembershipPlanProvider } from './MembershipPlanProvider';

const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('supportUs.title')}</title>
      </Helmet>
      <MembershipPlanProvider>
        <SupportUsContent />
      </MembershipPlanProvider>
    </>
  );
};

const SupportUs = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PageLayout data-cname="SupportUsPage">
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar
            title={t('supportUs.title')}
            action="back"
            bgColor={theme.palette.background.membership}
            elevation={0}
          />
        ),
      }}
    </PageLayout>
  );
};

export default SupportUs;

import { Stack, Typography } from '@mui/material';
import { FloodImpact } from 'features/riverGauges/riverGauges.types';
import { useTranslation } from 'react-i18next';

type FloodImpactsProps = {
  floodImpacts: FloodImpact[];
};

export const FloodImpacts = (props: FloodImpactsProps): JSX.Element => {
  const { floodImpacts } = props;
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="h3">{t('riverGauges.floodImpacts')}</Typography>

      {[...floodImpacts]
        .sort((a, b) => b.level - a.level)
        .map(({ level, statement }) => (
          <Typography key={level}>
            <b>{level}:</b> {statement}
          </Typography>
        ))}
    </Stack>
  );
};

import { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
  Alert,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EvacZone,
  FormEvacZoneStatus,
  RegionEvacZoneStyle,
} from 'shared/types';
import {
  getEvacZoneDefaultDescription,
  getResponsiveFontSize,
} from 'shared/utils';
import RichTextEditor from 'components/RichTextEditor';
import {
  EVAC_ZONE_LABEL_TRANS_KEYS,
  EvacZoneStatuses,
} from '../../../../constants';
import TwoSideMultiSelect from './TwoSideMultiSelect';

type EvacuationsProps = {
  evacZones: EvacZone[];
  zoneStyle: RegionEvacZoneStyle;
};

const createAlphabeticalComparator = <T, U extends string>(
  getKey: (obj: T) => U,
) => {
  return (a: T, b: T): number => {
    const valA = getKey(a).toLowerCase();
    const valB = getKey(b).toLowerCase();
    return valA.localeCompare(valB);
  };
};

const useStyles = makeStyles()((theme) => ({
  alertInfo: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    '& svg': {
      fontSize: getResponsiveFontSize('1.25rem'),
    },
  },
  editor: {
    minHeight: 42,
  },
  notesEditor: {
    minHeight: 84,
  },
}));

export const Evacuations = (props: EvacuationsProps): JSX.Element => {
  const { evacZones, zoneStyle } = props;
  const { control, formState, watch, setValue, clearErrors } = useFormContext();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const evacZoneStatuses = watch('evacZoneStatuses') as FormEvacZoneStatus[];
  const temporarilyDisplayEvacZones = watch(
    'temporarilyDisplayEvacZones',
  ) as boolean;
  const [customOrders, customWarnings, customAdvisories] = watch([
    'customOrders',
    'customWarnings',
    'customAdvisories',
  ]) as [boolean, boolean, boolean];

  const evacZoneStatusIds = useMemo(
    () => evacZoneStatuses.map((eZS) => eZS.evacZone.id),
    [evacZoneStatuses],
  );

  const noStatusZones = useMemo(() => {
    return evacZones
      .filter((evacZone) => !evacZoneStatusIds.includes(evacZone.id))
      .sort(
        createAlphabeticalComparator<EvacZone, string>(
          (item) => item.displayName,
        ),
      );
  }, [evacZoneStatusIds, evacZones]);

  const { evacZonesOrders, evacZonesWarnings, evacZonesAdvisories } =
    useMemo(() => {
      const sortedEvacZoneStatuses = evacZoneStatuses.sort(
        createAlphabeticalComparator<FormEvacZoneStatus, string>(
          (item) => item.evacZone.displayName,
        ),
      );

      const ordersEvacZones = sortedEvacZoneStatuses.filter(
        (zone) => zone.status === EvacZoneStatuses.orders,
      );
      const warningsEvacZones = sortedEvacZoneStatuses.filter(
        (zone) => zone.status === EvacZoneStatuses.warnings,
      );
      const advisoriesEvacZones = sortedEvacZoneStatuses.filter(
        (zone) => zone.status === EvacZoneStatuses.advisories,
      );

      return {
        evacZonesOrders: ordersEvacZones,
        evacZonesWarnings: warningsEvacZones,
        evacZonesAdvisories: advisoriesEvacZones,
      };
    }, [evacZoneStatuses]);

  if (evacZones.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info" className={classes.alertInfo}>
            {t('geoEventEvacuations.noEvacZones')}
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="evacuationOrders"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              return (
                <>
                  <RichTextEditor
                    id="field-control-evacuation-orders"
                    data-testid="evacuation-orders"
                    label={t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].orders)}
                    initialValue={
                      formState.defaultValues?.evacuationOrders || ''
                    }
                    onChange={field.onChange}
                    editable={!formState.isSubmitting}
                    error={!!fieldState.error}
                    editorClassName={classes.editor}
                    onBlur={field.onBlur}
                  />
                  {!!fieldState.error && (
                    <FormHelperText
                      error
                      sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                    >
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="evacuationWarnings"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              return (
                <>
                  <RichTextEditor
                    id="field-control-evacuation-warnings"
                    data-testid="evacuation-warnings"
                    label={t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].warnings)}
                    initialValue={
                      formState.defaultValues?.evacuationWarnings || ''
                    }
                    onChange={field.onChange}
                    editable={!formState.isSubmitting}
                    error={!!fieldState.error}
                    editorClassName={classes.editor}
                    onBlur={field.onBlur}
                  />
                  {!!fieldState.error && (
                    <FormHelperText
                      error
                      sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                    >
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="evacuationAdvisories"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              return (
                <>
                  <RichTextEditor
                    id="field-control-evacuation-advisories"
                    data-testid="evacuation-advisories"
                    label={t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].advisories)}
                    initialValue={
                      formState.defaultValues?.evacuationAdvisories || ''
                    }
                    onChange={field.onChange}
                    editable={!formState.isSubmitting}
                    error={!!fieldState.error}
                    editorClassName={classes.editor}
                    onBlur={field.onBlur}
                  />
                  {!!fieldState.error && (
                    <FormHelperText
                      error
                      sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                    >
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="evacuationNotes"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              return (
                <>
                  <RichTextEditor
                    id="field-control-evacuation-notes"
                    data-testid="evacuation-notes"
                    label={t('evacZones.notes')}
                    initialValue={
                      formState.defaultValues?.evacuationNotes || ''
                    }
                    onChange={field.onChange}
                    editable={!formState.isSubmitting}
                    error={!!fieldState.error}
                    editorClassName={classes.editor}
                    onBlur={field.onBlur}
                  />
                  {!!fieldState.error && (
                    <FormHelperText
                      error
                      sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                    >
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          disabled={
            evacZonesOrders.length > 0 ||
            evacZonesWarnings.length > 0 ||
            evacZonesAdvisories.length > 0 ||
            customOrders ||
            customWarnings ||
            customAdvisories
          }
          control={
            <Checkbox
              name="temporarilyDisplayEvacZones"
              checked={temporarilyDisplayEvacZones}
              onChange={(event) => {
                setValue('temporarilyDisplayEvacZones', event.target.checked);
                clearErrors('temporarilyDisplayEvacZones');
              }}
            />
          }
          label={t('geoEventEvacuations.inputs.temporarilyDisplayEvacZones')}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          {t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].orders)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TwoSideMultiSelect
          disabled={temporarilyDisplayEvacZones}
          evacZoneStatus={EvacZoneStatuses.orders}
          availableZones={noStatusZones}
          selectedZones={evacZonesOrders}
          downgradeable
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          disabled={temporarilyDisplayEvacZones}
          control={
            <Checkbox
              name="customOrders"
              checked={customOrders}
              onChange={(event) => {
                setValue('customOrders', event.target.checked);
                clearErrors('evacuationOrders');
              }}
            />
          }
          label={t('geoEventEvacuations.inputs.evacuationOrders.enableCustom')}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="evacuationOrders"
          control={control}
          render={({ field, fieldState }): JSX.Element => {
            const initialValue = customOrders
              ? formState.defaultValues?.evacuationOrders
              : getEvacZoneDefaultDescription(evacZonesOrders);
            return (
              <>
                <RichTextEditor
                  id="field-control-evacuation-orders"
                  data-testid="evacuation-orders"
                  label={t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].orders)}
                  initialValue={initialValue || ''}
                  onChange={field.onChange}
                  editable={
                    customOrders &&
                    !temporarilyDisplayEvacZones &&
                    !formState.isSubmitting
                  }
                  error={!!fieldState.error}
                  editorClassName={classes.editor}
                  onBlur={field.onBlur}
                />
                {!!fieldState.error && (
                  <FormHelperText
                    error
                    sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                  >
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          {t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].warnings)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TwoSideMultiSelect
          disabled={temporarilyDisplayEvacZones}
          evacZoneStatus={EvacZoneStatuses.warnings}
          availableZones={noStatusZones}
          selectedZones={evacZonesWarnings}
          downgradeable
          upgradeable
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="customWarnings"
              disabled={temporarilyDisplayEvacZones}
              checked={customWarnings}
              onChange={(event) => {
                setValue('customWarnings', event.target.checked);
                clearErrors('evacuationWarnings');
              }}
            />
          }
          label={t(
            'geoEventEvacuations.inputs.evacuationWarnings.enableCustom',
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="evacuationWarnings"
          control={control}
          render={({ field, fieldState }): JSX.Element => {
            const initialValue = customWarnings
              ? formState.defaultValues?.evacuationWarnings
              : getEvacZoneDefaultDescription(evacZonesWarnings);
            return (
              <>
                <RichTextEditor
                  id="field-control-evacuation-warnings"
                  data-testid="evacuation-warnings"
                  label={t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].warnings)}
                  initialValue={initialValue || ''}
                  onChange={field.onChange}
                  editable={
                    customWarnings &&
                    !temporarilyDisplayEvacZones &&
                    !formState.isSubmitting
                  }
                  error={!!fieldState.error}
                  editorClassName={classes.editor}
                  onBlur={field.onBlur}
                />
                {!!fieldState.error && (
                  <FormHelperText
                    error
                    sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                  >
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          {t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].advisories)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TwoSideMultiSelect
          disabled={temporarilyDisplayEvacZones}
          evacZoneStatus={EvacZoneStatuses.advisories}
          availableZones={noStatusZones}
          selectedZones={evacZonesAdvisories}
          upgradeable
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="customAdvisories"
              disabled={temporarilyDisplayEvacZones}
              checked={customAdvisories}
              onChange={(event) => {
                setValue('customAdvisories', event.target.checked);
                clearErrors('evacuationAdvisories');
              }}
            />
          }
          label={t(
            'geoEventEvacuations.inputs.evacuationAdvisory.enableCustom',
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="evacuationAdvisories"
          control={control}
          render={({ field, fieldState }): JSX.Element => {
            const initialValue = customAdvisories
              ? formState.defaultValues?.evacuationAdvisories
              : getEvacZoneDefaultDescription(evacZonesAdvisories);
            return (
              <>
                <RichTextEditor
                  id="field-control-evacuation-advisories"
                  data-testid="evacuation-advisories"
                  label={t(EVAC_ZONE_LABEL_TRANS_KEYS[zoneStyle].advisories)}
                  initialValue={initialValue || ''}
                  onChange={field.onChange}
                  editable={
                    customAdvisories &&
                    !temporarilyDisplayEvacZones &&
                    !formState.isSubmitting
                  }
                  error={!!fieldState.error}
                  editorClassName={classes.editor}
                  onBlur={field.onBlur}
                />
                {!!fieldState.error && (
                  <FormHelperText
                    error
                    sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                  >
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="evacuationNotes"
          control={control}
          render={({ field, fieldState }): JSX.Element => {
            return (
              <>
                <RichTextEditor
                  id="field-control-evacuation-notes"
                  data-testid="evacuation-notes"
                  label={t('evacZones.notes')}
                  initialValue={formState.defaultValues?.evacuationNotes || ''}
                  onChange={field.onChange}
                  editable={!formState.isSubmitting}
                  error={!!fieldState.error}
                  editorClassName={classes.notesEditor}
                  onBlur={field.onBlur}
                />
                {!!fieldState.error && (
                  <FormHelperText
                    error
                    sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                  >
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

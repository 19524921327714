import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { API } from 'api';
import PageLayout from 'components/PageLayout';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { HeaderBar } from 'components/HeaderBar';
import { Report } from 'shared/types';
import { useAuthState } from 'state';
import Unauthorized from 'components/Unauthorized';
import ModerateReportForm from './ModerateReportForm';

type ContentProps = {
  report: Report;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchReport = (reportId: number) => API.get(`reports/${reportId}`);

const Content = (props: ContentProps): JSX.Element => {
  const { report } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('moderateReport.title')}</title>
      </Helmet>
      <ModerateReportForm report={report} />
    </>
  );
};

const ModerateReport = (
  props: RouteComponentProps<{ reportId: string }>,
): JSX.Element => {
  const {
    match: { params },
  } = props;
  const { t } = useTranslation();
  const reportId = parseInt(params.reportId, 10);
  const {
    permissions: { canModerate },
  } = useAuthState();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['report', reportId],
    queryFn: () => fetchReport(reportId),
    enabled: !!reportId && canModerate,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  if (!canModerate) {
    return (
      <PageLayout data-cname="CreateEditIncidentPage">
        {{
          content: <Unauthorized />,
          headerBar: (
            <HeaderBar title={t('unauthorized.title')} action="back" />
          ),
        }}
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout data-cname="ModerateReport">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title="Add Report" action="back" />,
        }}
      </PageLayout>
    );
  }

  if (isError || !data?.data) {
    return (
      <PageLayout data-cname="ModerateReport">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title="Error" action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="ModerateReport">
      {{
        content: <Content report={data.data} />,
        headerBar: (
          <HeaderBar title={t('moderateReport.title')} action="back" />
        ),
      }}
    </PageLayout>
  );
};

export default ModerateReport;

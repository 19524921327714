import { useEffect, useMemo, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API, CacheAPI } from 'api';
import { GeoEvent, GeoEventEvacZoneStatus, Location } from 'shared/types';
import { useCacheState } from 'state';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/capacitor';
import { useGeoEventQuery } from 'features/geoEvents/hooks/useGeoEventQuery';

const fetchActiveEvacZones = async (
  cacheBusterTs: number | null,
): Promise<AxiosResponse<GeoEventEvacZoneStatus[]>> => {
  if (cacheBusterTs) {
    return CacheAPI.get<GeoEventEvacZoneStatus[]>(
      `evac_zones/statuses/?ts=${cacheBusterTs}`,
    );
  }
  return API.get<GeoEventEvacZoneStatus[]>('evac_zones/statuses');
};

// we want to ensure that we are using the most up to date for statuses that can be returned from either
//    useActiveEvacZonesQuery or geoEvent.evacZoneStatuses.
//    If new/updated data exists in geoEvent.evacZoneStatuses and not in evacZonesQuery, then let the caller know
//    then we should invalidate the querycache for the useActiveEvacZonesQuery to ensure that the main map
//    view also reflects this when moving out of the selected incident state.
export const mergeEvacZones = ({
  geoEventStatuses,
  activeEvacZones,
}: {
  geoEventStatuses: GeoEventEvacZoneStatus[];
  activeEvacZones: GeoEventEvacZoneStatus[];
}): [GeoEventEvacZoneStatus[], boolean] => {
  let shouldPerformCacheInvalidation = false;
  const allUids = Array.from(
    new Set([
      ...activeEvacZones.map((zone) => zone.evacZone.uidV2),
      ...geoEventStatuses.map((zone) => zone.evacZone.uidV2),
    ]),
  );
  const combined = allUids.map((uid) => {
    const selectedGeoEventEvacZone = geoEventStatuses.find(
      (zone) => zone.evacZone.uidV2 === uid,
    );
    const activeEvacZone = activeEvacZones.find(
      (zone) => zone.evacZone.uidV2 === uid,
    );
    // if this is a new zone not returned in the evac_status fetch, we should perform cache invalidation
    if (selectedGeoEventEvacZone && !activeEvacZone) {
      shouldPerformCacheInvalidation = true;
    }
    //  or if the statuses don't match - assume evac_statuses is now out of date as well
    if (
      selectedGeoEventEvacZone &&
      activeEvacZone &&
      selectedGeoEventEvacZone.status !== activeEvacZone.status
    ) {
      if (selectedGeoEventEvacZone.geoEventId !== activeEvacZone.geoEventId) {
        Sentry.captureMessage(
          'One zone has multiple statuses for different geo_events',
          { extra: { selectedGeoEventEvacZone, activeEvacZone } },
        );
      } else {
        shouldPerformCacheInvalidation = true;
      }
    }
    return selectedGeoEventEvacZone || activeEvacZone;
  });

  const filtered = combined.filter(
    (zoneOrUndefined) => !!zoneOrUndefined,
  ) as GeoEventEvacZoneStatus[];
  return [filtered, shouldPerformCacheInvalidation];
};

const useActiveEvacZonesQuery = (): GeoEventEvacZoneStatus[] => {
  const { cacheBusterTs } = useCacheState();
  const queryClient = useQueryClient();
  const invalidationRef = useRef(false);

  const { geoEvent: selectedGeoEvent } = useGeoEventQuery<
    GeoEvent | Location
  >();

  const { data, isFetching, isStale } = useQuery({
    queryKey: ['active-evacuation-zones', cacheBusterTs],
    queryFn: () => fetchActiveEvacZones(cacheBusterTs),
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 6, // 6 minutes - must be bigger than staleTime
  });

  const activeEvacZones: GeoEventEvacZoneStatus[] = useMemo(
    () => data?.data ?? [],
    [data?.data],
  );

  const [combinedZones, shouldPerformCacheInvalidation] = useMemo(() => {
    const geoEventStatuses =
      !!selectedGeoEvent && selectedGeoEvent.geoEventType === 'wildfire'
        ? selectedGeoEvent.evacZoneStatuses
        : [];
    return mergeEvacZones({ geoEventStatuses, activeEvacZones });
  }, [selectedGeoEvent, activeEvacZones]);

  if (
    shouldPerformCacheInvalidation &&
    !isFetching &&
    !isStale &&
    // Makes sure invalidation happens only once
    !invalidationRef.current
  ) {
    invalidationRef.current = true;
    queryClient.invalidateQueries({
      queryKey: ['active-evacuation-zones'],
    });
  }

  useEffect(() => {
    return () => {
      // Reset the invalidation ref when the component unmounts
      // and when the selectedGeoEventId changes
      invalidationRef.current = false;
    };
  }, [selectedGeoEvent?.id]);

  return combinedZones;
};

export default useActiveEvacZonesQuery;

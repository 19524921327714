import { List, ListSubheader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getResponsiveFontSize } from 'shared/utils';
import {
  MajorRiverGaugeIcon,
  ModerateRiverGaugeIcon,
  MinorRiverGaugeIcon,
  ActionRiverGaugeIcon,
  NormalRiverGaugeIcon,
  OfflineRiverGaugeIcon,
  DefaultRiverGaugeIcon,
} from '../Icons';
import LegendListItem from './LegendListItem';

export const RiverGaugesLegends = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky>
          <Typography
            variant="h6"
            component="h3"
            color="textPrimary"
            sx={(theme) => ({
              fontVariant: 'all-small-caps',
              fontSize: getResponsiveFontSize(theme.typography.pxToRem(20)),
            })}
          >
            {t('map.legends.riverGauges.title')}
          </Typography>
        </ListSubheader>
      }
    >
      <LegendListItem
        iconSrc={MajorRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.major.title')}
        secondary={t('map.legends.riverGauges.legends.major.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
      <LegendListItem
        iconSrc={ModerateRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.moderate.title')}
        secondary={t('map.legends.riverGauges.legends.moderate.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
      <LegendListItem
        iconSrc={MinorRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.minor.title')}
        secondary={t('map.legends.riverGauges.legends.minor.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
      <LegendListItem
        iconSrc={ActionRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.action.title')}
        secondary={t('map.legends.riverGauges.legends.action.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
      <LegendListItem
        iconSrc={NormalRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.normal.title')}
        secondary={t('map.legends.riverGauges.legends.normal.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
      <LegendListItem
        iconSrc={DefaultRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.default.title')}
        secondary={t('map.legends.riverGauges.legends.default.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
      <LegendListItem
        iconSrc={OfflineRiverGaugeIcon.data}
        primaryText={t('map.legends.riverGauges.legends.offline.title')}
        secondary={t('map.legends.riverGauges.legends.offline.subtitle')}
        iconBackground="transparent"
        iconSize={22}
      />
    </List>
  );
};

import { ReactNode } from 'react';
import { Box, Grid, SxProps } from '@mui/material';

type TabPanelProps = {
  children?: ReactNode;
  index: number | string;
  value: number | string;
  idPrefix: string;
  gridItem?: boolean;
  className?: string;
  sx?: SxProps;
};

export const a11yProps = (
  idPrefix: string,
  index: number,
): { id: string; 'aria-controls': string } => {
  return {
    id: `${idPrefix}-tab-${index}`,
    'aria-controls': `${idPrefix}-tabpanel-${index}`,
  };
};

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, idPrefix, gridItem, className, sx } = props;

  if (gridItem) {
    return (
      <Grid
        item
        role="tabpanel"
        hidden={value !== index}
        id={`${idPrefix}-tabpanel-${index}`}
        aria-labelledby={`${idPrefix}-tab-${index}`}
        className={className}
        sx={sx}
      >
        {value === index && <>{children}</>}
      </Grid>
    );
  }

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${idPrefix}-tabpanel-${index}`}
      aria-labelledby={`${idPrefix}-tab-${index}`}
      className={className}
      sx={sx}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRiverGauge } from 'features/riverGauges/hooks/useRiverGauge';
import PageLayout from 'components/PageLayout';
import { HeaderBar } from 'components/HeaderBar';
import { RiverGaugeAlertForm } from 'features/riverGauges/components/RiverGaugeAlertForm';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useRiverGaugeAlert } from 'features/riverGauges/hooks/useRiverGaugeAlert';
import { Container } from '@mui/material';
import { useRef } from 'react';
import { useIosKeyboardScroller } from 'hooks/useIosKeyboardScroller';

export const RiverGaugeEditAlert = (): JSX.Element => {
  const { gaugeId, alertId } = useParams<{
    gaugeId: string;
    alertId?: string;
  }>();
  const { t } = useTranslation();
  const location = useLocation();
  const {
    riverGauge,
    isLoading: isLoadingRiverGauge,
    isError: isErrorLoadingRiverGauge,
  } = useRiverGauge({ id: gaugeId });
  const {
    riverGaugeAlert,
    isLoading: isLoadingAlert,
    isError: isErrorLoadingAlert,
  } = useRiverGaugeAlert({
    riverGaugeAlertId: alertId ? parseInt(alertId, 10) : undefined,
  });
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  useIosKeyboardScroller(scrollContainerRef);

  const isLoading = !riverGauge || isLoadingRiverGauge || isLoadingAlert;
  const isError = isErrorLoadingRiverGauge || isErrorLoadingAlert;

  const returnRoute =
    location.state?.fromRoute || `/gauge/${gaugeId}?tab=notifications`;

  return (
    <PageLayout>
      {{
        headerBar: (
          <HeaderBar
            title={
              alertId
                ? t('riverGauges.alerts.editNotification')
                : t('riverGauges.alerts.addNotification')
            }
            action={returnRoute}
          />
        ),
        content: (
          <>
            {isLoading || isError ? (
              <LoadingAndErrors isLoading={isLoading} />
            ) : (
              <Container
                maxWidth="xs"
                sx={{
                  paddingTop: 2,
                  paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
                  width: '100%',
                  overflowY: 'auto',
                }}
                ref={scrollContainerRef}
              >
                <RiverGaugeAlertForm
                  riverGauge={riverGauge}
                  alert={riverGaugeAlert}
                  fromRoute={returnRoute}
                />
              </Container>
            )}
          </>
        ),
      }}
    </PageLayout>
  );
};

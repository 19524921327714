import { WildfireListItem } from 'shared/types';
import {
  useAllGeoEvents,
  UseGeoEventsProps,
} from 'features/geoEvents/hooks/useAllGeoEvents';

type WildfireList = {
  wildfireEvents: WildfireListItem[];
};

export const useWildfires = (props: UseGeoEventsProps = {}): WildfireList => {
  const { wildfireEvents } = useAllGeoEvents(props);

  return { wildfireEvents };
};

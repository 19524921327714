import { useTranslation } from 'react-i18next';
import { DialogContentText, Grid, Typography } from '@mui/material';
import { SHORT_MONTH_DAY_12HOUR_FORMAT, formatDate } from 'shared/dates';
import { ExternalGeoEvent } from 'shared/types';

type SourceContentProps = {
  externalGeoEvent: ExternalGeoEvent;
};

const getDateFormatted = (dateStr: string | undefined): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  return formatDate(date, SHORT_MONTH_DAY_12HOUR_FORMAT);
};

export const BaseContent = (props: SourceContentProps): JSX.Element => {
  // This is the base dialog content for externalSource types that we don't have a specific implementation for
  const { externalGeoEvent } = props;
  const { t } = useTranslation();

  const { dateStart, acreage, name } = externalGeoEvent.data;
  const dateStartFormatted = getDateFormatted(dateStart);
  const attributeNameSx = { fontVariant: 'all-small-caps' };

  return (
    <DialogContentText color="textPrimary" sx={{ marginLeft: -1 }}>
      <Grid
        container
        sx={{
          rowGap: 0.5,
          marginTop: 1,
        }}
      >
        <Grid item xs={6}>
          <Typography variant="body2" color="grayText" sx={attributeNameSx}>
            {t('externalGeoEvents.defaultSource.name')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{name}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="grayText" sx={attributeNameSx}>
            {t('externalGeoEvents.defaultSource.acreage')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{acreage}</Typography>
        </Grid>
        {Boolean(dateStart) && (
          <>
            <Grid item xs={6}>
              <Typography variant="body2" color="grayText" sx={attributeNameSx}>
                {t('externalGeoEvents.defaultSource.reported')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{dateStartFormatted}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </DialogContentText>
  );
};

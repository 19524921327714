import addVisible from 'shared/addVisible';
import { Layer, LayerProps, MapRef, useMapInstance } from 'shared/map-exports';
import {
  MapboxFeature,
  useMapLayerEvents,
} from 'components/Map/layers/useMapLayerEvents';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import VisibilityToggledSource from 'components/Map/layers/VisibilityToggledSource';
import { getWatchdutyTileUrl } from 'components/Map/layers/getWatchdutyTileUrl';
import { FONT_NAMES } from 'components/Map/styles/constants';
import { LabelTooltipRoundedBorderBackground } from 'components/Map/Icons';
import { useCenterMap } from 'components/IncidentsMap/CenterMap';
import { LatLng } from 'shared/types';
import { useRiverGauge } from 'features/riverGauges/hooks/useRiverGauge';
import { useSelectedRiverGaugeId } from 'features/riverGauges/hooks/useSelectedRiverGaugeId';
import {
  FLOOD_CATEGORIES,
  FLOOD_CATEGORY_ICON_NAMES,
  FloodCategory,
} from '../riverGauges.types';

type RiverGaugesLayerProps = {
  visible: boolean;
};

type RiverGaugesFeatureProperties = {
  name: string;
  weather_station_type: string;
  flood_category: FloodCategory;
  noaa_lid: string;
  usgs_id: string;
  level: number;
  flow?: number;
  is_offline: boolean;
  is_observation_recent: boolean;
};

const RIVER_GAUGES_SOURCE_ID = 'river-gauges';
const RIVER_GAUGES_ICONS_LAYER_ID = 'river-gauges-icons-layer';
const RIVER_GAUGES_LABELS_LAYER_ID = 'river-gauges-labels-layer';
const SOURCE_LAYER = 'weatherstations';
const MIN_ICON_ZOOM_LEVEL = 9;
const MIN_LABEL_ZOOM_LEVEL = 11;

const REFRESH_INTERVAL_SECONDS = 60 * 15; // 15 minutes

const iconStyle: LayerProps = {
  type: 'symbol',
  minzoom: MIN_ICON_ZOOM_LEVEL,
  'source-layer': SOURCE_LAYER,
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'is_offline'], true], // Check if is_offline is true
      FLOOD_CATEGORY_ICON_NAMES.offline, // Use the offline icon if true
      [
        'match',
        ['get', 'flood_category'],
        FLOOD_CATEGORIES.major,
        FLOOD_CATEGORY_ICON_NAMES.major,
        FLOOD_CATEGORIES.moderate,
        FLOOD_CATEGORY_ICON_NAMES.moderate,
        FLOOD_CATEGORIES.minor,
        FLOOD_CATEGORY_ICON_NAMES.minor,
        FLOOD_CATEGORIES.action,
        FLOOD_CATEGORY_ICON_NAMES.action,
        FLOOD_CATEGORIES.none,
        FLOOD_CATEGORY_ICON_NAMES.none,
        FLOOD_CATEGORY_ICON_NAMES.default,
      ],
    ],
    'icon-offset': [0, -55],
    'icon-allow-overlap': true,
    'icon-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      MIN_ICON_ZOOM_LEVEL,
      0.25,
      13,
      0.5,
    ],
  },
};

const labelStyle: LayerProps = {
  type: 'symbol',
  minzoom: MIN_LABEL_ZOOM_LEVEL,
  'source-layer': 'weatherstations',
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': [
      'format',
      [
        'number-format',
        ['get', 'level'],
        { 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
      ],
      ['literal', ' ft'],
      { 'font-scale': 0.75 },
    ],
    'text-size': 14,
    'text-offset': [0, 1.2],
    // Icon properties for the background box
    'icon-image': LabelTooltipRoundedBorderBackground.name,
    'icon-text-fit': 'both',
    'icon-allow-overlap': true,
    'icon-text-fit-padding': [4, 8, 4, 8], // [top, right, bottom, left]
    'icon-offset': [0, 2],
  },
  filter: [
    'all',
    ['has', 'level'],
    ['==', ['get', 'is_observation_recent'], true],
  ],
};

const getFeatureCoordinates = (input: {
  mapInstance?: MapRef;
  sourceId: string;
  featureId: number;
}): LatLng | null => {
  const { mapInstance, sourceId, featureId } = input;

  if (!mapInstance) {
    return null;
  }

  const features = mapInstance.querySourceFeatures(sourceId, {
    sourceLayer: SOURCE_LAYER,
  });

  const feature = features.find((f) => f.id === featureId);

  if (feature?.geometry.type === 'Point') {
    const [lng, lat] = feature.geometry.coordinates;
    return { lng, lat };
  }

  return null;
};

export const RiverGaugesLayer = (props: RiverGaugesLayerProps): JSX.Element => {
  const { visible } = props;
  const history = useHistory();
  const activeGaugeId = useSelectedRiverGaugeId();
  const map = useMapInstance();
  const { riverGauge } = useRiverGauge({ id: activeGaugeId });

  const handleClick = useCallback(
    (features: MapboxFeature<RiverGaugesFeatureProperties>[]) => {
      const feature = features[0];
      const featureId = feature.id;
      history.push(`/gauge/${featureId}`);
    },
    [history],
  );

  useMapLayerEvents<RiverGaugesFeatureProperties>({
    layerId: RIVER_GAUGES_LABELS_LAYER_ID,
    onClick: handleClick,
  });
  useMapLayerEvents<RiverGaugesFeatureProperties>({
    layerId: RIVER_GAUGES_ICONS_LAYER_ID,
    onClick: handleClick,
  });

  const activeGaugeLatLng = useMemo(() => {
    if (!activeGaugeId) return null;
    const latLngFromFeature = getFeatureCoordinates({
      mapInstance: map,
      sourceId: RIVER_GAUGES_SOURCE_ID,
      featureId: activeGaugeId,
    });
    if (!latLngFromFeature && riverGauge?.location) {
      return riverGauge.location;
    }
    return latLngFromFeature;
  }, [activeGaugeId, map, riverGauge?.location]);

  useCenterMap({
    latLng: activeGaugeLatLng,
    drawerIsOpen: true,
    defaultSnapPointPct: 0.47,
    zoomIn: true,
  });

  return (
    <VisibilityToggledSource
      id={RIVER_GAUGES_SOURCE_ID}
      visible={visible}
      refreshIntervalSeconds={REFRESH_INTERVAL_SECONDS}
      url={getWatchdutyTileUrl(SOURCE_LAYER)}
    >
      <Layer
        id={RIVER_GAUGES_LABELS_LAYER_ID}
        {...addVisible(labelStyle, visible)}
      />
      <Layer
        id={RIVER_GAUGES_ICONS_LAYER_ID}
        {...addVisible(iconStyle, visible)}
      />
    </VisibilityToggledSource>
  );
};

import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TurnOnNotificationsIcon from 'assets/turn_on_notifications.svg?react';
import OnboardingLayout from 'components/OnboardingLayout';
import { useCacheState, useMapState } from 'state';
import { initPushNotifications } from 'shared/initPushNotifications';
import {
  getCurrentPositionFromCapacitor,
  requestGeolocationPermissionsForNative,
} from 'hooks/usePosition';

const useStyles = makeStyles()((theme) => ({
  image: {
    width: '90vmin',
    height: 'auto',
    maxWidth: 345,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
}));

const PermissionsContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { setCacheState } = useCacheState();
  const { updateIncidentMapState } = useMapState();

  const setInitialMapLocationToUsersLocation = async (): Promise<void> => {
    const [error, result] = await getCurrentPositionFromCapacitor();
    if (error || !result) return;
    const { coords } = result;
    updateIncidentMapState({
      center: { lat: coords.latitude, lng: coords.longitude },
    });
  };

  const handleContinue = async (): Promise<void> => {
    const permissionsGranted = await requestGeolocationPermissionsForNative();
    if (permissionsGranted) {
      setInitialMapLocationToUsersLocation().then(() => {
        // Intentionally not awaiting so this runs in the background without the user waiting
      });
    }
    await initPushNotifications(history, setCacheState);
    history.push('onboarding_notifications');
  };

  return (
    <OnboardingLayout
      title={t('permissions.title')}
      footerText={
        <Typography>
          <Trans i18nKey="permissions.footer">
            Select <b>Allow While Using the App</b> then <b>Allow</b>.
          </Trans>
        </Typography>
      }
      buttonTitle={t('permissions.button')}
      onClick={handleContinue}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{t('permissions.description')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.iconContainer}>
            <TurnOnNotificationsIcon className={classes.image} />
          </Box>
        </Grid>
      </Grid>
    </OnboardingLayout>
  );
};

export default PermissionsContent;

import { useCallback, useMemo } from 'react';
import { differenceInWeeks } from 'date-fns/differenceInWeeks';
import { differenceInHours } from 'date-fns/differenceInHours';
import useOnboardingState from 'state/useOnboardingState';
import { getLastPushNotificationTimestamp } from 'state/localStorage';
import { InboxMessage } from 'shared/types';
import { useInboxMessages } from './useInboxMessages';

type UsePopupMessageHookReturn = {
  message: InboxMessage | undefined;
  setMessageAsRead: () => void;
};

const FIVE_MINUTES_MS = 1000 * 60 * 5; // 5 minutes

const MIN_APP_USAGE_HOURS = 24;

const usePopupMessage = (): UsePopupMessageHookReturn => {
  const { popupMessages, setMessagesAsRead } = useInboxMessages();
  const { showTutorialSteps, onboardedAt } = useOnboardingState();

  const message = useMemo(() => {
    if (!popupMessages.length) return undefined;

    const today = new Date();

    const lastPushNotificationTimestamp = getLastPushNotificationTimestamp();

    const pushNotifLast5Mins = lastPushNotificationTimestamp
      ? today.getTime() - lastPushNotificationTimestamp <= FIVE_MINUTES_MS
      : false;

    const appUsageInHours = onboardedAt
      ? Math.abs(
          differenceInHours(today, new Date(onboardedAt), {
            roundingMethod: 'round',
          }),
        )
      : 0;

    if (
      showTutorialSteps ||
      pushNotifLast5Mins ||
      appUsageInHours <= MIN_APP_USAGE_HOURS
    ) {
      return undefined;
    }

    const sortedDesc = popupMessages.sort((a, b) => {
      return (
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
    });

    const filteredMessages = sortedDesc.filter((msg) => {
      const withinLast2Weeks =
        Math.abs(
          differenceInWeeks(today, new Date(msg.dateCreated), {
            roundingMethod: 'round',
          }),
        ) <= 2;

      return withinLast2Weeks;
    });

    return filteredMessages[0];
  }, [onboardedAt, popupMessages, showTutorialSteps]);

  const setMessageAsRead = useCallback(() => {
    setMessagesAsRead(popupMessages.map((msg) => msg.id));
  }, [setMessagesAsRead, popupMessages]);

  return { message, setMessageAsRead };
};

export default usePopupMessage;

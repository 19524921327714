import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoTooltip } from 'components/InfoTooltip';

const GeoEventStopped = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        bgcolor: '#FFF4E5',
        borderRadius: '8px',
        mb: 2,
        py: 1,
        px: 2,
      }}
    >
      <Typography align="center" variant="body2" sx={{ fontWeight: '500' }}>
        {t('geoEvent.stoppedTooltip.title')}
      </Typography>
      <InfoTooltip title={t('geoEvent.stoppedTooltip.description')} />
    </Stack>
  );
};

export default GeoEventStopped;

import { useContext, useState } from 'react';
import {
  Box,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useTheme,
  SvgIconTypeMap,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { PrivateLandOwnershipEntity } from 'shared/types';
import { MapEntityDrawerContext } from 'components/Map/MapEntityDrawer';
import { MapEntityDrawerHeader } from 'components/Map/MapEntityDrawerHeader';
import { getResponsiveFontSize } from 'shared/utils';
import { TabPanel, a11yProps } from 'components/TabPanel';
import { formatDate } from 'shared/dates';
import { parse } from 'date-fns';
import { DrawerStickyHeader } from 'components/DrawerStickyHeader';

type ListItemProps = {
  label: string;
  primaryText: string;
  secondaryText?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
};

const TAB_PANEL_ID_PREFIX = 'land-ownership';

const ListItem = (props: ListItemProps): JSX.Element => {
  const { label, primaryText, secondaryText, Icon = LocationOnIcon } = props;
  const theme = useTheme();

  return (
    <MuiListItem divider>
      <ListItemText
        primary={
          <Typography
            color="secondary"
            sx={{
              fontWeight: 'bold',
              fontVariant: 'all-small-caps',
              fontSize: getResponsiveFontSize(theme.typography.pxToRem(18)),
            }}
          >
            {label}
          </Typography>
        }
        secondary={
          <>
            <Typography
              color="textPrimary"
              sx={{
                fontWeight: 'medium',
              }}
            >
              {primaryText || '-'}
            </Typography>
            {!!secondaryText && (
              <Typography color="textPrimary">{secondaryText}</Typography>
            )}
          </>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <ListItemIcon sx={{ minWidth: 'fit-content', color: 'text.primary' }}>
        <Icon />
      </ListItemIcon>
    </MuiListItem>
  );
};

export const PrivateLandOwnershipContent = (
  props: PrivateLandOwnershipEntity,
): JSX.Element => {
  const {
    owner,
    address,
    taxAddress,
    lat,
    lng,
    acres,
    buildings,
    zoningType,
    zoningSubtype,
    femaFloodZone,
    femaFloodZoneSubtype,
    femaFloodZoneDate,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const { scrollElementRef } = useContext(MapEntityDrawerContext);

  return (
    <Box>
      <DrawerStickyHeader
        scrollContainer={scrollElementRef?.current as HTMLElement}
      >
        <DrawerStickyHeader.ContentContainer>
          <MapEntityDrawerHeader>{owner}</MapEntityDrawerHeader>
          <Typography variant="body2" color="textSecondary">
            {address}
          </Typography>
        </DrawerStickyHeader.ContentContainer>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          aria-label="incident tabs"
          indicatorColor="primary"
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab
            label={t('privateLandOwnership.tabs.overview')}
            {...a11yProps(TAB_PANEL_ID_PREFIX, 0)}
          />
          <Tab
            label={t('privateLandOwnership.tabs.info')}
            {...a11yProps(TAB_PANEL_ID_PREFIX, 1)}
          />
        </Tabs>
      </DrawerStickyHeader>

      <TabPanel value={activeTab} index={0} idPrefix={TAB_PANEL_ID_PREFIX}>
        <List>
          {address !== taxAddress && (
            <ListItem
              label={t('privateLandOwnership.overview.taxAddress')}
              primaryText={owner}
              secondaryText={taxAddress}
            />
          )}
          <ListItem
            label={t('privateLandOwnership.overview.coordinates')}
            primaryText={`${lat}, ${lng}`}
          />
          <ListItem
            label={t('privateLandOwnership.overview.acres')}
            primaryText={acres.toFixed(1)}
          />
        </List>
      </TabPanel>

      <TabPanel value={activeTab} index={1} idPrefix={TAB_PANEL_ID_PREFIX}>
        <List>
          <ListItem
            label={t('privateLandOwnership.info.buildings')}
            primaryText={buildings.toString()}
            Icon={LanguageIcon}
          />
          <ListItem
            label={t('privateLandOwnership.info.zoningType')}
            primaryText={zoningType}
          />
          <ListItem
            label={t('privateLandOwnership.info.zoningSubtype')}
            primaryText={zoningSubtype}
          />
          <ListItem
            label={t('privateLandOwnership.info.femaFloodZone')}
            primaryText={femaFloodZone ?? '-'}
          />
          <ListItem
            label={t('privateLandOwnership.info.femaFloodZoneSubtype')}
            primaryText={femaFloodZoneSubtype ?? '-'}
          />
          <ListItem
            label={t('privateLandOwnership.info.femaFloodZoneDate')}
            primaryText={
              femaFloodZoneDate
                ? formatDate(
                    parse(
                      femaFloodZoneDate,
                      'yyyy-MM-dd',
                      new Date(femaFloodZoneDate),
                    ),
                    'MMMM d, yyyy',
                  )
                : '-'
            }
          />
        </List>
      </TabPanel>
    </Box>
  );
};

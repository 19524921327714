import { useCallback } from 'react';
import { FilterSpecification, Layer, LayerProps } from 'shared/map-exports';
import { usePoisState } from 'state/usePoisState';
import { FirePerimeterPoi } from 'shared/types';
import { getWatchdutyStaticTileUrl } from './getWatchdutyStaticTileUrl';
import VisibilityToggledSource from './VisibilityToggledSource';
import { MapLayerProps } from '../types';
import { ActiveFirePerimeterDialogContent } from '../ActiveFirePerimeterDialogContent';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../utils';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';
import { BEFORE_LAYER_ID } from '../styles/constants';
import { getWatchdutyTileUrl } from './getWatchdutyTileUrl';

type FirePerimeterSource = 'nifc' | 'firis' | 'cal_fire_intel';

type ActiveFirePerimetersProperties = {
  source_date_current: string;
  source: FirePerimeterSource;
};

type NifcActiveFirePerimetersProperties = {
  OBJECTID: number;
  IncidentName?: string;
  FeatureCategory?: string;
  MapMethod?: string;
  GISAcres?: number;
  CreateDate: number;
  DateCurrent: number;
  PolygonDateTime?: number;
  GACC?: string;
  UnidID?: string;
  LocalIncidentID?: string;
  IRWINID?: string;
  GeometryID?: string;
  CurrentDateAge?: number;
  IncidentTypeCategory?: string;
};

const FIRIS_LAYER_REFRESH_SECONDS = 60;
const FIRIS_LAYER_OUTER_STROKE_WIDTH = 8;

const NIFC_PERIMETERS_FILL_LAYER_ID = 'perimeters-fill';
const FIRIS_PERIMETERS_FILL_LAYER_ID = 'firis-fill';

const PERIMETER_SOURCE_DISPLAY_STRING_MAP = {
  nifc: 'NIFC',
  firis: 'FIRIS',
  cal_fire_intel: 'CAL FIRE Intel Flight',
};

const PERIMETER_SOURCE_DISPLAY_STRING_DEFAULT =
  PERIMETER_SOURCE_DISPLAY_STRING_MAP.firis;

const ActiveFirePerimetersLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  const handleClickCommon = useCallback(
    (poi: FirePerimeterPoi) => {
      setSelectedPoi({
        type: 'firePerimeter',
        id: poi.id,
        PoiDialogContent: () => (
          <ActiveFirePerimeterDialogContent activeFirePerimeter={poi} />
        ),
      });
    },
    [setSelectedPoi],
  );

  const handleClickNifc = useCallback(
    (
      geoJsonFeatures: MapboxFeature<NifcActiveFirePerimetersProperties>[],
    ): void => {
      for (const feature of geoJsonFeatures) {
        const { properties } = feature;
        const activeFirePerimeter: FirePerimeterPoi = {
          id: properties.OBJECTID,
          source: PERIMETER_SOURCE_DISPLAY_STRING_MAP.nifc,
          dateCurrent: properties.DateCurrent,
          irwinId: properties.IRWINID,
        };
        handleClickCommon(activeFirePerimeter);
      }
    },
    [handleClickCommon],
  );

  const handleClickFiris = useCallback(
    (
      geoJsonFeatures: MapboxFeature<ActiveFirePerimetersProperties>[],
    ): void => {
      for (const feature of geoJsonFeatures) {
        const { properties } = feature;
        const activeFirePerimeter: FirePerimeterPoi = {
          id: feature.id,
          source:
            PERIMETER_SOURCE_DISPLAY_STRING_MAP[properties.source] ??
            PERIMETER_SOURCE_DISPLAY_STRING_DEFAULT,
          dateCurrent: properties.source_date_current,
        };
        handleClickCommon(activeFirePerimeter);
      }
    },
    [handleClickCommon],
  );

  useMapLayerEvents<NifcActiveFirePerimetersProperties>({
    layerId: NIFC_PERIMETERS_FILL_LAYER_ID,
    onClick: handleClickNifc,
  });

  useMapLayerEvents<ActiveFirePerimetersProperties>({
    layerId: FIRIS_PERIMETERS_FILL_LAYER_ID,
    onClick: handleClickFiris,
  });

  const selectedId =
    (selectedPoi?.type === 'firePerimeter' && selectedPoi.id) || '';

  const selectedNifcFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': 'nifc' },
    selectedId,
  });

  const selectedNifcStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    otherLayerProps: { 'source-layer': 'nifc' },
    selectedId,
  });

  const selectedFirisFillStyle = getSelectedPatternStyle({
    idKey: ['id'],
    selectedId,
  });

  const selectedFirisStrokeStyle = getSelectedStrokeStyle({
    idKey: ['id'],
    selectedId,
  });

  return (
    <>
      <VisibilityToggledSource
        url={getWatchdutyStaticTileUrl('active_perimeters')}
        volatile
        visible={visible}
      >
        <Layer
          id={NIFC_PERIMETERS_FILL_LAYER_ID}
          beforeId={BEFORE_LAYER_ID}
          source-layer="nifc"
          {...activeFirePerimetersFillStyle}
        />
        <Layer
          id="perimeters-stroke"
          beforeId={BEFORE_LAYER_ID}
          source-layer="nifc"
          {...activeFirePerimetersStrokeStyle}
        />
        <Layer {...selectedNifcFillStyle} source-layer="nifc" />
        <Layer {...selectedNifcStrokeStyle} source-layer="nifc" />
      </VisibilityToggledSource>
      <VisibilityToggledSource
        url={getWatchdutyTileUrl('fireperimeters_active')}
        volatile
        visible={visible}
        refreshIntervalSeconds={FIRIS_LAYER_REFRESH_SECONDS}
      >
        <Layer
          id={FIRIS_PERIMETERS_FILL_LAYER_ID}
          beforeId={BEFORE_LAYER_ID}
          source-layer="fireperimeters_active"
          {...firisPerimetersFillStyle}
        />
        <Layer
          id="firis-stroke"
          beforeId={BEFORE_LAYER_ID}
          source-layer="fireperimeters_active"
          {...firisPerimetersStrokeStyle}
        />
        <Layer
          id="firis-border"
          beforeId={BEFORE_LAYER_ID}
          source-layer="fireperimeters_active"
          {...firisPerimetersBorderStyle}
        />
        <Layer
          id="firis-fill-selected"
          {...selectedFirisFillStyle}
          source-layer="fireperimeters_active"
        />
        <Layer
          id="firis-stroke-selected"
          {...selectedFirisStrokeStyle}
          source-layer="fireperimeters_active"
        />
      </VisibilityToggledSource>
    </>
  );
};

const activeFirePerimetersFillStyle: LayerProps = {
  type: 'fill',
  paint: {
    'fill-color': '#FF0000',
    'fill-opacity': 0.25,
  },
};

const activeFirePerimetersStrokeStyle: LayerProps = {
  type: 'line',
  paint: {
    'line-color': '#FF0000',
    'line-opacity': 0.75,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 18, 3.5],
  },
};

// For now, NIFC perimeters are still being sourced from the static tiles
// pipeline.
const firisLayerFilter: FilterSpecification = ['!=', 'source', 'nifc'];

const firisPerimetersFillStyle: LayerProps = {
  type: 'fill',
  filter: firisLayerFilter,
  paint: {
    'fill-color': '#FD2D2C',
    'fill-opacity': 0.5,
  },
};

const firisPerimetersStrokeStyle: LayerProps = {
  type: 'line',
  filter: firisLayerFilter,
  paint: {
    'line-color': '#FD2D2C',
    'line-opacity': 1,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 18, 2.5],
    'line-dasharray': [2, 1],
  },
};

const firisPerimetersBorderStyle: LayerProps = {
  type: 'line',
  filter: firisLayerFilter,
  layout: {
    'line-cap': 'square',
  },
  paint: {
    'line-color': '#FD2D2C',
    'line-opacity': 0.32,
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12,
      FIRIS_LAYER_OUTER_STROKE_WIDTH / 12,
      18,
      FIRIS_LAYER_OUTER_STROKE_WIDTH,
    ],
    'line-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12,
      -FIRIS_LAYER_OUTER_STROKE_WIDTH / 24,
      18,
      -FIRIS_LAYER_OUTER_STROKE_WIDTH / 2,
    ],
  },
};

export default ActiveFirePerimetersLayer;

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import useUpgradeMessageState from 'state/useUpgradeMessageState';
import Loader from 'components/Loader';
import { HeaderBar, getCameraActions } from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import {
  SUCCESS_MSG,
  useAuthState,
  useCacheState,
  useMapState,
  useSnackbarState,
} from 'state';
import { getDeviceInfo } from 'state/localStorageTyped';
import { useGeoEventQuery } from 'features/geoEvents/hooks/useGeoEventQuery';
import { GeoEvent, Location } from 'shared/types';
import { useSelectedCameraId } from 'hooks/useSelectedCameraId';
import { useAlertCameraPlayerState } from 'hooks/useAlertCameraPlayer';
import SupportUsIcon from 'assets/header-heart.svg?react';
import HomeContent from './HomeContent';
import { useHandleExternalPaymentSuccess } from './useHandleExternalPaymentSuccess';
import { UpgradeCTA } from './UpgradeCTA';
import { getAdminMenuItems } from './Home.utils';

const isUpgradeAvailable = async (): Promise<boolean> => {
  if (import.meta.env.VITE_SHOW_UPGRADE_MESSAGE === 'true') {
    return true;
  }

  const deviceInfo = getDeviceInfo();
  if (!deviceInfo || deviceInfo.isWeb) {
    return false;
  }

  const updateInfo = await AppUpdate.getAppUpdateInfo();
  return (
    updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
  );
};

export const Home = (): JSX.Element => {
  const { cacheBusterTs } = useCacheState();
  const { setSnackbar } = useSnackbarState();
  const {
    permissions: {
      isInternalUser,
      canReport,
      isRxOnlyReporter,
      canCreateLocation,
    },
  } = useAuthState();
  const [shouldShowUpgradeMessage, setShownUpgradeMessage] =
    useUpgradeMessageState();
  const [hasUpgrade, setHasUpgrade] = useState(false);
  const { isPending } = useHandleExternalPaymentSuccess();
  const { t } = useTranslation();
  const { geoEvent } = useGeoEventQuery<GeoEvent | Location>();
  const { incidentMapState, geoEventMapState } = useMapState();
  const cameraId = useSelectedCameraId();
  const { timelapseFrame } = useAlertCameraPlayerState();

  useEffect(() => {
    const getUpgradeInternal = async (): Promise<void> => {
      const result = await isUpgradeAvailable();
      setHasUpgrade(result);
    };
    getUpgradeInternal();
  }, []);

  // we haven't shown them the upgrade alert in seven days, user hasn't received a push notif in past 5 minutes
  // and app store upgrade exists
  const showUpgradeMessage =
    shouldShowUpgradeMessage && !cacheBusterTs && hasUpgrade;

  const upgradeCTAComponent = UpgradeCTA();

  useEffect(() => {
    if (!showUpgradeMessage) {
      return;
    }
    setSnackbar(upgradeCTAComponent, SUCCESS_MSG, null);
    setShownUpgradeMessage();
  }, [
    upgradeCTAComponent,
    showUpgradeMessage,
    setShownUpgradeMessage,
    setSnackbar,
  ]);

  const hasEditPermissions = useMemo(() => {
    if (!geoEvent) return false;
    if (geoEvent.geoEventType !== 'wildfire') {
      return canReport && !isRxOnlyReporter;
    }
    if (!isRxOnlyReporter) return true;
    return geoEvent.data.isPrescribed;
  }, [geoEvent, isRxOnlyReporter, canReport]);

  const geoEventId =
    geoEvent?.data.geoEventType === 'wildfire' ? geoEvent.id : undefined;
  const locationId =
    geoEvent?.data.geoEventType === 'location' ? geoEvent.id : undefined;

  const automatedGeoEvent = !geoEvent?.reporterManaged;

  const showAdminActions = canReport && (!geoEventId || hasEditPermissions);

  const adminMenuItems = getAdminMenuItems(t, {
    automatedGeoEvent,
    geoEventId,
    locationId,
    geoEventMapState,
    canCreateLocation,
    incidentMapState,
    timelapseFrame,
    cameraId,
  });

  const cameraAction = showAdminActions
    ? []
    : getCameraActions(
        t,
        geoEventId ? `/add_report/${geoEventId}` : '/add_report',
      );

  const iconActions = isInternalUser
    ? undefined
    : [
        {
          label: t('header.actions.supportUs'),
          to: '/support_us',
          Icon: <SupportUsIcon />,
        },
        ...cameraAction,
      ];

  return (
    <PageLayout data-cname="HomePage" data-testid="homePage">
      {{
        content: (
          <>
            <HomeContent />
            {isPending && <Loader />}
          </>
        ),
        headerBar: (
          <HeaderBar
            iconActions={iconActions}
            menuActions={showAdminActions ? adminMenuItems : undefined}
          />
        ),
      }}
    </PageLayout>
  );
};

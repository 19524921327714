import useInitUserOnboard from 'hooks/useInitUserOnboard';
import useFirebaseAnalytics from 'shared/analytics';
import { useEffect } from 'react';
import { SplashScreen } from '@capacitor/splash-screen';
import { useInboxMessages } from 'hooks/useInboxMessages';
import useAppInfo from 'hooks/useAppInfo';
import useOnboardingState from 'state/useOnboardingState';
import { useAuthState } from 'state';
import OnboardingWalkMe from 'components/OnboardingWalkMe';
import { useLocation } from 'react-router-dom';
import useSelectedLocationState from 'state/useSelectedLocationState';
import { useIapInitializer } from 'hooks/useIapInitializer';
import { AlertCameraPlayerProvider } from 'hooks/useAlertCameraPlayer';
import { useSupporterStatus } from 'hooks/useSupporterStatus';
import Routes from './Routes';

const Navigation = (): JSX.Element => {
  const { onboardCompleted } = useOnboardingState();
  useFirebaseAnalytics();
  useIapInitializer();
  useInitUserOnboard();
  useAppInfo(true);
  useInboxMessages();
  const { updateAuth } = useAuthState();
  const location = useLocation();
  const { setSelectedLocation } = useSelectedLocationState();

  useEffect(() => {
    // If we've completed the onboarding once - we can immediately hide the splash screen
    // otherwise, let the the logic in useInitUserOnboard handle it
    const hideSplashScreen = async (): Promise<void> => {
      await SplashScreen.hide();
    };
    if (onboardCompleted) {
      hideSplashScreen();
    }
  }, [onboardCompleted]);

  const supporterStatus = useSupporterStatus();

  useEffect(() => {
    if (supporterStatus) {
      updateAuth({
        hasActiveMembership: supporterStatus.hasActiveMembership,
        user: { supporterStatus },
      });
    }
  }, [supporterStatus, updateAuth]);

  useEffect(() => {
    setSelectedLocation(null);
  }, [location, setSelectedLocation]);

  return (
    <>
      <AlertCameraPlayerProvider>
        <Routes />
      </AlertCameraPlayerProvider>
      <OnboardingWalkMe />
    </>
  );
};

export default Navigation;

import { RouteComponentProps } from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import { HeaderBar } from 'components/HeaderBar';
import { isPhone } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import SupportUsIcon from 'assets/header-heart.svg?react';
import { CameraFullscreenDetails } from './CameraFullscreenDetails';
import { MobileFullscreenCamera } from './MobileFullscreenCamera';

export const FullscreenCamera = (
  props: RouteComponentProps<{ cameraId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { cameraId },
    },
  } = props;
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="FullscreenCameraPage">
      {{
        content: isPhone() ? (
          <MobileFullscreenCamera />
        ) : (
          <CameraFullscreenDetails />
        ),
        headerBar: isPhone() ? undefined : (
          <HeaderBar
            action={`/camera/${cameraId}`}
            dark
            iconActions={[
              {
                label: t('header.actions.supportUs'),
                to: '/support_us',
                Icon: <SupportUsIcon />,
              },
            ]}
          />
        ),
      }}
    </PageLayout>
  );
};

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { API } from 'api';
import { RiverGaugeAlert } from '../../riverGauges.types';
import { getPushTokenFromLocalStorage } from '../../../../state/localStorage';

type UseRiverGaugeAlertsProps = {
  riverGaugeId?: number | string;
  enabled?: boolean;
};

type UseRiverGaugeAlerts = Omit<
  UseQueryResult<RiverGaugeAlert[], Error>,
  'data'
> & {
  riverGaugeAlerts?: RiverGaugeAlert[];
};

type ServerRiverGaugeAlerts = RiverGaugeAlert[];

const fetchRiverGaugeAlerts = async (
  pushToken: string,
  riverGaugeId?: number | string,
): Promise<RiverGaugeAlert[]> => {
  const response = await API.get<ServerRiverGaugeAlerts>(`weather/alerts/`, {
    params: { pushToken },
  });

  // filter by riverGaugeId if provided
  if (riverGaugeId) {
    // currently not modifying the api to filter since we'll be doing device syncing of all alerts
    return response.data.filter(
      (alert) => alert.station.id.toString() === riverGaugeId.toString(),
    );
  }

  return response.data;
};

export const useRiverGaugeAlerts = (
  props: UseRiverGaugeAlertsProps = {},
): UseRiverGaugeAlerts => {
  const { riverGaugeId, enabled = true } = props;
  const pushToken = getPushTokenFromLocalStorage();

  const { data, ...results } = useQuery({
    queryKey: ['riverGaugeAlerts', riverGaugeId],
    queryFn: () => fetchRiverGaugeAlerts(pushToken!, riverGaugeId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: Boolean(enabled && pushToken),
  });

  return {
    ...results,
    riverGaugeAlerts: data,
  };
};

import { useEffect, useCallback, useRef } from 'react';
import { SplashScreen } from '@capacitor/splash-screen';
import { useNotifications } from 'hooks/useNotifications';
import { isPushNotificationsEnabled } from 'shared/initPushNotifications';
import useOnboardingState from 'state/useOnboardingState';
import { isMobile } from 'shared/utils';

const useInitUserOnboard = (): void => {
  const {
    subscribedNotificationSettings: { isLoading, data },
  } = useNotifications({ retryQuery: false });
  const {
    onboardCompleted,
    setFirstAppOpen,
    setOnboardUser,
    setNotificationsEnabled,
  } = useOnboardingState();
  const hasOnboardingStateBeenChecked = useRef(false);

  const updateAppState = useCallback(
    async (userRequiresOnboarding: boolean) => {
      hasOnboardingStateBeenChecked.current = true;
      setFirstAppOpen();
      setOnboardUser(userRequiresOnboarding);
      // Hide the splash when we know if to show the onboarding flow or not
      await SplashScreen.hide();
    },
    [setOnboardUser, setFirstAppOpen],
  );

  const shouldOnboardUser = useCallback(async () => {
    if (hasOnboardingStateBeenChecked.current) return;

    const notificationsEnabled = await isPushNotificationsEnabled();
    if (notificationsEnabled) {
      // This is needed for a later step on the onboarding flow
      setNotificationsEnabled(notificationsEnabled);
    }

    const isAnyCountySelected = !!data?.data?.length;

    // When a user opens the app for the first time, notifications won't be enabled
    // so "firstAppOpen === true" condition will also be met here
    if ((!notificationsEnabled || !isAnyCountySelected) && !onboardCompleted) {
      await updateAppState(true);
      return;
    }

    await updateAppState(false);
  }, [updateAppState, data, onboardCompleted, setNotificationsEnabled]);

  useEffect(() => {
    // we never need to run this if the onboarding has completed once
    if (!isMobile() || isLoading || onboardCompleted) return;

    shouldOnboardUser();
  }, [isLoading, shouldOnboardUser, onboardCompleted]);
};

export default useInitUserOnboard;

import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { AircraftDetails } from 'shared/types';

type AircraftDialogContentProps = {
  aircraft: AircraftDetails;
};

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 0, 2),
    lineHeight: '1 !important',
  },
  content: {
    padding: theme.spacing(0, 2, 2),
  },
  description: {
    margin: theme.spacing(0, 0, 1),
  },
  infoTitle: {
    fontVariant: 'all-small-caps',
    minWidth: 70,
  },
}));

export const AircraftDialogContent = (
  props: AircraftDialogContentProps,
): JSX.Element => {
  const { classes } = useStyles();
  const {
    aircraft: { name, tailNum, model, altitude, speed, type, squawk },
  } = props;
  const { t } = useTranslation();

  const nameWithDefault = name || t('map.poi.aircraft.noCallsign');

  let altitudeFormatted = '-';
  if (altitude === 'ground') {
    altitudeFormatted = t('map.poi.aircraft.onGround');
  } else if (typeof altitude === 'number') {
    const formattedAlt = altitude.toLocaleString();
    altitudeFormatted = t('map.poi.aircraft.altitude.value', {
      altitude: formattedAlt,
    });
  }

  const details = [
    [t('map.poi.aircraft.altitude.title'), altitudeFormatted],
    [
      t('map.poi.aircraft.speed.title'),
      speed ? t('map.poi.aircraft.speed.value', { speed }) : '-',
    ],
    [t('map.poi.aircraft.squawk.title'), squawk || '-'],
    [
      t('map.poi.aircraft.type.title'),
      t(`map.aircraft.type.${type.split('_')[0]}`),
    ],
  ];

  return (
    <>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2">
          <b>{nameWithDefault}</b>
        </Typography>
        &nbsp;
        <Typography color="secondary" component="span" variant="subtitle1">
          {tailNum}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <DialogContentText
          id="alert-dialog-description"
          color="textPrimary"
          className={classes.description}
          variant="body2"
        >
          {model}
        </DialogContentText>

        <Grid container>
          {details.map(([title, value]) => (
            <Grid key={title} item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                  color="secondary"
                  className={classes.infoTitle}
                  variant="body2"
                >
                  {title}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </>
  );
};

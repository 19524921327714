import { ReactNode, SVGProps } from 'react';
import { Box, Stack, StackOwnProps, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type State = {
  criteria?: () => boolean;
  renderImage: (
    imageProps: SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    },
  ) => ReactNode;
  title?: string;
  description: ReactNode;
  Button?: ReactNode;
  fixedButton?: boolean;
  scrollContainer?: HTMLElement;
  containerProps?: Omit<StackOwnProps, 'children'>;
};

type DownStateProps = {
  states: State[];
};

const useStyles = makeStyles()((theme) => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: 266,
    height: 'auto',
  },
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
}));

export const DownState = (props: DownStateProps): JSX.Element | null => {
  const { states } = props;
  const { classes } = useStyles();

  const matchedState = states.find(({ criteria = () => true }) => criteria());

  if (!matchedState) {
    return null;
  }

  const {
    renderImage,
    title,
    description,
    Button,
    fixedButton = false,
    scrollContainer,
    containerProps = {},
  } = matchedState;

  const isScrollable = scrollContainer
    ? scrollContainer.scrollHeight >
      scrollContainer.getBoundingClientRect().height
    : false;

  const { spacing = 2, sx = {}, ...containerPropsRest } = containerProps;

  return (
    <Stack
      {...containerPropsRest}
      spacing={spacing}
      sx={{ pb: fixedButton ? 10 : 0, ...sx }}
    >
      <Stack spacing={2}>
        <Box className={classes.imageContainer}>
          {renderImage({ className: classes.image, title })}
        </Box>

        {!!title && (
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
        )}

        {typeof description === 'string' ? (
          <Typography>{description}</Typography>
        ) : (
          description
        )}
      </Stack>

      {fixedButton ? (
        <Box
          className={classes.footer}
          sx={(theme) => ({
            boxShadow: isScrollable ? theme.shadows[10] : 'none',
          })}
        >
          {Button}
        </Box>
      ) : (
        Button
      )}
    </Stack>
  );
};

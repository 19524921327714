import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { CHART_LEFT_RIGHT_PADDING } from './useWaterLevelsChart.constants';

type WaterLevelsChartWrapperProps = {
  children: ReactNode;
};

/**
 * useWaterLevelsChart() adds padding to both sides of the chart to support
 * the y-axis label, etc. ECharts works better when the padding on both sides
 * is the same (helps keep the "center" of the chart centered). This wrapper
 * counteracts that by cutting off the right-side padding, which we don't need
 * for any label.
 */
export const WaterLevelsChartWrapper = (
  props: WaterLevelsChartWrapperProps,
): JSX.Element => {
  const { children } = props;

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box sx={{ marginRight: `-${CHART_LEFT_RIGHT_PADDING}px !important` }}>
        {children}
      </Box>
    </Box>
  );
};

import { Capacitor } from '@capacitor/core';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { AllMapLayers } from 'components/Map/constants';
import { GeoEvent, Report } from 'shared/types';
import {
  getCurrentLocationAsShareUrl,
  getResponsiveFontSize,
  isMobile,
} from 'shared/utils';
import { getDateFormatted } from 'shared/dates';
import RichTextContent from 'components/RichTextContent';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGeoEventQuery } from 'features/geoEvents/hooks/useGeoEventQuery';
import Map from '../Map';
import PlatformShareIcon from '../PlatformShareIcon';
import ReportMetadata from '../ReportMetadata';
import TextLogo from '../TextLogo';
import { useShareOrCopy } from '../../hooks/useShareOrCopy';

type ReportDetailProps = {
  report: Report;
};

const useStyles = makeStyles()(
  ({ spacing, palette, shape, breakpoints, typography }) => ({
    root: {
      backgroundColor: palette.common.black,
      color: palette.common.white,
      minHeight: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100vw',
      paddingBottom: spacing(2),
    },
    title: {
      flexGrow: 1,
      paddingLeft: '20px',
      fontSize: getResponsiveFontSize(typography.pxToRem(20)),
    },
    header: {
      position: 'sticky',
      top: 0,
      background: 'rgba(0, 0, 0, 0.6)',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingTop: `env(safe-area-inset-top)`,
      paddingBottom: spacing(1),
      zIndex: 1,
      justifyContent: 'space-between',
      display: 'flex',
    },
    textLogo: {
      height: 14,
      width: 138,
      marginTop: 10,
      fill: '#FFFFFF',
    },
    container: {
      flexWrap: 'initial',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    content: {
      [breakpoints.down('md')]: {
        marginRight: spacing(3),
        marginLeft: spacing(3),
      },
    },
    message: {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
      paddingRight: spacing(0.5),
    },
    link: {
      color: palette.primary.main,
    },
    map: {
      borderRadius: shape.borderRadius,
      height: '75vw',
      maxHeight: 300,
      overflow: 'hidden',
    },
    notificationType: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    pinchZoomWrapper: {
      width: 'auto',
      height: 'auto',
    },
    attribution: {
      '& p': {
        fontSize: typography.subtitle1.fontSize,
        marginTop: 8,
        marginBottom: 0,
      },
    },
    media: {
      /**
       * Add margin on mobile so a user can scroll the page by swiping on the sides of the screen.
       * For more context see: https://github.com/BetterTyped/react-zoom-pan-pinch/issues/434
       */
      marginLeft: isMobile() ? spacing(2) : 0,
      marginRight: isMobile() ? spacing(2) : 0,
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const ReportDetail = (props: ReportDetailProps): JSX.Element => {
  const { report } = props;
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { geoEvent } = useGeoEventQuery<GeoEvent>();
  const { t } = useTranslation();

  const media = report?.media?.[0] || {};
  const imgSrc = media.url;

  const { lat, lng } = media;

  const getIncidentPath = (): string => {
    const isLegacyIncident = location.pathname.includes('incident');
    return isLegacyIncident
      ? `/incident/${report.incidentId}`
      : `/i/${report.geoEventId}`;
  };

  const handleGoBack = (): void => {
    history.push(getIncidentPath());
  };

  const { canShareOrCopy, shareOrCopyUrl } = useShareOrCopy();

  const handleShare = async (): Promise<void> => {
    shareOrCopyUrl({
      title: report.message,
      text: report.message,
      url: getCurrentLocationAsShareUrl(new Date(report.dateModified)),
      androidDialogTitle: t('geoEvent.reports.shareReport'),
    });
  };

  // Set the status bar background to black to match this page.
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#000000' });
      StatusBar.setStyle({ style: Style.Dark });
    }
  }, []);

  // Our image viewer
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <IconButton
          edge="start"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleGoBack}
          color="inherit"
          size="large"
        >
          <ArrowBackIcon />
          <Typography variant="h6" className={classes.title}>
            <TextLogo className={classes.textLogo} />
          </Typography>
        </IconButton>
        {canShareOrCopy && (
          <IconButton color="inherit" onClick={handleShare} size="large">
            <PlatformShareIcon />
          </IconButton>
        )}
      </Box>
      <Grid container gap={1} className={classes.container}>
        <Grid item md={6} lg={8} className={classes.media}>
          <Box className={classes.imageContainer}>
            <TransformWrapper>
              <TransformComponent
                contentClass={classes.pinchZoomWrapper}
                wrapperClass={classes.pinchZoomWrapper}
              >
                <img
                  src={imgSrc}
                  alt="Media"
                  width="100%"
                  data-testid="report-image"
                  style={{
                    // '~66px' is the height of the header
                    maxHeight: 'calc(100vh - 66px)',
                    objectFit: 'contain',
                  }}
                />
              </TransformComponent>
            </TransformWrapper>
          </Box>
          {media.attribution && (
            <RichTextContent
              content={media.attribution}
              className={classes.attribution}
            />
          )}
        </Grid>
        <Grid item md={6} lg={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.content}>
              <Typography color="secondary" variant="body2">
                {getDateFormatted(report.dateCreated, {
                  includeYear: !geoEvent?.isActive,
                })}
              </Typography>
            </Grid>
            {report.message && (
              <Grid item className={classes.content} sx={{ my: -1 }}>
                <RichTextContent
                  content={report.message}
                  className={classes.message}
                />
              </Grid>
            )}
            <Grid item className={classes.content}>
              <Typography
                gutterBottom
                variant="subtitle2"
                color="secondary"
                className={classes.notificationType}
              >
                <ReportMetadata report={report} />
              </Typography>
            </Grid>
            {!!lat && !!lng && (
              <Grid item className={classes.content}>
                <Box className={classes.map}>
                  <Map
                    center={{ lat, lng }}
                    zoom={12}
                    noControls
                    disableMapLayers={AllMapLayers}
                  >
                    <Map.Markers
                      locations={report}
                      type="media"
                      isFadable={false}
                    />
                    <Map.CenterView locations={[report]} />
                  </Map>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportDetail;

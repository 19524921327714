import { useCallback, useEffect, useRef } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import { usePoisState } from 'state/usePoisState';
import {
  ROUTER_ENTITY_TO_ROUTE_PATH_MAP,
  RouterEntityDrawer,
} from '../../../constants';

// Any router entity that is selected during this time window is considered part of the same event.
const CLICK_SAME_EVENT_WINDOW_MS = 300;

// Higher priority router entities will get precedence when deciding which entity to select.
const CLICK_PRIORITY: Record<RouterEntityDrawer, number> = {
  wildfire: 4,
  location: 3,
  camera: 2,
  gauge: 1,
};

/**
 * This hook performs the following tasks:
 *
 * - Reconciles the `mapDrawerEntity` state with the currently selected
 *   router-entity drawer. It ensures that the `mapDrawerEntity` state
 *   is cleared when the user selects any of the following:
 *   - Wildfire drawer
 *   - Location drawer
 *   - Alert camera drawer
 *   - River gauge drawer
 *
 * - Ensures that the higher-priority entity is selected when multiple
 *   overlapping router-entity markers are present on the map, and the
 *   user selects them within the same click event's time window.
 *
 * - Clears all Points of Interest (POIs) when navigating to a router-entity drawer,
 *   which is essential for effectively managing overlapping layers on the map.
 */
export const useMapDrawerEntitySelectorReconciler = (): void => {
  const history = useHistory();
  const { setMapDrawerEntity } = useMapDrawerEntityState();
  const { clearSelectedPois } = usePoisState();
  const prevRouterEntityRef = useRef<{
    name: RouterEntityDrawer;
    ts: number;
  } | null>(null);

  const getRouterEntity = useCallback(
    (pathname: string): RouterEntityDrawer | null => {
      for (const [entity, path] of Object.entries(
        ROUTER_ENTITY_TO_ROUTE_PATH_MAP,
      )) {
        const isMatch = matchPath(pathname, { path, exact: true });
        if (isMatch) return entity as RouterEntityDrawer;
      }

      return null;
    },
    [],
  );

  useEffect(() => {
    const unblock = history.block((newLocation) => {
      // @ts-expect-error pathname does exist on newLocation
      const newLocationPathname = newLocation.pathname;

      const routerEntity = getRouterEntity(newLocationPathname);
      if (routerEntity) {
        // Block any underlying POI.
        // A short `setTimeout()` is required to allow the POI array to populate (see `usePoisState()`).
        setTimeout(() => clearSelectedPois(), 10);
        setMapDrawerEntity(null);

        /*
         * Block navigation when a user clicks a map point where multiple
         * entities overlap, and a previously selected entity from the same click
         * event has a higher priority.
         *
         * Ideally, entity layers would render in order of priority, with the highest
         * priority layer on top. This would ensure that the 'wildfire' entity's click
         * event always triggers first. However, this rendering order can be unreliable.
         */
        const prevRouterEntity = prevRouterEntityRef.current;
        if (prevRouterEntity) {
          const deltaTs = Date.now() - prevRouterEntity.ts;
          const routerEntityClickPriority = CLICK_PRIORITY[routerEntity];
          const prevRouterEntityClickPriority =
            CLICK_PRIORITY[prevRouterEntity.name];

          if (
            deltaTs <= CLICK_SAME_EVENT_WINDOW_MS &&
            prevRouterEntityClickPriority > routerEntityClickPriority
          ) {
            return false;
          }
        }

        prevRouterEntityRef.current = { name: routerEntity, ts: Date.now() };
      }

      return true;
    });

    return () => {
      unblock();
    };
  }, [clearSelectedPois, history, getRouterEntity, setMapDrawerEntity]);
};

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import { HeaderBar } from 'components/HeaderBar';
import PlaceForm from 'components/PlaceForm';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { PlaceLocation } from 'shared/types';
import useMapPlaces from 'hooks/useMapPlaces';

type ContentProps = {
  place: PlaceLocation;
};

const Content = (props: ContentProps): JSX.Element => {
  const { place } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('editPlace.title')}</title>
      </Helmet>
      <PlaceForm initialPlace={place} />
    </>
  );
};

const EditPlace = (
  props: RouteComponentProps<{ placeId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { placeId },
    },
  } = props;
  const { t } = useTranslation();
  const { places } = useMapPlaces();

  const place = places.find((p) => p.id === Number(placeId));

  if (!place) {
    return (
      <PageLayout data-cname="EditPlacePage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('geoEvent.loading')} action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="EditPlacePage">
      {{
        content: <Content place={place} />,
        headerBar: <HeaderBar title={t('editPlace.title')} action="/" />,
      }}
    </PageLayout>
  );
};

export default EditPlace;

import { Container, Grid, Button, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import VectorLogo from '../../../components/VectorLogo';
import { HeaderBar } from '../../../components/HeaderBar';
import PageLayout from '../../../components/PageLayout';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
    width: '100%',
  },
  description: {
    lineHeight: '20px',
  },
}));

export const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('resetPasswordConfirmation.title')}</title>
      </Helmet>
      <div className={classes.root}>
        <Container maxWidth="sm">
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Box sx={{ textAlign: 'center' }}>
                <VectorLogo sx={{ paddingTop: 3 }} />
              </Box>

              <Typography align="center" component="h2" variant="h3">
                <b>{t('resetPasswordConfirmation.subtitle')}</b>
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                className={classes.description}
                sx={{
                  marginBottom: '16px',
                }}
              >
                <Trans i18nKey="resetPasswordConfirmation.description">
                  We emailed you a link to reset your password.
                  <br />
                  Please click the link to proceed.
                </Trans>
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                <b>{t('resetPasswordConfirmation.message')}</b>
              </Typography>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                size="large"
                to="/login"
                component={Link}
                sx={{ marginTop: 1 }}
              >
                {t('resetPasswordConfirmation.button')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const ResetPasswordConfirmation = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="ResetPasswordPage">
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action="/" title={t('resetPasswordConfirmation.title')} />
        ),
      }}
    </PageLayout>
  );
};

export default ResetPasswordConfirmation;

import { ReactNode, useMemo } from 'react';
import { Source } from 'shared/map-exports';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getWatchdutyStaticTileUrl } from './getWatchdutyStaticTileUrl';

type VisibilityToggledSourceProps = {
  children: ReactNode;
  url: string;
  visible: boolean;
  promoteId?: string;
  volatile?: boolean;
  refreshIntervalSeconds?: number;
  id?: string;
};

const getTileJsonLastModified = async (url: string): Promise<string> => {
  const response = await axios.head(url);
  const lastModified = response.headers['last-modified'];
  if (lastModified) return lastModified;
  return '';
};

const VisibilityToggledSource = (
  props: VisibilityToggledSourceProps,
): JSX.Element => {
  const {
    children,
    url,
    visible,
    promoteId,
    volatile = false,
    refreshIntervalSeconds,
    id,
  } = props;

  const isRefetchEnabled = useMemo(
    () => visible && !!refreshIntervalSeconds && refreshIntervalSeconds > 0,
    [visible, refreshIntervalSeconds],
  );

  const { data: tilesetLastModified = '' } = useQuery({
    queryKey: ['tileJsonLastModified', url],
    enabled: isRefetchEnabled,
    queryFn: async () => getTileJsonLastModified(url),
    refetchInterval: refreshIntervalSeconds && refreshIntervalSeconds * 1000,
  });

  const sourceUrl = useMemo(() => {
    if (!visible) {
      return getWatchdutyStaticTileUrl('empty');
    }
    if (isRefetchEnabled) {
      return `${url}?ignoredForceLocalRefresh=${encodeURIComponent(tilesetLastModified)}`;
    }
    return url;
  }, [visible, isRefetchEnabled, url, tilesetLastModified]);

  return (
    <Source
      url={sourceUrl}
      type="vector"
      promoteId={promoteId}
      volatile={volatile}
      id={id}
    >
      {children}
    </Source>
  );
};

export default VisibilityToggledSource;

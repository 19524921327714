import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { API } from 'api';
import { AlertThreshold, RiverGaugeAlert } from '../../riverGauges.types';
import { getPushTokenFromLocalStorage } from '../../../../state/localStorage';

type UseRiverGaugeAlertProps = {
  riverGaugeAlertId?: number;
};

type UseRiverGaugeAlert = Omit<
  UseQueryResult<RiverGaugeAlert, Error>,
  'data'
> & {
  riverGaugeAlert?: RiverGaugeAlert;
  saveRiverGaugeAlert: (
    riverGaugeAlertData: RiverGaugeAlertData,
  ) => Promise<RiverGaugeAlert>;
  deleteRiverGaugeAlert: () => Promise<void>;
};

type RiverGaugeAlertData = {
  id?: number;
  stationId: number;
  alertThresholdValue: AlertThreshold;
};

type ServerMutationRiverGaugeAlertData = RiverGaugeAlertData & {
  pushToken: string | null;
};

type DeleteRiverGaugeAlertData = {
  id: number | string;
  pushToken: string | null;
};

const fetchAlert = async (
  riverGaugeId?: number | string,
): Promise<RiverGaugeAlert> => {
  const response = await API.get<RiverGaugeAlert>(
    `weather/alerts/${riverGaugeId}`,
  );
  return response.data;
};

const createAlert = async (
  data: ServerMutationRiverGaugeAlertData,
): Promise<RiverGaugeAlert> => {
  const response = await API.post(`/weather/alerts/`, data);
  return response.data;
};

const updateAlert = async (
  data: ServerMutationRiverGaugeAlertData,
): Promise<RiverGaugeAlert> => {
  const response = await API.put(`/weather/alerts/${data.id}`, data);
  return response.data;
};

const deleteAlert = async (data: DeleteRiverGaugeAlertData): Promise<void> => {
  const response = await API.delete(`/weather/alerts/${data.id}`, {
    params: { pushToken: data.pushToken },
  });
  return response.data;
};

export const useRiverGaugeAlert = (
  props: UseRiverGaugeAlertProps,
): UseRiverGaugeAlert => {
  const { riverGaugeAlertId } = props;
  const queryClient = useQueryClient();
  const pushToken = getPushTokenFromLocalStorage();
  const createMutation = useMutation({ mutationFn: createAlert });
  const updateMutation = useMutation({ mutationFn: updateAlert });
  const deleteMutation = useMutation({ mutationFn: deleteAlert });

  const { data, ...results } = useQuery({
    queryKey: ['riverGaugeAlert', riverGaugeAlertId],
    queryFn: () => fetchAlert(riverGaugeAlertId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: Boolean(riverGaugeAlertId),
  });

  const saveRiverGaugeAlert = async (
    riverGaugeAlertData: RiverGaugeAlertData,
  ): Promise<RiverGaugeAlert> => {
    const mutationData = { ...riverGaugeAlertData, pushToken };
    if (riverGaugeAlertId) {
      const response = await updateMutation.mutateAsync(mutationData);
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['riverGaugeAlerts'] }),
        queryClient.invalidateQueries({
          queryKey: ['riverGaugeAlert', riverGaugeAlertId],
        }),
      ]);
      return response;
    }

    const response = await createMutation.mutateAsync(mutationData);
    await queryClient.invalidateQueries({ queryKey: ['riverGaugeAlerts'] });
    return response;
  };

  const deleteRiverGaugeAlert = async (): Promise<void> => {
    await deleteMutation.mutateAsync({
      id: riverGaugeAlertId!,
      pushToken,
    });
    await queryClient.invalidateQueries({ queryKey: ['riverGaugeAlerts'] });
  };

  return {
    ...results,
    riverGaugeAlert: data,
    saveRiverGaugeAlert,
    deleteRiverGaugeAlert,
  };
};

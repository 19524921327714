import { MarkAreaComponentOption } from 'echarts';
import { TFunction } from 'i18next';
import {
  NORMAL_LEVEL_CHART_COLOR,
  floodCategoryColorsTransparent,
} from '../../riverGauges.constants';
import {
  FloodCategory,
  FloodCategoryLevel,
  RiverGaugeObservation,
} from '../../riverGauges.types';
import { formatNumber } from '../../../../shared/utils';

type GetCategoriesMarkAreaDataArgs = {
  t: TFunction;
  categoryLevels: FloodCategoryLevel[];
  unit: string;
  lowerBounds: number;
  upperBounds: number;
};

const UPPOER_LOWER_BOUNDS_PADDING_PCT = 0.2;

const getCategoryAreaLabels = (
  t: TFunction,
): Record<FloodCategory, string> => ({
  major: t('riverGauges.weatherLevelsChart.levelAreaDescriptions.major'),
  moderate: t('riverGauges.weatherLevelsChart.levelAreaDescriptions.moderate'),
  minor: t('riverGauges.weatherLevelsChart.levelAreaDescriptions.minor'),
  action: t('riverGauges.weatherLevelsChart.levelAreaDescriptions.action'),
  none: '',
});

/**
 * Category levels don't always include the "none" level, but we always want to style it
 */
const getCategoriesSortedWithNone = (
  categoryLevels: FloodCategoryLevel[],
  minYValue: number,
): FloodCategoryLevel[] => {
  const sortedCategoryLevels = [...categoryLevels].sort(
    (a, b) => a.level - b.level,
  );

  if (sortedCategoryLevels[0]?.category !== 'none') {
    return [
      {
        category: 'none',
        level: Math.min(minYValue, 0),
      },
      ...sortedCategoryLevels,
    ];
  }

  return sortedCategoryLevels;
};

export const getCategoriesMarkAreaData = (
  args: GetCategoriesMarkAreaDataArgs,
): MarkAreaComponentOption['data'] => {
  const { t, categoryLevels, unit, lowerBounds, upperBounds } = args;

  if (categoryLevels.length === 0) {
    return [];
  }

  const sortedCategoryLevels = getCategoriesSortedWithNone(
    categoryLevels,
    lowerBounds,
  );

  return sortedCategoryLevels.map((categoryLevel, idx) => {
    const { category, level } = categoryLevel;
    const { level: nextLevel } = sortedCategoryLevels[idx + 1] || {
      level: upperBounds,
    };

    const label = getCategoryAreaLabels(t)[category];
    const min = level;
    const max = nextLevel;
    const minLabel = formatNumber(min, 1).replace('.0', '');

    return [
      {
        name: label ? `${label}: ${minLabel} ${unit}` : '',
        yAxis: min,
        itemStyle: {
          color:
            floodCategoryColorsTransparent[category] ||
            NORMAL_LEVEL_CHART_COLOR,
        },
      },
      {
        yAxis: max,
      },
    ];
  });
};

export const getChartBounds = (
  allValues: RiverGaugeObservation[],
  categories: FloodCategoryLevel[],
): { upperBounds: number; lowerBounds: number } => {
  const levels = allValues.map(({ level }) => level);
  const highestLevel = Math.max(...levels);
  const lowestLevel = Math.min(...levels);

  const sortedCategories = getCategoriesSortedWithNone(categories, lowestLevel);

  const highestFloodCategoryLevel =
    sortedCategories.length > 1
      ? sortedCategories.at(-1)?.level || highestLevel
      : highestLevel;

  // Make sure we have at least a little range so we the chart isn't unrealistically zoomed in
  const chartRange = Math.max(highestFloodCategoryLevel - lowestLevel, 5);

  const lowerBounds =
    // if lowest value is positive, don't extend the lower bounds into the negatives
    lowestLevel >= 0
      ? Math.max(lowestLevel - chartRange * UPPOER_LOWER_BOUNDS_PADDING_PCT, 0)
      : lowestLevel - chartRange * UPPOER_LOWER_BOUNDS_PADDING_PCT;

  const upperBounds =
    highestFloodCategoryLevel + chartRange * UPPOER_LOWER_BOUNDS_PADDING_PCT;

  return { lowerBounds, upperBounds };
};

export const observationToSeriesData = ({
  timestamp,
  level,
}: RiverGaugeObservation): [Date, number] => [
  timestamp,
  Math.round(level * 10) / 10,
];

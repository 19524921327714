import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import { HeaderBar } from 'components/HeaderBar';
import useMapPlaces from 'hooks/useMapPlaces';
import EmptyPlaces from './EmptyPlaces';
import SavedPlaces from './SavedPlaces';

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const { places } = useMapPlaces({ sync: true });

  return (
    <>
      <Helmet>
        <title>{t('places.title')}</title>
      </Helmet>
      {places.length > 0 ? <SavedPlaces /> : <EmptyPlaces />}
    </>
  );
};

const Places = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="PlacesPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar title={t('places.title')} action="/" />,
      }}
    </PageLayout>
  );
};

export default Places;

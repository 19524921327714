import { RouteComponentProps } from 'react-router-dom';
import { subDays } from 'date-fns';
import { useRiverGaugeWithObservations } from 'features/riverGauges/hooks/useRiverGaugeWithObservations';
import PageLayout from 'components/PageLayout';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { HeaderBar } from 'components/HeaderBar';
import { useTranslation } from 'react-i18next';
import { EmbeddedRiverGauge } from './EmbeddedRiverGauge';

export const RiverGaugeEmbedPage = (
  props: RouteComponentProps<{ gaugeId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { gaugeId },
    },
  } = props;
  const { t } = useTranslation();

  const observationsHistoryStart = subDays(new Date(), 7);

  const {
    riverGauge,
    observations = [],
    isLoading,
    error,
  } = useRiverGaugeWithObservations({
    id: gaugeId,
    start: observationsHistoryStart,
    end: new Date(),
  });

  if (isLoading) {
    return (
      <PageLayout data-cname="RiverGaugeEmbedPage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('common.loading')} action="back" />,
        }}
      </PageLayout>
    );
  }

  if (error || !riverGauge) {
    return (
      <PageLayout data-cname="RiverGaugeEmbedPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout isLoading={isLoading} data-cname="RiverGaugeEmbedPage">
      {{
        content: (
          <EmbeddedRiverGauge
            riverGauge={riverGauge}
            observations={observations}
          />
        ),
        headerBar: <HeaderBar />,
      }}
    </PageLayout>
  );
};

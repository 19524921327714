import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useHistory } from 'react-router-dom';
import { HeaderBar, getCameraActions } from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { getDeviceInfo } from 'state/localStorageTyped';
import {
  ProcessPaymentState,
  ProcessMembershipPaymentTokenData,
  MembershipPlan,
  ProcessMembershipUpgradeData,
} from 'shared/types';
import useMembershipState, {
  MEMBERSHIP_CODES,
  MEMBERSHIP_DESCRIPTION_TRANS_KEYS,
  MEMBERSHIP_TITLE_TRANS_KEYS,
  MembershipState,
} from 'state/useMembershipState';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import RecurlyAccountInformation from 'components/RecurlyAccountInformation';
import usePaymentMethodQuery from 'hooks/usePaymentMethodQuery';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { setAnalyticsUser } from 'shared/analytics';
import { useAuthState } from 'state';
import { usePaymentApi } from 'hooks/usePaymentApi';
import { MEMBERSHIP_PLANS_AMOUNTS } from '../../../constants';

const getInitialState = (
  membershipState: MembershipState,
  paramValues: {
    email: string;
    firstName?: string;
    lastName?: string;
  },
): ProcessPaymentState => {
  const {
    clientToken,
    email,
    firstName,
    lastName,
    optInToUpdates,
    existingUser,
    verificationToken,
    userId,
    plan,
  } = membershipState;

  return {
    email: email || paramValues.email,
    firstName: firstName || paramValues.firstName || '',
    lastName: lastName || paramValues.lastName || '',
    clientToken,
    optInToUpdates,
    existingUser,
    transactionAmount: MEMBERSHIP_PLANS_AMOUNTS[plan],
    verificationToken,
    userId,
  };
};

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const params = useLocationSearchParams();
  const history = useHistory();
  const { user } = useAuthState();

  const clientTokenParam = params.get('clientToken') || undefined;
  const emailParam = decodeURIComponent(params.get('email') || '');
  const verificationTokenParam = params.get('verificationToken') || undefined;
  const userIdParam = params.get('userId')
    ? parseInt(params.get('userId') as string, 10)
    : undefined;
  const plan = params.get('plan') || undefined;
  const isMembershipUpgrade =
    params.get('upgrade') === 'true' ||
    user?.supporterStatus?.planType === 'regular';

  useEffect(() => {
    // set the user id for analytics when purchase membership flow continues on web
    if (userIdParam) {
      setAnalyticsUser({ userId: userIdParam.toString() });
    }
  }, [userIdParam]);

  const stateUpdateData: Partial<MembershipState> | undefined = useMemo(() => {
    return clientTokenParam || verificationTokenParam
      ? {
          clientToken: clientTokenParam,
          verificationToken: verificationTokenParam,
          userId: userIdParam,
          existingUser: !!emailParam,
          fromMobile: true,
          plan: plan as MembershipPlan | undefined,
        }
      : undefined;
  }, [clientTokenParam, emailParam, plan, userIdParam, verificationTokenParam]);

  const { updateMembershipState, ...membershipState } = useMembershipState({
    stateUpdateData,
  });

  const initialState = getInitialState(membershipState, {
    email: emailParam,
    firstName: params.get('firstName') || undefined,
    lastName: params.get('lastName') || undefined,
  });

  const userId = membershipState.userId || userIdParam;
  const verificationToken =
    membershipState.verificationToken || verificationTokenParam;

  const { data: savedPaymentMethod, isLoading: isLoadingPaymentMethod } =
    usePaymentMethodQuery({ userId, verificationToken });

  const { processMembershipPaymentMutation } = usePaymentApi<
    ProcessMembershipPaymentTokenData | ProcessMembershipUpgradeData
  >({
    onSuccess: () => {
      let redirectPath = '/membership/success';
      if (isMembershipUpgrade) {
        redirectPath += '?upgrade=true';
      }
      history.push(redirectPath);
    },
  });

  if (isLoadingPaymentMethod) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{t('membershipAccountInformation.title')}</title>
      </Helmet>
      <RecurlyProvider
        publicKey={import.meta.env.VITE_RECURLY_PUBLIC_TOKEN as string}
      >
        <Elements>
          <RecurlyAccountInformation<
            ProcessMembershipPaymentTokenData | ProcessMembershipUpgradeData
          >
            transactionType="membership"
            initialState={initialState}
            updateState={updateMembershipState}
            processPaymentMutation={processMembershipPaymentMutation}
            paymentMethod={savedPaymentMethod}
            membershipConfig={{
              code: MEMBERSHIP_CODES[membershipState.plan],
              title: t(MEMBERSHIP_TITLE_TRANS_KEYS[membershipState.plan]),
              description: t(
                MEMBERSHIP_DESCRIPTION_TRANS_KEYS[membershipState.plan],
              ),
              upgrade: isMembershipUpgrade,
            }}
          />
        </Elements>
      </RecurlyProvider>
    </>
  );
};

const MembershipAccountInformation = (): JSX.Element => {
  const { t } = useTranslation();
  const deviceInfo = getDeviceInfo();
  const isMobilePlatform = !!deviceInfo?.isMobilePlatform;

  return (
    <PageLayout
      data-cname="MembershipAccountInformationPage"
      showBanner={false}
    >
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar
            action={isMobilePlatform ? 'noControls' : 'back'}
            iconActions={getCameraActions(t)}
          />
        ),
      }}
    </PageLayout>
  );
};

export default MembershipAccountInformation;

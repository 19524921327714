import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { StatItem, StatTable } from 'components/StatTable';
import {
  WaterLevelsChartWrapper,
  useWaterLevelsChart,
} from 'features/riverGauges/hooks/useWaterLevelsChart';
import {
  RiverGauge,
  RiverGaugeObservation,
} from 'features/riverGauges/riverGauges.types';
import { TFunction } from 'i18next';
import { EchartsWrapper } from 'libs/echarts/EchartsWrapper';
import { getFloodCategoryFromLevel } from 'pages/RiverGaugeDetails/RiverGaugeDetails.utils';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'shared/utils';
import { makeStyles } from 'tss-react/mui';

type EmbeddedRiverGaugeProps = {
  riverGauge: RiverGauge;
  observations: RiverGaugeObservation[];
};

const EMBED_WIDTH = 700;
const EMBED_HEADERBAR_HEIGHT = 64; // MUI standard header size
const EMBED_HEIGHT = 368 - EMBED_HEADERBAR_HEIGHT;

const getFloodLevelTranslated = (
  t: TFunction,
  category: string | null,
): string | null => {
  if (category && ['major', 'minor', 'moderate', 'action'].includes(category)) {
    return t(`riverGauges.levels.${category}`);
  }

  return category;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  card: {
    borderRadius: 0,
    minHeight: '100%',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    overflow: 'auto',
    maxHeight: '100%',
    paddingTop: theme.spacing(2),
  },
}));

export const EmbeddedRiverGauge = (
  props: EmbeddedRiverGaugeProps,
): JSX.Element => {
  const { riverGauge, observations } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const waterLevelsChartOptions = useWaterLevelsChart({
    observations: observations || [],
    riverGauge,
  });

  const { level: latestLevel = undefined, flow: latestFlow = undefined } =
    observations.length ? observations[0] : {};

  const latestCategory = latestLevel
    ? getFloodCategoryFromLevel(latestLevel, riverGauge.floodCategories)
    : null;

  const stats: StatItem[] = [
    {
      label: t('riverGauges.height'),
      value: latestLevel
        ? `${formatNumber(latestLevel, 1)} ${riverGauge.units.level}`
        : null,
    },
    {
      label: t('riverGauges.flow'),
      value: latestFlow
        ? `${formatNumber(latestFlow)} ${riverGauge.units.flow}`
        : null,
      tooltipText:
        latestFlow && riverGauge.units.flow === 'kcfs'
          ? t('riverGauges.kcfs')
          : undefined,
    },
    {
      label: t('riverGauges.floodLevel'),
      valueTypographyProps: { textTransform: 'capitalize' },
      value: getFloodLevelTranslated(t, latestCategory) || null,
    },
  ];

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          flexShrink: 1,
          flexBasis: `${EMBED_WIDTH + 220 / 2}px`,
          minWidth: '376px',
          height: EMBED_HEIGHT,
          px: 2,
        }}
      >
        {waterLevelsChartOptions && (
          <WaterLevelsChartWrapper>
            <EchartsWrapper option={waterLevelsChartOptions} />
          </WaterLevelsChartWrapper>
        )}
      </Box>

      <Box sx={{ width: '100%', maxWidth: '532px' }}>
        <Card className={classes.card}>
          <CardContent sx={{ py: 0 }}>
            <Stack spacing={2}>
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                {riverGauge.name}
              </Typography>

              <StatTable stats={stats} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

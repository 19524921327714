import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { HeaderBar, getCameraActions } from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import DonateContent from './DonateContent';

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('donate.title')}</title>
      </Helmet>
      <DonateContent />
    </>
  );
};

const Donate = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <PageLayout data-cname="DonatePage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action="back" iconActions={getCameraActions(t)} />
        ),
      }}
    </PageLayout>
  );
};

export default Donate;

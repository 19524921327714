import { ReactNode, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps, Tooltip, ClickAwayListener } from '@mui/material';
import { getDeviceInfo } from 'state/localStorageTyped';

type InfoTooltipProps = {
  title: ReactNode;
  iconSx?: SxProps;
};

/**
 * A consolidated info icon with a tooltip containing helper text. Unlike the
 * vanilla MUI tooltip, this will be triggered on mobile by a simple touch,
 * not requiring a long-press.
 */
export const InfoTooltip = (props: InfoTooltipProps): JSX.Element => {
  const { title, iconSx = {} } = props;
  const [open, setOpen] = useState(false);
  const deviceInfo = getDeviceInfo();
  const isMobilePlatform = !!deviceInfo?.isMobilePlatform;

  if (!isMobilePlatform) {
    return (
      <Tooltip arrow title={title} placement="top">
        <InfoOutlinedIcon
          fontSize="small"
          sx={{ width: 16, height: 16, ...iconSx }}
        />
      </Tooltip>
    );
  }

  return (
    <ClickAwayListener
      onClickAway={() => setOpen(false)}
      touchEvent="onTouchStart"
      clickEvent={false}
    >
      <Tooltip
        arrow
        open={open}
        title={title}
        placement="top"
        onOpen={() => setOpen(true)}
      >
        <InfoOutlinedIcon
          onClick={() => setOpen(true)}
          fontSize="small"
          sx={{ width: 16, height: 16, ...iconSx }}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

import { useEffect, useRef, useState } from 'react';
import { Typography, Tabs, Tab, Stack, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GrayButton from 'components/GrayButton';
import { useTabs } from 'hooks/useTabs';
import { useQueryParams } from 'hooks/useQueryParms';
import { useRiverGaugesEnabled } from 'features/riverGauges/hooks/useRiverGaugesEnabled';
import { RiverGaugeAlertList } from 'features/riverGauges/components/RiverGaugeAlertList';
import { IncidentsSubscriptionsEditor } from './IncidentsSubscriptionsEditor';

const TAB_PANEL_ID_PREFIX = 'notification-settings';

const useStyles = makeStyles()((theme) => ({
  container: {
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
  },
}));

export const NotificationsSubscriptionsEditor = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [, setIsMounted] = useState(false);
  const riverGaugesEnabled = useRiverGaugesEnabled();
  const [{ tab: initialTab = 'incidents' }, setQueryParams] = useQueryParams<{
    tab?: string;
  }>();

  useEffect(() => {
    // For component to re-render when navigating back to this page and
    // container element is not null so we know if component's scrollable.
    setIsMounted(true);
  }, []);

  const tabData = [
    {
      tabId: 'incidents',
      label: t('notifications.tabs.incidents'),
      enabled: true,
      content: (
        <IncidentsSubscriptionsEditor
          scrollContainer={containerRef.current || undefined}
          // Reset the initial tab value to match the current active tab when leaving the page.
          onDownstateClick={() => setQueryParams({ tab: 'incidents' })}
        />
      ),
      panelSx: { flex: 1, mt: '0px !important' },
    },
    {
      tabId: 'other',
      label: t('notifications.tabs.other'),
      enabled: riverGaugesEnabled,
      content: (
        <RiverGaugeAlertList
          fromRoute="/notifications?tab=other"
          scrollContainer={containerRef.current || undefined}
        />
      ),
      panelSx: { flex: 1, mt: '0px !important' },
    },
  ];

  const { tabsProps, tabProps, tabPanels, activeTabId } = useTabs({
    idPrefix: TAB_PANEL_ID_PREFIX,
    ariaLabel: 'notifications tabs',
    initialTab,
    tabData,
  });

  const resetInitialTab = (): void => {
    // Reset the initial tab value to match the current active tab when leaving the page.
    // Without this reset, returning from editing incidents or test notifications could
    // result in the wrong tab remaining selected.
    if (initialTab !== activeTabId) {
      setQueryParams({ tab: activeTabId });
    }
  };

  return (
    <Box className={classes.container} ref={containerRef}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography variant="h2">
            {t('notifications.notifications')}
          </Typography>

          <GrayButton
            fullWidth
            size="large"
            component={Link}
            to={{ pathname: '/notifications/test' }}
            onClick={resetInitialTab}
          >
            {t('notifications.test')}
          </GrayButton>
        </Stack>

        <Tabs {...tabsProps}>
          {tabProps.map((props) => (
            <Tab
              key={props.key}
              {...props}
              sx={{ maxWidth: 1 / tabData.filter((tab) => tab.enabled).length }}
            />
          ))}
        </Tabs>

        {tabPanels}
      </Stack>
    </Box>
  );
};

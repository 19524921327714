import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
  PushNotificationSchema,
  PushNotifications,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import * as Sentry from '@sentry/capacitor';
import { History } from 'history';
import { getCurrentPosition } from 'hooks/usePosition';
import { registerDeviceWithWatchDuty } from 'shared/device';
import { User } from 'shared/types';
import {
  getPushTokenFromLocalStorage,
  setLastPushNotificationTimestamp,
} from 'state/localStorage';
import {
  getNotificationData,
  getRouteForPushData,
  initAndroidPushChannel,
  isVisiblePushNotification,
  replayAndroidPushInFocus,
} from './initPushNotifications.utils';

export const handlePushRegistrationFinished = async (
  token: Token,
  user?: User | null,
): Promise<void> => {
  const currentToken = getPushTokenFromLocalStorage();

  // if the token given to us from FCM is the same as what
  // we have locally, this is a no-op
  if (currentToken === token.value) {
    return;
  }

  const [, positionResult] = await getCurrentPosition();
  const params = {
    user,
    push_token: token.value,
    lat: positionResult?.coords?.latitude,
    lng: positionResult?.coords?.longitude,
  };
  await registerDeviceWithWatchDuty(params);
};

const handlePushRegistrationFailed = (error: RegistrationError): void => {
  // @todo upload this token to watch duty
  // eslint-disable-next-line no-console
  console.error('push registration failed', error);
};

const handlePushReceived = async (
  notification: PushNotificationSchema,
  setCacheBuster?: (ts: number | null) => void,
): Promise<void> => {
  setLastPushNotificationTimestamp();

  if (Capacitor.getPlatform() === 'android') {
    /**
     * On Android, foreground notifications are non-clickable due to an underlying capacitor issue.
     * Additionally, these notifications use the default system sound. To address this, we have to
     * remove the delivered notification from the screen so that it's never shown and schedule a
     * local notification with the same payload that uses our custom notification sound and is clickable.
     * The above was true for Capacitor 4, no longer true in Capacitor 5.
     */
    await PushNotifications.getDeliveredNotifications()
      .then((deliveredNotif) => {
        PushNotifications.removeDeliveredNotifications(deliveredNotif);
      })
      .catch((err) => Sentry.captureException(err));
  }

  if (!isVisiblePushNotification(notification)) {
    return;
  }

  const { object } = getNotificationData(notification);

  // make a cacheBuster from the model
  const ts =
    'date_modified' in object && object.date_modified
      ? new Date(object.date_modified)
      : new Date();
  if (setCacheBuster) setCacheBuster(ts.getTime());

  if (Capacitor.getPlatform() === 'android') {
    replayAndroidPushInFocus(notification);
  }
};

const handlePushTapped = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: Record<string, any>,
  history: History,
  setCacheBuster?: (ts: number | null) => void,
): void => {
  const { actionId, notification } = details;
  if (actionId !== 'tap') {
    return;
  }

  const pushData = getNotificationData(notification);

  // make a cacheBuster from the model
  const ts =
    'date_modified' in pushData.object && pushData.object.date_modified
      ? new Date(pushData.object.date_modified)
      : new Date();

  if (setCacheBuster) {
    setCacheBuster(ts.getTime());
  }

  const route = getRouteForPushData(pushData);

  if (route) {
    // Current belief is that when the app comes back from a "long-term" minimized state, there is a race condition
    //   where this updates history, and then something occurs to overwrite that history change.
    //   By delaying the history.push, we ensure this is the "last" url that the user ends up seeing.
    // See ticket: https://trello.com/c/svhXqy1D/932-fix-investigate-push-notification-tap-bug
    setTimeout(() => history.push(route), 500);
  }
};

export const initPushNotifications = async (
  history: History,
  setCacheBuster?: (ts: number | null) => void,
  handleTokenRegistration?: (token: Token) => Promise<void>,
): Promise<boolean> => {
  if (!Capacitor.isNativePlatform()) {
    return false;
  }

  if (!Capacitor.isPluginAvailable('PushNotifications')) {
    return false;
  }

  const result = await PushNotifications.requestPermissions();

  if (result.receive !== 'granted') {
    return false;
  }

  await PushNotifications.removeAllListeners();

  // Note: these listeners need to come BEFORE PushNotifications.register()
  PushNotifications.addListener(
    'registration',
    handleTokenRegistration || handlePushRegistrationFinished,
  );
  PushNotifications.addListener(
    'registrationError',
    handlePushRegistrationFailed,
  );
  PushNotifications.addListener('pushNotificationReceived', (notif) => {
    handlePushReceived(notif, setCacheBuster);
  });
  PushNotifications.addListener('pushNotificationActionPerformed', (notif) => {
    handlePushTapped(notif, history, setCacheBuster);
  });
  LocalNotifications.addListener(
    'localNotificationActionPerformed',
    (notif) => {
      handlePushTapped(notif, history, setCacheBuster);
    },
  );

  // Register with Apple / Google to receive push token via APNS/FCM
  await PushNotifications.register();

  if (Capacitor.getPlatform() === 'android') {
    await initAndroidPushChannel();
  }

  return true;
};

import { Button, Typography } from '@mui/material';
import RiverGaugeNotificationsIcon from 'assets/river-gauge-notifications.svg?react';
import { DownState } from 'components/DownState';
import { MapLayers } from 'components/Map/constants';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, openNativeAppSettings } from 'shared/utils';
import useMapLayersState from 'state/useMapLayersState';
import { Link } from 'react-router-dom';

type NotificationsDownstateProps = {
  scrollContainer?: HTMLElement;
  isPushEnabled?: boolean;
  gaugeId?: number | string;
};

export const NotificationsDownstate = (
  props: NotificationsDownstateProps,
): JSX.Element => {
  const { scrollContainer, isPushEnabled = true, gaugeId } = props;
  const { t } = useTranslation();
  const { mapLayers, setMapLayerVisibility } = useMapLayersState();

  const enableRiverGaugesLayer = (): void => {
    setMapLayerVisibility(MapLayers.RIVER_GAUGES, true);
  };

  return (
    <DownState
      states={[
        {
          criteria: () => !isMobile(),
          renderImage: (imageProps) => (
            <RiverGaugeNotificationsIcon {...imageProps} />
          ),
          title: t('riverGauges.alerts.waterLevel.title'),
          description: t('riverGauges.alerts.waterLevel.description'),
          containerProps: { sx: { p: 2 } },
        },
        {
          criteria: () => !isPushEnabled,
          renderImage: (imageProps) => (
            <RiverGaugeNotificationsIcon {...imageProps} />
          ),
          title: t('riverGauges.alerts.turnOn.title'),
          description: (
            <>
              <Typography>
                <Trans i18nKey="riverGauges.alerts.turnOn.description1">
                  Never miss important updates in your area by
                  <b>enabling notifications</b>.
                </Trans>
              </Typography>
              <Typography>
                {t('riverGauges.alerts.turnOn.description2')}
              </Typography>
            </>
          ),
          Button: (
            <Button fullWidth size="large" onClick={openNativeAppSettings}>
              {t('riverGauges.alerts.turnOn.button')}
            </Button>
          ),
          containerProps: { spacing: 6, sx: { p: 2 } },
        },
        {
          criteria: () => !!gaugeId,
          renderImage: (imageProps) => (
            <RiverGaugeNotificationsIcon {...imageProps} />
          ),
          title: t('riverGauges.alerts.add.title'),
          description: t('riverGauges.alerts.add.description'),
          Button: (
            <Button
              fullWidth
              size="large"
              component={Link}
              to={`/gauge/${gaugeId}/alert/create`}
            >
              {t('riverGauges.alerts.addNotification')}
            </Button>
          ),
          containerProps: { spacing: 6, sx: { p: 2 } },
        },
        {
          renderImage: (imageProps) => (
            <RiverGaugeNotificationsIcon {...imageProps} />
          ),
          title: t('riverGauges.alerts.configure.title'),
          description: t('riverGauges.alerts.configure.description'),
          Button: !mapLayers.includes(MapLayers.RIVER_GAUGES) ? (
            <Button fullWidth size="large" onClick={enableRiverGaugesLayer}>
              {t('riverGauges.alerts.configure.button')}
            </Button>
          ) : undefined,
          fixedButton: !mapLayers.includes(MapLayers.RIVER_GAUGES),
          scrollContainer,
          containerProps: {
            sx: { height: '100%', px: 2, pt: 6 },
          },
        },
      ]}
    />
  );
};

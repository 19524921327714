import { Capacitor } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { defaultsDeep } from 'lodash-es';
import { useEffect, useState, useCallback } from 'react';
import { UserPosition } from 'shared/types';
import { getData, LOCAL_STORAGE_KEY, saveData } from 'state/localStorage';
import { getDeviceInfo } from 'state/localStorageTyped';
import useForegroundState from './useForegroundState';
import usePrevious from './usePrevious';

const GEO_REQUEST_MAX_AGE = 60000; // 1 min
const PERMISSION_DENIED_MESSAGE = 'Permission Denied';

type PositionResult = [
  null | Error | GeolocationPositionError,
  Position | null,
];

type CallbackFn = (result: PositionResult) => void;

export const getCurrentPositionFromCapacitor =
  async (): Promise<PositionResult> => {
    try {
      const coords = await Geolocation.getCurrentPosition({
        maximumAge: GEO_REQUEST_MAX_AGE,
      });

      return [null, coords];
    } catch (e) {
      return [e as Error | GeolocationPositionError, null];
    }
  };

const createPermissionDeniedError = (): Error => {
  const error = new Error(PERMISSION_DENIED_MESSAGE);
  return error;
};

export const requestGeolocationPermissionsForNative =
  async (): Promise<boolean> => {
    try {
      const results = await Geolocation.requestPermissions();
      return (
        results?.location === 'granted' || results?.coarseLocation === 'granted'
      );
    } catch (e) {
      return false;
    }
  };

export const hasGeolocationPermissions = async (): Promise<boolean> => {
  try {
    const deviceInfo = getDeviceInfo();

    const results = await Geolocation.checkPermissions();

    /**
     * Safari geolocation result is always "prompt" on macOS and iOS.
     * A similar behavior is observed on iOS chrome browser.
     * To mitigate this, we check if the result is different than denied.
     * Location access is later confirm when getting the user's current
     * position with the possible geolocation error response.
     */
    if (
      deviceInfo?.isWeb &&
      ['mac', 'ios'].includes(deviceInfo?.operatingSystem ?? '')
    ) {
      return results?.location !== 'denied';
    }

    return (
      results?.location === 'granted' || results?.coarseLocation === 'granted'
    );
  } catch (e) {
    return false;
  }
};

const getCachedGeoData = (): Position | undefined =>
  getData<Position>(LOCAL_STORAGE_KEY.LAST_KNOWN_GEOLOCATION);

const persistCachedGeoData = (geoData: Position | null): void => {
  // For some reason, we can't stringify the geoData object that we get. @see https://stackoverflow.com/q/11042212/477632
  const fixedData = defaultsDeep({ coords: {} }, geoData);

  saveData(LOCAL_STORAGE_KEY.LAST_KNOWN_GEOLOCATION, fixedData);
};

const GeolocationRequestManager: {
  requests: CallbackFn[];
  hasRequestedNativePermissions: boolean;
  hasGrantedNativePermissions: boolean;
  isRequestInProgress: boolean;
  requestGeolocation: (cb: CallbackFn) => void;
  kickoffRequests: () => void;
  onError: (err: Error | GeolocationPositionError) => void;
  onSuccess: (res: Position | null) => void;
  isPermissionEnabled: () => boolean;
} = {
  requests: [],
  hasRequestedNativePermissions: false,
  hasGrantedNativePermissions: false,
  isRequestInProgress: false,

  requestGeolocation(callback: CallbackFn = (): void => {}) {
    this.requests.push(callback);

    if (!this.isRequestInProgress) {
      this.kickoffRequests();
    }
  },

  async kickoffRequests() {
    this.isRequestInProgress = true;

    if (
      Capacitor.isNativePlatform() &&
      !this.hasRequestedNativePermissions &&
      !this.hasGrantedNativePermissions
    ) {
      this.hasRequestedNativePermissions = true;
      this.hasGrantedNativePermissions =
        await requestGeolocationPermissionsForNative();

      if (!this.hasGrantedNativePermissions) {
        this.onError(createPermissionDeniedError());
        this.isRequestInProgress = false;
        return;
      }
    }

    const [error, result] = await getCurrentPositionFromCapacitor();

    if (error) {
      this.onError(error);
      this.isRequestInProgress = false;
      return;
    }

    persistCachedGeoData(result);

    this.onSuccess(result);
    this.isRequestInProgress = false;
  },

  onError(error: Error | GeolocationPositionError) {
    const cachedCoords = getCachedGeoData();

    if (cachedCoords) {
      this.onSuccess(cachedCoords);
      return;
    }

    this.requests.forEach((callback) => {
      callback([error, null]);
    });

    this.requests = [];
  },

  onSuccess(result) {
    this.requests.forEach((callback) => {
      callback([null, result]);
    });

    this.requests = [];
  },

  isPermissionEnabled() {
    return Capacitor.isNativePlatform()
      ? this.hasGrantedNativePermissions
      : true; // for web
  },
};

export const getCurrentPosition = (): Promise<PositionResult> =>
  new Promise((resolve) => {
    GeolocationRequestManager.requestGeolocation(resolve);
  });

const usePosition = (): UserPosition => {
  const [position, setPosition] = useState<{
    lat?: number;
    lng?: number;
    az?: number;
    accuracy?: number;
  }>({});
  const [error, setError] = useState<Error | GeolocationPositionError | null>(
    null,
  );
  const [askForPermission, setAskForPermission] = useState(false);
  const [locationEnabled, setLocationEnabled] = useState(false);
  const appState = useForegroundState();
  const prevAppStateActive = usePrevious(appState.isActive);

  const handlePositionReceived = useCallback((coords?: Position['coords']) => {
    if (!coords) return;

    const { latitude, longitude, heading, accuracy } = coords;

    setPosition({
      lat: latitude,
      lng: longitude,
      az: heading ?? undefined,
      accuracy: accuracy ?? undefined,
    });
  }, []);

  const getUserPosition = useCallback(
    async (handlePermission = true) => {
      const [permissionEnabled, [geoError, coords]] = await Promise.all([
        hasGeolocationPermissions(),
        getCurrentPosition(),
      ]);

      let locationPermissionEnabled = permissionEnabled;

      /**
       * Confirm if a user has geolocation permissions.
       * Workaround mentioned in "hasGeolocationPermissions" fn.
       */
      if (
        permissionEnabled &&
        geoError &&
        (geoError.message === PERMISSION_DENIED_MESSAGE ||
          ('code' in geoError &&
            geoError?.code === GeolocationPositionError.PERMISSION_DENIED))
      ) {
        locationPermissionEnabled = false;
      }

      setLocationEnabled(locationPermissionEnabled);

      if (geoError) {
        setError(geoError);
      } else if (locationPermissionEnabled && coords) {
        handlePositionReceived(coords.coords);
      }

      if (handlePermission && !locationPermissionEnabled) {
        setAskForPermission(true);
      }
    },
    [handlePositionReceived],
  );

  useEffect(() => {
    getUserPosition(false);
  }, [getUserPosition]);

  const updateLocationPermissionState = useCallback(async () => {
    const permissionEnabled = await hasGeolocationPermissions();
    setLocationEnabled(permissionEnabled);
  }, []);

  useEffect(() => {
    if (
      typeof prevAppStateActive !== 'undefined' &&
      !prevAppStateActive &&
      appState.isActive
    ) {
      // background -> foreground
      updateLocationPermissionState();
    }
  }, [prevAppStateActive, appState.isActive, updateLocationPermissionState]);

  return {
    ...position,
    error,
    askForPermission,
    locationEnabled,
    getUserPosition,
    setAskForPermission,
  };
};

export default usePosition;

import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import TextLogo from 'components/TextLogo';
import { getResponsiveFontSize } from 'shared/utils';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVert from '@mui/icons-material/MoreVert';
import { Link, useHistory } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useTranslation } from 'react-i18next';
import useDrawerNavState from 'state/useDrawerNavState';
import CameraIcon from '@mui/icons-material/CameraAlt';
import { TFunction } from 'i18next';
import MenuBadge from './MenuBadge';
import DrawerNav from './DrawerNav';

type HeaderBarAction = 'noControls' | 'back' | string;

type HeaderIconAction = {
  label: string;
  to: string;
  Icon: ReactNode;
  variant?: 'camera';
};

export type HeaderMenuAction = {
  label: string;
  to: string;
};

type HeaderBarProps = {
  title?: string;
  action?: HeaderBarAction;
  dark?: boolean;
  bgColor?: string;
  elevation?: number;
  iconActions?: HeaderIconAction[];
  menuActions?: HeaderMenuAction[];
};

type ActionButtonProps = {
  action?: HeaderBarAction;
  className?: string;
  iconClassName?: string;
  toggleNavDrawer: () => void;
};

export const getCameraActions = (
  t: TFunction,
  to: string = '/add_report',
): HeaderIconAction[] => {
  if (!Capacitor.isNativePlatform()) {
    return [];
  }
  return [
    {
      label: t('header.actions.submitPhoto'),
      to,
      Icon: <CameraIcon />,
      variant: 'camera' as const,
    },
  ];
};

const getAppBgColor = (
  theme: Theme,
  dark: boolean,
  bgColor?: string,
): string => {
  if (dark) {
    return theme.palette.common.black;
  }

  if (bgColor) {
    return bgColor;
  }

  return import.meta.env.VITE_ENV === 'staging'
    ? '#008000' /* green */
    : theme.palette.primary.main;
};

const useStyles = makeStyles<{ dark: boolean; bgColor?: string }>()((
  theme,
  { dark, bgColor },
) => {
  const appBgColor = getAppBgColor(theme, dark, bgColor);

  return {
    menuButton: {
      marginRight: theme.spacing(1),
      // hard-coding to prevent iOS Dynamic Type from resizing this
      width: 48,
      height: 48,
    },
    menuIcon: {
      width: 24,
      height: 24,
      color: dark ? theme.palette.common.white : theme.palette.text.primary,
    },
    appBarMiddle: {
      flexGrow: 1,
      minWidth: 0,
    },
    title: {
      fontSize: getResponsiveFontSize(theme.typography.pxToRem(20)),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    textLogo: {
      // hard-coding to prevent iOS Dynamic Type from resizing this
      width: 144, // 9rem
      fill: dark ? theme.palette.common.white : theme.palette.text.primary,
    },
    appBar: {
      flexGrow: 0,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      paddingTop: `env(safe-area-inset-top)`,
      position: 'sticky',
      backgroundColor: appBgColor,
      top: 0,
      zIndex: theme.zIndex.drawer + 3,
    },
  };
});

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  const { action, className, iconClassName, toggleNavDrawer } = props;
  const history = useHistory();
  const { t } = useTranslation();

  if (!action) {
    return (
      <IconButton
        edge="start"
        className={className}
        color="inherit"
        aria-label={t('header.menu')}
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleNavDrawer}
        size="large"
      >
        <MenuBadge />
      </IconButton>
    );
  }

  if (action === 'noControls') {
    return <></>;
  }

  return (
    <IconButton
      edge="start"
      className={className}
      color="inherit"
      aria-label={t('header.menu')}
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={() => {
        // We can't always allow action="back" because that could take the user out of Watch Duty. For now, we're opting into this with a special URL #hash
        // @see https://trello.com/c/Ywn5UB9H/279-refactor-page-history-mechanism
        if (action === 'back' || window.location.hash === '#allow-back') {
          // history does indeed have a goBack function
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          history.goBack();
        } else {
          history.push(action);
        }
      }}
      size="large"
    >
      <ArrowBackIcon className={iconClassName} />
    </IconButton>
  );
};

export const HeaderBar = (props: HeaderBarProps): JSX.Element => {
  const {
    title,
    action,
    dark = false,
    bgColor,
    elevation = 4,
    iconActions = [],
    menuActions = [],
  } = props;
  const { classes } = useStyles({ dark, bgColor });
  const theme = useTheme();
  const { open, toggleOpen } = useDrawerNavState();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      const appBgColor = getAppBgColor(theme, dark, bgColor);
      StatusBar.setBackgroundColor({ color: appBgColor });
      StatusBar.setStyle({ style: dark ? Style.Dark : Style.Light });
    }
  }, [dark, theme, bgColor]);

  const showMenuActions = menuActions.length > 0;

  return (
    <AppBar className={classes.appBar} elevation={elevation}>
      <Toolbar>
        <ActionButton
          action={action}
          className={classes.menuButton}
          iconClassName={classes.menuIcon}
          toggleNavDrawer={toggleOpen}
        />

        <Box className={classes.appBarMiddle}>
          {title ? (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          ) : (
            <TextLogo className={classes.textLogo} />
          )}
        </Box>

        {iconActions.length > 0 && (
          <Stack direction="row" spacing={2}>
            {iconActions.map(({ to, label, Icon, ...rest }) => (
              <IconButton
                key={`${to}-${label}`}
                to={to}
                component={Link}
                aria-label={label}
                {...rest}
              >
                {Icon}
              </IconButton>
            ))}
          </Stack>
        )}

        {showMenuActions && (
          <>
            <IconButton
              onClick={handleClick}
              size="medium"
              aria-label={t('header.moreButtonLabel')}
            >
              <MoreVert sx={{ width: 24, height: 24 }} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{ '& .MuiMenu-list': { padding: 0, minWidth: 175 } }}
            >
              {menuActions.map(({ to, label }, index) => (
                <MenuItem
                  key={`${to}-${label}`}
                  component={Link}
                  to={to}
                  onClick={handleClose}
                  sx={{ padding: '12px 16px' }}
                  divider={
                    menuActions.length > 1 && index !== menuActions.length - 1
                  }
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Toolbar>

      <DrawerNav open={open} toggleOpen={toggleOpen} />
    </AppBar>
  );
};

import { EChartsOption } from 'echarts';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  DatasetComponent,
  GraphicComponent,
  GridComponent,
  MarkAreaComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { echartsTheme } from './echartsTheme';

export type EchartsWrapperProps = Partial<ReactEChartsCore> & {
  option: EChartsOption;
};

/**
 * Register only the components we'll use. This should improve bundle size.
 * See echarts-for-react docs: https://www.npmjs.com/package/echarts-for-react
 */
echarts.use([
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LineChart,
  MarkAreaComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
  GraphicComponent,
]);

export const EchartsWrapper = (props: EchartsWrapperProps): JSX.Element => (
  <ReactEChartsCore theme={echartsTheme} {...props} echarts={echarts} />
);

import { getActiveSettings, getSortedRegionsSections } from 'shared/utils';
import { useMemo } from 'react';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import Loader from 'components/Loader';
import { Box, Button } from '@mui/material';
import { DownState } from 'components/DownState';
import TurnOnNotificationsIcon from 'assets/turn_on_notifications.svg?react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RegionEditorCard from './RegionEditorCard';
import { NotificationTypes } from '../../constants';

type IncidentsSubscriptionsEditorProps = {
  scrollContainer?: HTMLElement;
  onDownstateClick: () => void;
};

const footerSx = {
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  padding: 2,
  paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  backgroundColor: 'background.paper',
  zIndex: 1,
};

export const IncidentsSubscriptionsEditor = (
  props: IncidentsSubscriptionsEditorProps,
): JSX.Element => {
  const { scrollContainer, onDownstateClick } = props;
  const { subscribedNotificationSettings } = useNotificationsSettings();
  const { t } = useTranslation();

  const { data, isLoading, isError, isRefetching } =
    subscribedNotificationSettings;

  const regionSettings = data?.data ?? [];

  const activeSettings = getActiveSettings(regionSettings);

  const regionSettingsSections = useMemo(
    () => getSortedRegionsSections(activeSettings, null),
    [activeSettings],
  );

  const isAnyCountySelected = regionSettings.some(
    (rS) => rS.setting !== NotificationTypes.OFF.key,
  );

  if (isLoading || isError || !data) {
    return <LoadingAndErrors isLoading={isLoading} />;
  }

  if (isAnyCountySelected) {
    return (
      <>
        <Box sx={{ paddingX: 2, paddingTop: 4, paddingBottom: 10 }}>
          {regionSettingsSections.map((regionSection) => (
            <RegionEditorCard
              key={regionSection.title}
              title={regionSection.title}
              regionSettings={regionSection.regionSettings}
            />
          ))}

          {isRefetching && <Loader disableBackground />}
        </Box>
        <Box sx={footerSx}>
          <Button
            fullWidth
            size="large"
            component={Link}
            to="/notifications/add_counties"
          >
            {t('notifications.addCounty')}
          </Button>
        </Box>
      </>
    );
  }

  return (
    <DownState
      states={[
        {
          renderImage: (imageProps) => (
            <TurnOnNotificationsIcon {...imageProps} />
          ),
          title: t('notifications.addCounties.title'),
          description: t('notifications.addCounties.description'),
          Button: (
            <Button
              fullWidth
              size="large"
              component={Link}
              to="/notifications/add_counties"
              onClick={onDownstateClick}
            >
              {t('notifications.addCounty')}
            </Button>
          ),
          fixedButton: true,
          scrollContainer,
          containerProps: { height: '100%', px: 2, pt: 6 },
        },
      ]}
    />
  );
};

import { API } from 'api';
import { DeepPartial, GeoEvent, GeoEventCreateUpdateData } from 'shared/types';

type PostGeoEventProps = {
  geoEventData: GeoEventCreateUpdateData;
  skipDuplicationCheck?: boolean;
};

type PatchGeoEventProps = {
  id: number;
  geoEventData: DeepPartial<GeoEventCreateUpdateData>;
};

/**
 * For prescribed burn wildfires, prescribedDateStartLocal comes back as a UTC date string,
 * but its meant to be used as a naive timezone-agnostic date. This function
 * removes UTC from that date string if present.
 *
 * @see https://trello.com/c/p9Jvym3u/1024-add-timezone-z-to-strings-returned-from-our-drf-serializers
 */
const adjustNaiveTimezones = (geoEvent: GeoEvent): GeoEvent => {
  if (geoEvent.geoEventType !== 'wildfire') {
    return geoEvent;
  }

  const prescribedStartLocalIso = geoEvent.data.prescribedDateStartLocal;

  if (!prescribedStartLocalIso) {
    return geoEvent;
  }

  return {
    ...geoEvent,
    data: {
      ...geoEvent.data,
      prescribedDateStartLocal: prescribedStartLocalIso.replace('Z', ''),
    },
  };
};

export const fetchGeoEvent = async (geoEventId: number): Promise<GeoEvent> => {
  const response = await API.get(`geo_events/${geoEventId}`);
  return adjustNaiveTimezones(response.data);
};

export const postGeoEvent = async (
  props: PostGeoEventProps,
): Promise<GeoEventCreateUpdateData> => {
  const { geoEventData, skipDuplicationCheck } = props;
  const response = await API.post(`geo_events/`, geoEventData, {
    params: {
      skipDuplicationCheck,
    },
  });

  return response.data;
};

export const patchGeoEvent = async (
  props: PatchGeoEventProps,
): Promise<GeoEventCreateUpdateData> => {
  const { id, geoEventData } = props;
  const response = await API.patch(`geo_events/${id}`, geoEventData);
  return response.data;
};

import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Stack,
  Tooltip,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { useRiverGaugeWithObservations } from 'features/riverGauges/hooks/useRiverGaugeWithObservations';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { MapEntityDrawerHeader } from 'components/Map/MapEntityDrawerHeader';
import { StatItem, StatTable } from 'components/StatTable';
import { subDays } from 'date-fns';
import { getTimePass } from 'shared/dates';
import { Capacitor } from '@capacitor/core';
import { useShareOrCopy } from 'hooks/useShareOrCopy';
import { formatNumber, getCurrentLocationAsShareUrl } from 'shared/utils';
import { RiverGaugeAlertList } from 'features/riverGauges/components/RiverGaugeAlertList';
import { useTabs } from 'hooks/useTabs/useTabs';
import { TFunction } from 'i18next';
import {
  WaterLevelsChartWrapper,
  useWaterLevelsChart,
} from 'features/riverGauges/hooks/useWaterLevelsChart';
import { EchartsWrapper } from 'libs/echarts/EchartsWrapper';
import { useContext, useEffect, useState } from 'react';
import useMapLayersState from 'state/useMapLayersState';
import { MapLayers } from 'components/Map/constants';
import { useQueryParams } from 'hooks/useQueryParms';
import { DrawerStickyHeader } from 'components/DrawerStickyHeader';
import { MapEntityDrawerContext } from 'components/Map/MapEntityDrawer';
import { FloodImpacts } from 'features/riverGauges/components/FloodImpacts';
import { getFloodCategoryFromLevel } from './RiverGaugeDetails.utils';

const OBSERVATIONS_POLL_INTERVAL = 5 * 60 * 1000;
const TAB_PANEL_ID_PREFIX = 'river-gauge-tab-panel';

const getFloodLevelTranslated = (
  t: TFunction,
  category: string | null,
): string | null => {
  if (category && ['major', 'minor', 'moderate', 'action'].includes(category)) {
    return t(`riverGauges.levels.${category}`);
  }

  return category;
};

export const RiverGaugeDetails = (): JSX.Element => {
  const { gaugeId } = useParams<{ gaugeId: string }>();
  const { t } = useTranslation();
  const { canShareOrCopy, shareOrCopyUrl } = useShareOrCopy();
  const observationsHistoryStart = subDays(new Date(), 7);
  const [{ tab: initialTab = 'conditions' }] = useQueryParams<{
    tab: string;
  }>();
  const { mapLayers, setMapLayerVisibility } = useMapLayersState();

  const isRiverGaugesLayerEnabled = mapLayers.includes(MapLayers.RIVER_GAUGES);

  useEffect(() => {
    if (!isRiverGaugesLayerEnabled) {
      setMapLayerVisibility(MapLayers.RIVER_GAUGES, true);
    }
  }, [isRiverGaugesLayerEnabled, setMapLayerVisibility]);

  const { scrollElementRef } = useContext(MapEntityDrawerContext);
  const [stickyHeaderContentHeight, setStickyHeaderContentHeight] = useState(0);

  const { riverGauge, observations, forecasts, isLoading, error } =
    useRiverGaugeWithObservations({
      id: gaugeId,
      start: observationsHistoryStart,
      end: new Date(),
      pollInterval: OBSERVATIONS_POLL_INTERVAL,
    });

  const waterLevelsChartOptions = useWaterLevelsChart({
    observations: observations || [],
    forecasts: forecasts || [],
    riverGauge,
  });

  const tabData = [
    {
      tabId: 'conditions',
      label: t('riverGauges.tabs.conditions'),
      enabled: true,
      content: (
        <Stack sx={{ p: 2, pb: 0 }} spacing={2}>
          {waterLevelsChartOptions && (
            <WaterLevelsChartWrapper>
              <EchartsWrapper option={waterLevelsChartOptions} />
            </WaterLevelsChartWrapper>
          )}
          {riverGauge?.noaaLid && (
            <Button
              component={Link}
              to={{
                pathname: `https://water.noaa.gov/gauges/${riverGauge.noaaLid}`,
              }}
              target="_blank"
              rel="nofollow"
              sx={{
                fontWeight: 'bold',
                pointerEvents: 'auto',
              }}
              fullWidth
            >
              {t('riverGauges.noaaDetails')}
            </Button>
          )}
          {!!riverGauge?.floodImpacts.length && (
            <FloodImpacts floodImpacts={riverGauge.floodImpacts} />
          )}
        </Stack>
      ),
    },
    {
      tabId: 'notifications',
      label: t('riverGauges.tabs.notifications'),
      enabled: true,
      content: (
        <RiverGaugeAlertList
          riverGaugeId={gaugeId}
          fromRoute={`/gauge/${gaugeId}?tab=notifications`}
        />
      ),
    },
  ];

  const { tabsProps, tabProps, tabPanels } = useTabs({
    idPrefix: TAB_PANEL_ID_PREFIX,
    ariaLabel: 'river gauge tabs',
    initialTab,
    tabData,
  });

  if (isLoading || error || !riverGauge || !observations) {
    return <LoadingAndErrors isLoading={isLoading} />;
  }

  const {
    level: latestLevel = undefined,
    flow: latestFlow = undefined,
    timestamp: lastUpdated = undefined,
  } = observations.length ? observations[0] : {};

  const handleShare = (): void => {
    shareOrCopyUrl({
      title: t('home.riverGaugeTitle', { name: riverGauge.name }),
      url: getCurrentLocationAsShareUrl(lastUpdated),
    });
  };

  const latestCategory = latestLevel
    ? getFloodCategoryFromLevel(latestLevel, riverGauge.floodCategories)
    : null;

  const stats: StatItem[] = [
    {
      label: t('riverGauges.height'),
      value: latestLevel
        ? `${formatNumber(latestLevel, 1)} ${riverGauge.units.level}`
        : null,
    },
    {
      label: t('riverGauges.flow'),
      value: latestFlow
        ? `${formatNumber(latestFlow)} ${riverGauge.units.flow}`
        : null,
      tooltipText:
        latestFlow && riverGauge.units.flow === 'kcfs'
          ? t('riverGauges.kcfs')
          : undefined,
    },
    {
      label: t('riverGauges.floodLevel'),
      valueTypographyProps: { textTransform: 'capitalize' },
      value: getFloodLevelTranslated(t, latestCategory) || null,
    },
  ];

  const shareTooltipText = Capacitor.isNativePlatform()
    ? t('common.share')
    : t('common.copyLinkToClipboard');

  return (
    <>
      <Helmet>
        <title>{t('home.riverGaugeTitle', { name: riverGauge.name })}</title>
      </Helmet>

      <Box sx={{ mb: 'env(safe-area-inset-bottom)' }}>
        <DrawerStickyHeader
          scrollContainer={scrollElementRef?.current as HTMLElement}
          onStickyContentHeightChange={setStickyHeaderContentHeight}
        >
          <DrawerStickyHeader.ContentContainer>
            <MapEntityDrawerHeader>{riverGauge.name}</MapEntityDrawerHeader>
          </DrawerStickyHeader.ContentContainer>
        </DrawerStickyHeader>

        <Stack gap={2} sx={{ p: 2, pt: 0 }}>
          <StatTable stats={stats} />

          {lastUpdated && (
            <Typography>
              {t('common.updated')}&nbsp;
              <b>{getTimePass(lastUpdated.toISOString())}</b>
            </Typography>
          )}

          {canShareOrCopy && (
            <Tooltip title={shareTooltipText}>
              <Button
                fullWidth
                color="inherit"
                variant="outlined"
                onClick={handleShare}
                size="large"
              >
                {t('common.share')}
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Box>

      <Stack>
        <DrawerStickyHeader
          scrollContainer={scrollElementRef?.current as HTMLElement}
          topOffset={stickyHeaderContentHeight * -1}
          stickyStyle={{ marginTop: stickyHeaderContentHeight }}
        >
          <Tabs {...tabsProps}>
            {tabProps.map((props) => (
              // maintaining tab width so they are consistent even if some are hidden
              <Tab
                key={props.key}
                {...props}
                sx={{ maxWidth: 1 / tabData.length }}
              />
            ))}
          </Tabs>
        </DrawerStickyHeader>

        {tabPanels}
      </Stack>
    </>
  );
};

import { Source, Layer, LayerProps } from 'shared/map-exports';
import useMapLayersState from 'state/useMapLayersState';
import { getWatchdutyStaticTileUrl } from './getWatchdutyStaticTileUrl';

const noCoverageFillStyle = (isDark: boolean): LayerProps => ({
  id: 'reporter-no-coverage-fill',
  type: 'fill',
  'source-layer': 'no_coverage',
  paint: {
    'fill-pattern': isDark
      ? 'DiagonalHatchPatternWhite'
      : 'DiagonalHatchPatternBlack',
    'fill-opacity': isDark ? 0.35 : 0.2,
  },
});

const coverageStrokeStyle = (
  sourceLayer: string,
  isDark: boolean,
): LayerProps => ({
  id: `reporter-${sourceLayer}-stroke`,
  type: 'line',
  'source-layer': sourceLayer,
  paint: {
    'line-color': isDark ? '#FFFFFF' : '#444444',
    'line-opacity': 0.2,
    'line-width': 1,
  },
});

const ReporterCoverageLayer = (): JSX.Element => {
  const { isDarkBaseLayer } = useMapLayersState();

  return (
    <Source url={getWatchdutyStaticTileUrl('region_coverage')} type="vector">
      <Layer {...noCoverageFillStyle(isDarkBaseLayer)} />
      <Layer {...coverageStrokeStyle('no_coverage', isDarkBaseLayer)} />
    </Source>
  );
};

export default ReporterCoverageLayer;

import { Capacitor } from '@capacitor/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import useIapState, { IAP_PRODUCT_IDS } from 'state/useIapState';
import { useMutation } from '@tanstack/react-query';
import { useSupporterStatus } from 'hooks/useSupporterStatus';
import * as Sentry from '@sentry/capacitor';
import { MEMBERSHIP_PLANS } from '../../constants';
import {
  getLatestOriginalTransactionId,
  syncAppPurchase,
} from './useIapInitializer.utils';
import { useAuthState } from '../../state';

export const useIapInitializer = (): void => {
  const { setProduct, setLoading } = useIapState();
  const { user } = useAuthState();
  const storeInitializedRef = useRef(false);
  const [iapTransactionId, setIapTransactionId] = useState<string | null>(null);
  const supporterStatus = useSupporterStatus();

  const { mutate: syncIapPurchase } = useMutation({
    mutationFn: syncAppPurchase,
    retry: 1,
  });

  useEffect(() => {
    if (
      supporterStatus?.requiresOriginalTransactionId &&
      !iapTransactionId &&
      Capacitor.getPlatform() === 'ios'
    ) {
      Sentry.captureMessage('no iap original transactionId', {
        extra: { user },
      });
    }
    if (supporterStatus?.requiresOriginalTransactionId && iapTransactionId) {
      syncIapPurchase(iapTransactionId);
    }
    // Suppress ESLint warning about missing dependencies: 'user'.
    // This effect is intended to capture the user object at the time
    // 'requiresOriginalTransactionId' changes. Since the user is
    // expected to be logged in at this point, we don't need to
    // re-run the effect when other user properties change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    iapTransactionId,
    supporterStatus?.requiresOriginalTransactionId,
    syncIapPurchase,
  ]);

  const onProductUpdate = useCallback(
    (product: CdvPurchase.Product) => {
      const price = product?.offers[0]?.pricingPhases[0]?.price;
      if (price) {
        setProduct(product.id, price);
      }
    },
    [setProduct],
  );

  const onDeviceReady = useCallback(() => {
    if (storeInitializedRef.current) return;

    setLoading(true);
    const purchasePlatform = CdvPurchase.Platform.APPLE_APPSTORE;
    const recurringProductType = CdvPurchase.ProductType.PAID_SUBSCRIPTION;

    CdvPurchase.store.ready(() => {
      setLoading(false);
      storeInitializedRef.current = true;

      const originalTransactionId = getLatestOriginalTransactionId(
        CdvPurchase.store.localTransactions,
      );

      if (originalTransactionId) {
        setIapTransactionId(originalTransactionId);
      }
    });

    CdvPurchase.store.register([
      {
        type: recurringProductType,
        id: IAP_PRODUCT_IDS[MEMBERSHIP_PLANS.yearly],
        platform: purchasePlatform,
      },
      {
        type: recurringProductType,
        id: IAP_PRODUCT_IDS[MEMBERSHIP_PLANS.proYearly],
        platform: purchasePlatform,
      },
      {
        type: recurringProductType,
        id: IAP_PRODUCT_IDS[MEMBERSHIP_PLANS.proMonthly],
        platform: purchasePlatform,
      },
    ]);

    CdvPurchase.store.when().productUpdated(onProductUpdate);

    if (import.meta.env.VITE_ENV !== 'production') {
      CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
    }

    CdvPurchase.store.initialize([purchasePlatform]);
  }, [onProductUpdate, setLoading]);

  useEffect(() => {
    const isIos = Capacitor.getPlatform() === 'ios';

    if (isIos) {
      document.addEventListener('deviceready', onDeviceReady);
    }

    return () => {
      if (isIos) {
        document.removeEventListener('deviceready', onDeviceReady);
      }
    };
  }, [onDeviceReady]);
};

import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { HeaderBar } from 'components/HeaderBar';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import PageLayout from 'components/PageLayout';
import RegionSelector from 'components/RegionSelector';
import { useNotifications } from 'hooks/useNotifications';
import { NotificationSetting } from 'shared/types';

type NotificationSettingsRegionAddProps = {
  onboarding?: boolean;
};

type ContentProps = {
  regionSettings: NotificationSetting[];
  onboarding?: boolean;
};

export const Content = (props: ContentProps): JSX.Element => {
  const { regionSettings, onboarding } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('notificationSettingsRegionAdd.title')}</title>
      </Helmet>
      <RegionSelector regionSettings={regionSettings} onboarding={onboarding} />
    </>
  );
};

const NotificationSettingsRegionAdd = (
  props: NotificationSettingsRegionAddProps,
): JSX.Element => {
  const { onboarding } = props;
  const { allNotificationSettings } = useNotifications();
  const { data, isLoading, isError } = allNotificationSettings;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <PageLayout>
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('common.loading')} action="back" />,
        }}
      </PageLayout>
    );
  }

  if (isError) {
    return (
      <PageLayout>
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {{
        content: <Content regionSettings={data} onboarding={onboarding} />,
        headerBar: (
          <HeaderBar
            action="back"
            title={t('notificationSettingsRegionAdd.title')}
          />
        ),
      }}
    </PageLayout>
  );
};

export default NotificationSettingsRegionAdd;

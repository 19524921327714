import { Chip, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type UserStatusChipType = 'internal' | 'members' | 'pro';

type UserStatusChipProps = {
  type: UserStatusChipType;
};

const translationKeys: Record<UserStatusChipType, string> = {
  internal: 'internalChip.label',
  members: 'membersChip.label',
  pro: 'proChip.label',
};

const UserStatusChip = ({
  type,
  ...props
}: UserStatusChipProps & ChipProps): JSX.Element => {
  const { t } = useTranslation();

  const chipTitle = t(translationKeys[type]);

  return (
    <Chip
      {...props}
      label={chipTitle}
      color={type === 'internal' ? 'error' : 'primary'}
      variant="watchduty"
      component="span"
      data-testid={`${type}-chip`}
    />
  );
};

export default UserStatusChip;

import { Stack, Typography } from '@mui/material';
import { InfoTooltip } from 'components/InfoTooltip';

type StatHeaderLabelProps = {
  label: string;
  tooltipText?: string;
};

export const StatHeaderLabel = (props: StatHeaderLabelProps): JSX.Element => {
  const { label, tooltipText } = props;

  if (!tooltipText) {
    return (
      <Typography
        variant="subtitle1"
        component="dt"
        color="textSecondary"
        textTransform="uppercase"
      >
        {label}
      </Typography>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={0.5}
      component="dt"
    >
      <Typography
        variant="subtitle1"
        color="textSecondary"
        textTransform="uppercase"
      >
        {label}
      </Typography>

      <InfoTooltip
        title={tooltipText}
        iconSx={{
          width: 14,
          height: 14,
          color: 'text.secondary',
          mt: '-2px !important',
        }}
      />
    </Stack>
  );
};

import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { rewriteFramesIntegration } from '@sentry/react';
import { getAppVersion } from './utils';

const isReleaseBuild = (): boolean => {
  const isWebProductionBuild =
    !Capacitor.isNativePlatform() && import.meta.env.PROD;
  const isNativeProductionBuild =
    Capacitor.isNativePlatform() && !Capacitor.DEBUG;

  return isWebProductionBuild || isNativeProductionBuild;
};

const getSentryReleaseName = async (): Promise<string> => {
  if (!isReleaseBuild()) {
    return 'pre-release-testing';
  }

  const sha = import.meta.env.VITE_GIT_SHA;
  const appVersion = await getAppVersion();

  return `${appVersion}-${sha}`;
};

const modifyStacktraceFilenames = (
  frame: Sentry.StackFrame,
): Sentry.StackFrame => {
  // This updates the stacktrace filenames to help point to our map files
  if (!frame.filename) {
    return frame;
  }
  // This prefix tells sentry parse the relative paths of our source mappings with this prefix + relative path
  // eslint-disable-next-line
  frame.filename = frame.filename.replace(/^.*\/assets\//, '/assets/');
  return frame;
};

export const initSentry = async (): Promise<void> => {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    return;
  }

  const release = await getSentryReleaseName();

  const isNative = Capacitor.isNativePlatform();

  Sentry.init(
    {
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      release,
      // https://github.com/getsentry/sentry-capacitor/issues/63
      enableNative: isNative,
      integrations: [
        rewriteFramesIntegration({ iteratee: modifyStacktraceFilenames }),
      ],
      dist: '1', // we only vary release, not the dist. This matches the dist for source map bundles we upload,
      enableCaptureFailedRequests: false,
    },
    // Forward the init method from @sentry/react
    SentryReact.init,
  );
  Sentry.setTag('isNative', isNative);
};

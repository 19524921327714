import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useAuthState } from 'state';
import { SupportUsBanner } from 'components/SupportUsBanner';
import useSearchDrawerState from 'state/useSearchDrawerState';
import { MAP_DOM_ELEMENT_ID } from '../../../constants';
import { getMapDialogCommonStyles } from '../utils';
import { useSupportUsDialog } from './useSupportUsDialog';
import { useMapEntityDrawerState } from '../MapEntityDrawer';

const useStyles = makeStyles<{
  isDrawerEntitySelected: boolean;
  isSearchDrawerOpen: boolean;
  interactive: boolean;
  minSnapPct: number;
}>()(
  (
    theme,
    { isDrawerEntitySelected, isSearchDrawerOpen, interactive, minSnapPct },
  ) =>
    getMapDialogCommonStyles(theme, {
      isDrawerEntitySelected,
      isSearchDrawerOpen,
      interactive,
      minWidth: 343,
      minSnapPct,
    }),
);

export const SupportUsDialog = (): JSX.Element => {
  const { open: isSearchDrawerOpen } = useSearchDrawerState();
  const {
    permissions: { canReport },
  } = useAuthState();
  const { open: isDrawerEntitySelected, minSnapPct } =
    useMapEntityDrawerState();
  const { classes } = useStyles({
    isDrawerEntitySelected,
    isSearchDrawerOpen,
    interactive: canReport,
    minSnapPct,
  });
  const { open, handleClose } = useSupportUsDialog();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root,
        container: classes.container,
        paper: classes.paper,
      }}
      hideBackdrop
      container={(): HTMLElement | null =>
        document.getElementById(MAP_DOM_ELEMENT_ID)
      }
    >
      <SupportUsBanner onClose={handleClose} />
    </Dialog>
  );
};

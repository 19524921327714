import {
  ActionRiverGaugeIcon,
  DefaultRiverGaugeIcon,
  MajorRiverGaugeIcon,
  MinorRiverGaugeIcon,
  ModerateRiverGaugeIcon,
  NormalRiverGaugeIcon,
  OfflineRiverGaugeIcon,
} from 'components/Map/Icons';
import { LatLng } from 'shared/types';

export const FLOOD_CATEGORIES = {
  major: 'major',
  moderate: 'moderate',
  minor: 'minor',
  action: 'action',
  none: 'none',
} as const;

export const FLOOD_CATEGORY_ICON_NAMES: Record<
  FloodCategory | 'default' | 'offline',
  string
> = {
  major: MajorRiverGaugeIcon.name,
  moderate: ModerateRiverGaugeIcon.name,
  minor: MinorRiverGaugeIcon.name,
  action: ActionRiverGaugeIcon.name,
  none: NormalRiverGaugeIcon.name,
  default: DefaultRiverGaugeIcon.name,
  offline: OfflineRiverGaugeIcon.name,
};

export type FloodCategory =
  (typeof FLOOD_CATEGORIES)[keyof typeof FLOOD_CATEGORIES];

export type FloodCategoryLevel = {
  category: FloodCategory;
  level: number;
};

export type FloodImpact = {
  level: number;
  statement: string;
};

export type RiverGaugeSummary = {
  id: number;
  name: string;
  units: {
    level: string;
    flow: string;
  };
};

export type RiverGauge = {
  id: number;
  name: string;
  usgsId: string;
  noaaLid: string;
  floodCategories: FloodCategoryLevel[];
  floodImpacts: FloodImpact[];
  units: {
    level: string;
    flow: string;
  };
  location: LatLng;
};

export type RiverGaugeObservation = {
  timestamp: Date;
  flow: number;
  level: number;
};

export type AlertThreshold = {
  weatherStationType: string;
  level: number;
};

export type RiverGaugeAlert = {
  id: number;
  station: RiverGaugeSummary;
  alertThresholdValue: AlertThreshold;
  resetThresholdValue: AlertThreshold;
  isAlertActive: boolean;
};

import { LocalNotifications } from '@capacitor/local-notifications';
import {
  PushNotificationSchema,
  PushNotifications,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { MAX_CACHE_MS } from '../../state/useCacheState';
import { WatchDutyPushData } from './initPushNotifications.types';

// Android push notification importance decides how the notification is displayed (heads-up notification with sound)
// @see https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
const ANDROID_NOTIF_IMPORTANCE = 4;
const ANDROID_NOTIF_CHANNEL_ID = 'incidents-reports';
const ANDROID_NOTIF_SOUND = 'notification.wav';

/**
 * FCM can send silent pushes. This determines if this is a real, visible notification
 * @returns
 */
export const isVisiblePushNotification = (
  notif: PushNotificationSchema,
): boolean => Boolean(notif.title) || Boolean(notif.body);

export const isPushNotificationsEnabled = async (): Promise<boolean> => {
  // I'm not sure this is needed, disabled for the sake of e2e tests on iOS simulator
  // if (import.meta.env.VITE_DEBUG_PUSH_TOKEN) {
  //   return true;
  // }

  if (!Capacitor.isNativePlatform()) {
    return false;
  }

  if (!Capacitor.isPluginAvailable('PushNotifications')) {
    return false;
  }

  try {
    const result = await PushNotifications.checkPermissions();
    return result.receive === 'granted';
  } catch (e) {
    return false;
  }
};

/**
 * Android doesn't show a notification when the app is in the foreground, so we'll show our own.
 * We schedule a LocalNotification 1 second later since Capacitor for Android doesn't show anything in this case.
 *
 * @see:
 * - https://github.com/ionic-team/capacitor/issues/2261#issuecomment-647267061
 * - https://github.com/ionic-team/capacitor-plugins/issues/234
 */
export const replayAndroidPushInFocus = (
  notification: PushNotificationSchema & { channelId?: string },
): void => {
  const {
    body,
    channelId = ANDROID_NOTIF_CHANNEL_ID,
    data,
    title,
  } = notification;
  const epoch = new Date().getTime();

  LocalNotifications.schedule({
    notifications: [
      {
        title: title as string,
        body: body as string,
        // 32-bit int - Value should be between -2147483648 and 2147483647 inclusive
        id: Math.round(epoch / 1000),
        schedule: {
          at: new Date(epoch + 1000),
          // Allow this notification to fire while in Doze Only available for Android 23+. Note that these notifications can only fire once per 9 minutes, per app.
          // @see https://capacitorjs.com/docs/apis/local-notifications#doze
          allowWhileIdle: true,
        },
        extra: data,
        channelId,
        sound: ANDROID_NOTIF_SOUND,
      },
    ],
  });
};

/**
 * There is horrible behavior here where on Android, when the app is
 * in focus, the data comes over via the `extra` key instead of the `data`
 * key.  this code handles these differences
 */
export const getNotificationData = (
  notification: PushNotificationSchema,
): WatchDutyPushData => {
  const {
    data: { object = '', objectType = '' } = {},
    // @ts-expect-error
    extra: {
      object: androidObject = '',
      objectType: androidObjectType = '',
    } = {},
  } = notification;

  const dataString = object || androidObject;
  const type = objectType || androidObjectType;
  const data = dataString ? JSON.parse(dataString) : {};

  return { object: data, objectType: type };
};

export const setCacheBusterTsFromNotifications = async (
  setCacheBuster: (ts: number | null) => void,
): Promise<void> => {
  let response;
  try {
    response = await PushNotifications.getDeliveredNotifications();
  } catch (e) {
    // we expect this to fail for users that haven't enabled push notifications
    return;
  }

  if (!response.notifications || !response.notifications.length) return;

  const lastNotification = response.notifications[0];
  const { object } = getNotificationData(lastNotification);
  const newDate =
    'date_modified' in object && object.date_modified
      ? new Date(object.date_modified)
      : null;
  if (!newDate) return;

  // and only set it if its within the useable window - otherwise it will just cause re-renders downstream
  const nowMs = new Date().getTime();
  const ts = newDate.getTime();
  if (nowMs - ts > MAX_CACHE_MS) return;

  setCacheBuster(ts);
};

export const initAndroidPushChannel = async (): Promise<void> => {
  // Used when app is the background/closed
  await PushNotifications.createChannel({
    id: ANDROID_NOTIF_CHANNEL_ID,
    name: 'Incidents & Reports',
    sound: ANDROID_NOTIF_SOUND,
    importance: ANDROID_NOTIF_IMPORTANCE,
  });

  // Used when app is in the foreground
  await LocalNotifications.createChannel({
    id: ANDROID_NOTIF_CHANNEL_ID,
    name: 'Incidents & Reports',
    sound: ANDROID_NOTIF_SOUND,
    importance: ANDROID_NOTIF_IMPORTANCE,
  });
};

export const getRouteForPushData = (
  pushData: WatchDutyPushData,
): string | null => {
  if (pushData.objectType === 'geo_event' || pushData.objectType === 'report') {
    return `/i/${pushData.object.geo_event_id}`;
  }

  if (
    pushData.objectType === 'weather_station' &&
    pushData.object.weather_station_type === 'waterLevel'
  ) {
    return `/gauge/${pushData.object.weather_station_id}`;
  }

  return null;
};

import { Alert, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ChartLoadingGuardProps = {
  children: ReactNode;
  isLoading: boolean;
  error?: Error | null;
};

export const ChartLoadingGuard = (
  props: ChartLoadingGuardProps,
): JSX.Element | ReactNode => {
  const { children, isLoading, error } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return <Skeleton variant="rectangular" height={300} />;
  }

  if (error) {
    return (
      <Alert severity="error">
        <Typography>
          {t('riverGauges.weatherLevelsChart.errorMessage')}
        </Typography>
      </Alert>
    );
  }

  return children;
};

import Helmet from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { HeaderBar } from 'components/HeaderBar';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import PageLayout from 'components/PageLayout';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import { NotificationsSubscriptionsEditor } from 'components/NotificationsSubscriptionsEditor';
import { useRiverGaugeAlerts } from 'features/riverGauges/hooks/useRiverGaugeAlerts';
import { useRiverGaugesEnabled } from 'features/riverGauges/hooks/useRiverGaugesEnabled';
import { Button, Typography } from '@mui/material';
import { DownState } from 'components/DownState';
import TurnOnNotificationsIcon from 'assets/turn_on_notifications.svg?react';
import { useLocation } from 'react-router-dom';
import { openNativeAppSettings } from 'shared/utils';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('notifications.notifications')}</title>
      </Helmet>
      <NotificationsSubscriptionsEditor />
    </>
  );
};

const NotificationSettings = (): JSX.Element => {
  const { t } = useTranslation();
  const { isPushEnabled, pushToken } = useNotificationsSettings();
  const riverGaugesEnabled = useRiverGaugesEnabled();
  // Fetch river gauge alerts as soon as the page loads
  useRiverGaugeAlerts({ enabled: riverGaugesEnabled });
  const location = useLocation();

  const returnRoute = location.state?.fromRoute || '/';

  // add `&& false` for local dev
  if (!isPushEnabled) {
    return (
      <PageLayout>
        {{
          content: (
            <DownState
              states={[
                {
                  renderImage: (imageProps) => (
                    <TurnOnNotificationsIcon {...imageProps} />
                  ),
                  title: t('notifications.turnOn.title'),
                  description: (
                    <>
                      <Typography>
                        <Trans i18nKey="notifications.turnOn.description">
                          Never miss important updates in your area by
                          <b>enabling notifications</b>.
                        </Trans>
                      </Typography>
                      <Typography>{t('notifications.counties')}</Typography>
                    </>
                  ),
                  Button: (
                    <Button
                      fullWidth
                      size="large"
                      onClick={openNativeAppSettings}
                    >
                      {t('notifications.turnOn.title')}
                    </Button>
                  ),
                  fixedButton: true,
                  containerProps: { sx: { px: 2, pt: 6 } },
                },
              ]}
            />
          ),
          headerBar: <HeaderBar title="Notifications" action={returnRoute} />,
        }}
      </PageLayout>
    );
  }

  if (!pushToken) {
    return (
      <PageLayout>
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar action={returnRoute} />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar
            action={returnRoute}
            title={t('notifications.notifications')}
          />
        ),
      }}
    </PageLayout>
  );
};

export default NotificationSettings;

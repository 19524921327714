import { MapLayerProps } from 'components/Map/types';
import { Layer } from 'shared/map-exports';
import { usePoisState } from 'state/usePoisState';
import { useCallback } from 'react';
import {
  getSelectedPatternStyle,
  getSelectedStrokeStyle,
} from 'components/Map/utils';
import addVisible from 'shared/addVisible';
import {
  ELECTRIC_RETAIL_LAYER_ID,
  ELECTRIC_RETAIL_MAPTILES_DIRECTORY,
  ELECTRIC_RETAIL_SOURCE_DATA_LAYER,
  MIN_ZOOM_VISIBILITY,
} from './constants';
import { layerFillStyle, layerStrokeStyle } from './layerStyles';
import { MapboxFeature, useMapLayerEvents } from '../useMapLayerEvents';
import ElectricRetailDialogContent from './DialogContent';
import VisibilityToggledSource from '../VisibilityToggledSource';
import { getWatchdutyStaticTileUrl } from '../getWatchdutyStaticTileUrl';

type ElectricRetailService = {
  OBJECTID: string;
  NAME: string;
  CUSTOMERS: number;
};

const ElectricRetailServiceLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  useMapLayerEvents({
    layerId: ELECTRIC_RETAIL_LAYER_ID,
    onClick: useCallback(
      (features: MapboxFeature<ElectricRetailService>[]) => {
        for (const feature of features) {
          const electricRetailService: ElectricRetailService =
            feature.properties;
          setSelectedPoi({
            id: electricRetailService.OBJECTID,
            type: 'electricRetail',
            PoiDialogContent: () => (
              <ElectricRetailDialogContent
                customers={electricRetailService.CUSTOMERS}
                name={electricRetailService.NAME}
              />
            ),
          });
        }
      },
      [setSelectedPoi],
    ),
  });

  const selectedId =
    selectedPoi?.type === 'electricRetail' ? selectedPoi.id! : '';

  const selectedFillStyle = getSelectedPatternStyle({
    idKey: 'OBJECTID',
    selectedId,
    otherLayerProps: {
      minzoom: MIN_ZOOM_VISIBILITY,
      'source-layer': ELECTRIC_RETAIL_SOURCE_DATA_LAYER,
    },
  });

  const selectedStrokeStyle = getSelectedStrokeStyle({
    idKey: 'OBJECTID',
    selectedId,
    otherLayerProps: {
      minzoom: MIN_ZOOM_VISIBILITY,
      'source-layer': ELECTRIC_RETAIL_SOURCE_DATA_LAYER,
    },
  });

  return (
    <VisibilityToggledSource
      url={getWatchdutyStaticTileUrl(ELECTRIC_RETAIL_MAPTILES_DIRECTORY)}
      visible={visible}
    >
      <Layer
        id={ELECTRIC_RETAIL_LAYER_ID}
        {...addVisible(layerFillStyle, visible)}
      />
      <Layer {...addVisible(layerStrokeStyle, visible)} />
      <Layer {...addVisible(selectedFillStyle, visible)} />
      <Layer {...addVisible(selectedStrokeStyle, visible)} />
    </VisibilityToggledSource>
  );
};

export default ElectricRetailServiceLayer;

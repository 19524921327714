import { ReactNode } from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

type OnboardingLayoutProps = {
  title: string;
  children: ReactNode;
  footerText?: string | ReactNode;
  buttonTitle?: string;
  onClick?: () => void;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url("/images/app-background.png")`,
    backgroundSize: '100% 100%',
    overflow: 'hidden',
    display: 'flex',
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: `max(env(safe-area-inset-left), 16px)`,
    paddingRight: `max(env(safe-area-inset-right), 16px)`,
    paddingTop: `max(env(safe-area-inset-top), 16px)`,
    paddingBottom: `max(env(safe-area-inset-bottom), 16px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
  },
}));

const OnboardingLayout = (props: OnboardingLayoutProps): JSX.Element => {
  const { title, children, footerText, buttonTitle, onClick } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="center"
              data-testid="onboarding-title"
            >
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {!!footerText && (
            <Grid item xs={12} sx={{ color: 'common.white' }}>
              {footerText}
            </Grid>
          )}

          {!!buttonTitle && (
            <Grid item xs={12}>
              <Button
                fullWidth
                size="large"
                onClick={onClick}
                data-testid="onboarding-button"
              >
                {buttonTitle}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default OnboardingLayout;

import { FeatureCollection, Point, Polygon } from 'geojson';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Source, Map } from 'shared/map-exports';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthState } from 'state';
import { useAlertCameraPlayerState } from 'hooks/useAlertCameraPlayer';
import { useAlertCameras } from 'hooks/useAlertCameras';
import addVisible from 'shared/addVisible';
import { MapboxFeature, useMapLayerEvents } from '../useMapLayerEvents';
import { useCenterMap } from '../../../IncidentsMap/CenterMap';
import {
  GenericCameraFeatureProperties,
  CameraGeoJson,
} from './AlertCamerasLayer.types';
import {
  generateViewshedGeojson,
  getAllCameraLabelsGeoJson,
  getCameraFromGroup,
  getCameraIdFromFeature,
  noop,
  toGeojsonFeatures,
} from './AlertCamerasLayer.utils';
import {
  PTZ_SOURCE_ID,
  PTZ_ARROWS_SOURCE_ID,
  STATIONARY_SOURCE_ID,
  PTZ_ICONS_LAYER_ID,
  ALL_LABELS_SOURCE_ID,
  ALL_LABELS_LAYER_ID,
  PTZ_ARROW_ICONS_LAYER_ID,
  STATIONARY_ICONS_LAYER_ID,
  PTZ_VIEWSHED_LAYER_FILL_ID,
  PTZ_VIEWSHED_LAYER_STROKE_ID,
  STATIONARY_VIEWSHED_SOURCE_ID,
  PTZ_VIEWSHED_SOURCE_ID,
  STATIONARY_VIEWSHED_LAYER_FILL_ID,
  STATIONARY_VIEWSHED_LAYER_STROKE_ID,
  VIEWSHED_FILL_OPACITY,
  VIEWSHED_STROKE_OPACITY,
  VIEWSHED_FILL_OPACITY_SELECTED,
  VIEWSHED_STROKE_OPACITY_SELECTED,
} from './AlertCamerasLayer.constants';
import {
  getArrowIconStyles,
  getBaseIconStyles,
  getIconImageLayout,
  getPaintOpacityForActiveId,
  getViewshedFillStyles,
  getViewshedStrokeStyles,
  labelFewNamesStyles,
  labelManyNamesStyles,
} from './AlertCamerasLayer.styles';

type CamerasLayerProps = {
  visible: boolean;
  interactive?: boolean;
  withLabels?: boolean;
  withRealtimeUpdates?: boolean;
};

export const AlertCamerasLayer = (props: CamerasLayerProps): JSX.Element => {
  const {
    visible,
    interactive = true,
    withLabels = false,
    withRealtimeUpdates = true,
  } = props;
  const { alertCameraGroups = [], alertCameras = [] } = useAlertCameras({
    enabled: visible,
    withRealtimeUpdates,
  });
  const { timelapseFrame, playerStatus } = useAlertCameraPlayerState();
  const history = useHistory();
  const { cameraId: activeCameraId } = useParams<{ cameraId?: string }>();
  const {
    permissions: { isInternalUser },
  } = useAuthState();
  const [hoveredCameraId, setHoveredCameraId] = useState<string | null>(null);

  // On mobile, tapping a camera also fires the hover event, which sets
  // `hoveredCameraId`. On desktop, the camera viewshed should show a newly-hovered
  // camera's viewshed even if the timelapse player is playing, so the `hoveredCameraId`
  // takes precendence in viewshed display.
  //
  // To avoid `hoveredCameraId` persisting and showing the "live" viewshed of a camera
  // after timelapse playback has initiated, reset it whenever the player status changes
  // to "playing".
  useEffect(() => {
    if (playerStatus === 'playingTimelapse') {
      setHoveredCameraId(null);
    }
  }, [playerStatus]);

  const handleClickIcon = useCallback(
    (features: MapboxFeature<GenericCameraFeatureProperties>[]) => {
      const featureProperties = features[0].properties;
      const cameraId = getCameraIdFromFeature(featureProperties);
      history.push(`/camera/${cameraId}`);
    },
    [history],
  );

  const handleHover = useCallback(
    (features: MapboxFeature<GenericCameraFeatureProperties>[], map: Map) => {
      const { properties } = features[0];
      if (activeCameraId === properties.id) {
        return;
      }

      const currentCameraGroupHover = alertCameraGroups.find((cameraGroup) =>
        getCameraFromGroup(cameraGroup, properties.id!),
      );
      currentCameraGroupHover && setHoveredCameraId(currentCameraGroupHover.id);

      const selectedCameraGroupIds =
        'cameraGroupId' in properties
          ? [properties.id, properties.cameraGroupId]
          : [properties.id];
      const selectedChildCameraIds =
        'childCameraIds' in properties
          ? JSON.parse(properties.childCameraIds as unknown as string)
          : [];

      const selectedIds = [
        ...selectedCameraGroupIds,
        ...selectedChildCameraIds,
      ];

      [
        PTZ_ICONS_LAYER_ID,
        PTZ_ARROW_ICONS_LAYER_ID,
        STATIONARY_ICONS_LAYER_ID,
      ].forEach((layerId) => {
        map.setLayoutProperty(layerId, 'icon-image', [
          'case',
          ['in', ['get', 'id'], ['literal', selectedIds]],
          ['get', 'iconFocused'],
          ['get', 'icon'],
        ]);
      });

      [STATIONARY_VIEWSHED_LAYER_FILL_ID, PTZ_VIEWSHED_LAYER_FILL_ID].forEach(
        (layerId) => {
          map.setPaintProperty(layerId, 'fill-opacity', [
            'case',
            ['in', ['get', 'id'], ['literal', selectedIds]],
            VIEWSHED_FILL_OPACITY_SELECTED,
            VIEWSHED_FILL_OPACITY,
          ]);
        },
      );

      [
        STATIONARY_VIEWSHED_LAYER_STROKE_ID,
        PTZ_VIEWSHED_LAYER_STROKE_ID,
      ].forEach((layerId) => {
        map.setPaintProperty(layerId, 'line-opacity', [
          'case',
          ['in', ['get', 'id'], ['literal', selectedIds]],
          VIEWSHED_STROKE_OPACITY_SELECTED,
          VIEWSHED_STROKE_OPACITY,
        ]);
      });
    },
    [activeCameraId, alertCameraGroups],
  );

  const handleHoverOff = useCallback(
    (map: Map) => {
      setHoveredCameraId(null);

      [
        PTZ_ICONS_LAYER_ID,
        PTZ_ARROW_ICONS_LAYER_ID,
        STATIONARY_ICONS_LAYER_ID,
      ].forEach((layerId) => {
        map.setLayoutProperty(
          layerId,
          'icon-image',
          getIconImageLayout(activeCameraId),
        );
      });

      [STATIONARY_VIEWSHED_LAYER_FILL_ID, PTZ_VIEWSHED_LAYER_FILL_ID].forEach(
        (layerId) => {
          map.setPaintProperty(
            layerId,
            'fill-opacity',
            getPaintOpacityForActiveId(activeCameraId),
          );
        },
      );

      [
        STATIONARY_VIEWSHED_LAYER_STROKE_ID,
        PTZ_VIEWSHED_LAYER_STROKE_ID,
      ].forEach((layerId) => {
        map.setPaintProperty(
          layerId,
          'line-opacity',
          getPaintOpacityForActiveId(activeCameraId),
        );
      });
    },
    [activeCameraId],
  );

  useMapLayerEvents<GenericCameraFeatureProperties>({
    layerId: PTZ_ICONS_LAYER_ID,
    onClick: interactive ? handleClickIcon : noop,
    onHover: interactive ? handleHover : undefined,
    onHoverOff: interactive ? handleHoverOff : undefined,
  });
  useMapLayerEvents<GenericCameraFeatureProperties>({
    layerId: PTZ_ARROW_ICONS_LAYER_ID,
    onClick: interactive ? handleClickIcon : noop,
    onHover: interactive ? handleHover : undefined,
    onHoverOff: interactive ? handleHoverOff : undefined,
  });
  useMapLayerEvents<GenericCameraFeatureProperties>({
    layerId: STATIONARY_ICONS_LAYER_ID,
    onClick: interactive ? handleClickIcon : noop,
    onHover: interactive ? handleHover : undefined,
    onHoverOff: interactive ? handleHoverOff : undefined,
  });

  // do not modify the geojson based on selectedState or it will have to re-render.
  const geojsonPtz = useMemo(() => {
    const symbolFeatures = alertCameraGroups
      .filter((camera) => camera.hasPtz)
      .map((camera) => toGeojsonFeatures(camera))
      .reduce(
        (acc, current) => {
          const { baseIcons, arrowIcons } = current;
          return {
            baseIcons: acc.baseIcons.concat(baseIcons),
            arrowIcons: acc.arrowIcons.concat(arrowIcons),
          };
        },
        { baseIcons: [], arrowIcons: [] },
      );
    return {
      baseIcons: {
        type: 'FeatureCollection',
        features: symbolFeatures.baseIcons,
      },
      arrowIcons: {
        type: 'FeatureCollection',
        features: symbolFeatures.arrowIcons,
      },
    };
  }, [alertCameraGroups]);

  const geojsonStationary = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: alertCameraGroups
        .filter((camera) => !camera.hasPtz)
        .map((camera) => toGeojsonFeatures(camera))
        .reduce((acc, current) => {
          const { baseIcons } = current;
          return acc.concat(baseIcons);
        }, [] as CameraGeoJson[]),
    }),
    [alertCameraGroups],
  );

  // This geojson will be built after clicking or hovering on a camera
  const geojsonViewshedStationary: FeatureCollection<
    Point | Polygon,
    GenericCameraFeatureProperties
  > = useMemo(() => {
    return generateViewshedGeojson(
      alertCameraGroups,
      'stationary',
      activeCameraId,
      hoveredCameraId,
      timelapseFrame,
    );
  }, [hoveredCameraId, activeCameraId, alertCameraGroups, timelapseFrame]);

  const geojsonViewshedPtz: FeatureCollection<
    Point | Polygon,
    GenericCameraFeatureProperties
  > = useMemo(() => {
    return generateViewshedGeojson(
      alertCameraGroups,
      'ptz',
      activeCameraId,
      hoveredCameraId,
      timelapseFrame,
    );
  }, [activeCameraId, alertCameraGroups, hoveredCameraId, timelapseFrame]);

  const activeCameraLatLng = useMemo(() => {
    if (!activeCameraId) {
      return null;
    }

    const activeCamera = alertCameras.find(
      (camera) => camera.id === activeCameraId,
    );
    if (!activeCamera) {
      return null;
    }

    return activeCamera.latlng;
  }, [activeCameraId, alertCameras]);

  useCenterMap({
    latLng: activeCameraLatLng,
    drawerIsOpen: true,
    defaultSnapPointPct: 0.7,
    zoomIn: true,
  });

  const allLabelsGeoJson = useMemo(() => {
    const features = [
      ...geojsonStationary.features,
      ...geojsonPtz.baseIcons.features,
    ];

    return getAllCameraLabelsGeoJson(features);
  }, [geojsonStationary, geojsonPtz.baseIcons]);

  return (
    <>
      <Source id={STATIONARY_SOURCE_ID} type="geojson" data={geojsonStationary}>
        <Layer
          id={STATIONARY_ICONS_LAYER_ID}
          {...addVisible(
            getBaseIconStyles('stationary', isInternalUser, activeCameraId),
            visible,
          )}
        />
      </Source>
      <Source id={PTZ_SOURCE_ID} type="geojson" data={geojsonPtz.baseIcons}>
        <Layer
          id={PTZ_ICONS_LAYER_ID}
          {...addVisible(
            getBaseIconStyles('ptz', isInternalUser, activeCameraId),
            visible,
          )}
        />
      </Source>
      {withLabels && (
        <Source
          id={ALL_LABELS_SOURCE_ID}
          type="geojson"
          data={allLabelsGeoJson}
        >
          <Layer
            id={`${ALL_LABELS_LAYER_ID}-few`}
            {...addVisible(labelFewNamesStyles, visible)}
          />
          <Layer
            id={`${ALL_LABELS_LAYER_ID}-many`}
            {...addVisible(labelManyNamesStyles, visible)}
          />
        </Source>
      )}
      <Source
        id={PTZ_ARROWS_SOURCE_ID}
        type="geojson"
        data={geojsonPtz.arrowIcons}
      >
        <Layer
          id={PTZ_ARROW_ICONS_LAYER_ID}
          {...addVisible(
            getArrowIconStyles('ptz', isInternalUser, activeCameraId),
            visible,
          )}
        />
      </Source>
      <Source
        id={STATIONARY_VIEWSHED_SOURCE_ID}
        type="geojson"
        data={geojsonViewshedStationary}
      >
        <Layer
          id={STATIONARY_VIEWSHED_LAYER_FILL_ID}
          {...addVisible(
            getViewshedFillStyles('stationary', isInternalUser, activeCameraId),
            visible,
          )}
        />
        <Layer
          id={STATIONARY_VIEWSHED_LAYER_STROKE_ID}
          {...addVisible(
            getViewshedStrokeStyles(
              'stationary',
              isInternalUser,
              activeCameraId,
            ),
            visible,
          )}
        />
      </Source>
      <Source
        id={PTZ_VIEWSHED_SOURCE_ID}
        type="geojson"
        data={geojsonViewshedPtz}
      >
        <Layer
          id={PTZ_VIEWSHED_LAYER_FILL_ID}
          {...addVisible(
            getViewshedFillStyles('ptz', isInternalUser, activeCameraId),
            visible,
          )}
        />
        <Layer
          id={PTZ_VIEWSHED_LAYER_STROKE_ID}
          {...addVisible(
            getViewshedStrokeStyles('ptz', isInternalUser, activeCameraId),
            visible,
          )}
        />
      </Source>
    </>
  );
};

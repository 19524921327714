import { useQuery } from '@tanstack/react-query';
import { RouteComponentProps } from 'react-router-dom';
import { API } from 'api';
import { HeaderBar } from 'components/HeaderBar';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import PageLayout from 'components/PageLayout';
import EmbedGeoEvent from './EmbedGeoEvent';

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvent = (geoEventId: string) =>
  API.get(`geo_events/${geoEventId}`);

const WildFireGeoEventEmbed = (
  props: RouteComponentProps<{ geoEventId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { geoEventId },
    },
  } = props;

  const query = useQuery({
    queryKey: ['geoEvent', geoEventId],
    queryFn: () => fetchGeoEvent(geoEventId),
  });

  const { data, isLoading, isError } = query;

  if (isLoading) {
    return (
      <PageLayout data-cname="WildfireGeoEventEmbed">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title="Loading..." action="back" />,
        }}
      </PageLayout>
    );
  }

  if (isError || !data) {
    return (
      <PageLayout data-cname="WildfireGeoEventEmbed">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title="Error" action="back" />,
        }}
      </PageLayout>
    );
  }

  const { data: geoEvent } = data;
  return (
    <PageLayout isLoading={isLoading} data-cname="WildfireGeoEventEmbed">
      {{
        content: <EmbedGeoEvent geoEvent={geoEvent} />,
        headerBar: <HeaderBar />,
      }}
    </PageLayout>
  );
};

export default WildFireGeoEventEmbed;

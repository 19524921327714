import { ReactNode, SyntheticEvent, useState } from 'react';
import { SxProps, TabProps, TabsProps } from '@mui/material';
import { a11yProps, TabPanel } from '../../components/TabPanel';

type TabData = {
  tabId: string;
  label: string | JSX.Element;
  enabled: boolean;
  content: ReactNode;
  panelSx?: SxProps;
};

type UseTabs = {
  tabsProps: TabsProps;
  tabProps: TabProps[];
  tabPanels: JSX.Element[];
  activeTabId: string;
};

export const useTabs = (props: {
  idPrefix: string;
  ariaLabel: string;
  initialTab?: string;
  tabData: TabData[];
}): UseTabs => {
  const { idPrefix, ariaLabel, tabData, initialTab } = props;
  const defaultTab = tabData.find((child) => child.enabled)?.tabId || '';
  const [activeTabId, setActiveTab] = useState(initialTab || defaultTab);

  // handle case where navigation to invalid or disabled tab
  if (!tabData.some((child) => child.tabId === activeTabId && child.enabled)) {
    setActiveTab(defaultTab);
  }

  const tabsProps = {
    variant: 'fullWidth' as const,
    'aria-label': ariaLabel,
    indicatorColor: 'primary' as const,
    value: activeTabId,
    onChange: (_: SyntheticEvent, newValue: string) => setActiveTab(newValue),
    TabIndicatorProps: { children: <span /> },
  };

  const enabledTabs = tabData.filter((child) => child.enabled);

  const tabProps = enabledTabs.map((child, index) => {
    return {
      key: child.tabId,
      value: child.tabId,
      label: child.label,
      ...a11yProps(idPrefix, index),
    };
  });

  const tabPanels = enabledTabs.map((child) => (
    <TabPanel
      key={child.tabId}
      index={child.tabId}
      value={activeTabId || ''}
      idPrefix={idPrefix}
      sx={child.panelSx}
    >
      {child.content}
    </TabPanel>
  ));

  return { tabsProps, tabProps, tabPanels, activeTabId };
};

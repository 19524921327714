import { Capacitor } from '@capacitor/core';
import { useMediaQuery, useTheme, Box, BoxProps } from '@mui/material';
import { CSSProperties, ReactNode, useRef, useEffect, useState } from 'react';
import Sticky from 'react-sticky-el';
import { makeStyles } from 'tss-react/mui';

type DrawerStickyHeaderProps = {
  scrollContainer?: string | HTMLElement;
  children: ReactNode;
  topOffset?: number;
  stickyStyle?: CSSProperties;
  onStickyChange?: (isFixed: boolean) => void;
  onStickyContentHeightChange?: (contentHeight: number) => void;
};

const useStyles = makeStyles()((theme) => ({
  stickyEl: {
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 8px)',
      bottom: 0,
      left: 4,
      zIndex: -1,
      boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
}));

export const DrawerStickyHeader = (
  props: DrawerStickyHeaderProps,
): JSX.Element => {
  const {
    scrollContainer,
    children,
    topOffset,
    stickyStyle,
    onStickyChange,
    onStickyContentHeightChange,
  } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const [isElementFixed, setIsElementFixed] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * Calculate content height when the header becomes fixed. This is necessary
     * because the height of the content can change dynamically after the
     * initial sticky state change (e.g., due to dynamic children).
     * Directly including the content height in the `onStickyChange` prop
     * callback was avoided as it can lead to issues when the content height
     * changes after the header is already fixed.
     */
    if (isElementFixed && onStickyContentHeightChange) {
      onStickyContentHeightChange(contentRef.current?.offsetHeight ?? 0);
    }
  }, [isElementFixed, onStickyContentHeightChange]);

  const handleOnFixedToggle = (isFixed: boolean): void => {
    setIsElementFixed(isFixed);
    if (onStickyChange) onStickyChange(isFixed);
  };

  return (
    <Sticky
      scrollElement={scrollContainer}
      stickyClassName={classes.stickyEl}
      stickyStyle={
        stickyStyle ?? {
          paddingTop: isLargeMediaQuery ? 8 : 0,
        }
      }
      onFixedToggle={handleOnFixedToggle}
      topOffset={topOffset}
    >
      <div ref={contentRef}>{children}</div>
    </Sticky>
  );
};

const ContentContainer = (props: BoxProps): JSX.Element => {
  const { children, sx = {}, ...restProps } = props;
  return (
    <Box
      sx={{
        px: 2,
        pb: 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};

DrawerStickyHeader.ContentContainer = ContentContainer;

import {
  Button,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useRef } from 'react';
import { differenceInDays, subDays } from 'date-fns';
import {
  RiverGauge,
  RiverGaugeAlert,
  RiverGaugeObservation,
} from '../../riverGauges.types';
import BlackButton from '../../../../components/BlackButton';
import { useBlocker } from '../../../../hooks/useBlocker';
import { useSnackbarState } from '../../../../state';
import { useRiverGaugeAlert } from '../../hooks/useRiverGaugeAlert';
import { useRiverGaugeObservations } from '../../hooks/useRiverGaugeObservations';
import { CategoryChips } from '../CategoryChips';
import { ChartLoadingGuard } from './ChartLoadingGuard';
import {
  WaterLevelsChartWrapper,
  useWaterLevelsChart,
} from '../../hooks/useWaterLevelsChart';
import { EchartsWrapper } from '../../../../libs/echarts/EchartsWrapper';

type RiverGaugeAlertFormProps = {
  riverGauge: RiverGauge;
  alert?: RiverGaugeAlert;
  fromRoute: string;
};

type FormValues = {
  height?: number | string;
};

const getNumberOfDaysReturned = (
  observations: RiverGaugeObservation[],
): number => {
  const firstObservation = observations.at(-1);

  if (!firstObservation) {
    return 1;
  }

  return differenceInDays(new Date(), firstObservation.timestamp);
};

export const RiverGaugeAlertForm = (
  props: RiverGaugeAlertFormProps,
): JSX.Element => {
  const { riverGauge, alert, fromRoute } = props;
  const observationsHistoryStart = subDays(new Date(), 365);
  const {
    observations,
    isLoading: isLoadingObservations,
    error: observationsError,
  } = useRiverGaugeObservations({
    id: riverGauge.id,
    start: observationsHistoryStart,
    end: new Date(),
  });
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const myHistory = useHistory();
  const expectedNavigationRef = useRef(false);
  const { saveRiverGaugeAlert, deleteRiverGaugeAlert } = useRiverGaugeAlert({
    riverGaugeAlertId: alert?.id,
  });

  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(
      Yup.object().shape({
        height: Yup.number()
          .typeError(t('riverGauges.alerts.inputs.height.required'))
          .min(0, t('riverGauges.alerts.inputs.height.min'))
          .required(t('riverGauges.alerts.inputs.height.required')),
      }),
    ),
    defaultValues: {
      // Default value set to empty string to avoid passing undefined and getting the following warning when running tests:
      // Warning: A component is changing an uncontrolled input to be controlled. This is likely
      // caused by the value changing from undefined to a defined value, which should not happen.
      // Decide between using a controlled or uncontrolled input element for the lifetime of the
      // component. More info: https://reactjs.org/link/controlled-components
      height: '',
    },
    values: {
      height: alert ? alert.alertThresholdValue.level : '',
    },
  });

  const waterLevelsChartOptions = useWaterLevelsChart({
    observations: observations || [],
    riverGauge,
  });

  const navigateBack = (): void => {
    myHistory.push(fromRoute);
  };

  const handleDeleteAlert = async (): Promise<void> => {
    if (!alert) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals,no-alert
    if (!confirm(t('riverGauges.alerts.deleteConfirmation'))) {
      return;
    }

    try {
      expectedNavigationRef.current = true;
      await deleteRiverGaugeAlert();
      navigateBack();
    } catch (error) {
      expectedNavigationRef.current = false;
      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    }
  };

  const handleSaveAlert: SubmitHandler<FormValues> = async (values) => {
    const level =
      typeof values.height === 'string'
        ? parseFloat(values.height)
        : values.height || 0;

    const data = {
      id: alert?.id,
      stationId: riverGauge.id,
      alertThresholdValue: {
        weatherStationType: 'waterLevel',
        level,
      },
    };

    try {
      expectedNavigationRef.current = true;
      await saveRiverGaugeAlert(data);
      navigateBack();
    } catch (finalError) {
      expectedNavigationRef.current = false;
      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    }
  };

  useBlocker({
    shouldBlockNavigation:
      !expectedNavigationRef.current && !!formState.dirtyFields.height,
    confirmationMessage: t('riverGauges.alerts.unsavedChangesMessage'),
  });

  const actionText = alert
    ? t('riverGauges.alerts.saveNotification')
    : t('riverGauges.alerts.addNotification');

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{riverGauge?.name}</Typography>

      <ChartLoadingGuard
        isLoading={isLoadingObservations}
        error={observationsError}
      >
        {waterLevelsChartOptions && observations && (
          <>
            <Typography component="h3" sx={{ fontWeight: 'bold' }}>
              {t('riverGauges.alerts.lastNDays', {
                days: getNumberOfDaysReturned(observations),
              })}
            </Typography>
            <WaterLevelsChartWrapper>
              <EchartsWrapper option={waterLevelsChartOptions} />
            </WaterLevelsChartWrapper>
            <CategoryChips
              categoryLevels={riverGauge.floodCategories}
              units={riverGauge.units.level}
            />
          </>
        )}
      </ChartLoadingGuard>

      <Typography>{t('riverGauges.alerts.section')}</Typography>
      <Controller
        control={control}
        name="height"
        render={({ field, fieldState }) => (
          <FormControl fullWidth>
            <TextField
              name="height"
              label={t('riverGauges.alerts.inputs.height.label')}
              error={!!fieldState.error}
              helperText={
                fieldState.error?.message ||
                t('riverGauges.alerts.inputs.height.helperText')
              }
              value={field.value}
              onChange={field.onChange}
              slotProps={{
                input: {
                  inputMode: 'numeric',
                  endAdornment: field.value ? (
                    <InputAdornment position="end">
                      {riverGauge.units.level}
                    </InputAdornment>
                  ) : null,
                },
                inputLabel: {
                  shrink: !!field.value,
                },
              }}
            />
          </FormControl>
        )}
      />

      {alert && (
        <BlackButton
          fullWidth
          size="large"
          disabled={formState.isSubmitting}
          onClick={handleSubmit(handleDeleteAlert)}
        >
          {t('riverGauges.alerts.deleteNotification')}
        </BlackButton>
      )}

      <Button
        fullWidth
        size="large"
        disabled={formState.isSubmitting}
        onClick={handleSubmit(handleSaveAlert)}
      >
        {actionText}
      </Button>
    </Stack>
  );
};

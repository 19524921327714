import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API } from 'api';
import { useAuthState } from 'state';
import { MembershipSource } from 'shared/types';

type HostedLink = {
  hostedLink?: string;
  iosSubscription?: boolean;
  dateActiveUntil: string;
  planCode: string;
  purchaseSource: MembershipSource;
};

type UseMembershipManagement = {
  hostedLink?: string;
  isIosSubscription: boolean;
  dateActiveUntil?: string;
  planCode?: string;
  purchaseSource?: MembershipSource;
  isLoading: boolean;
  isExpired: boolean;
};

const getMembershipManagement = (): Promise<AxiosResponse<HostedLink>> =>
  API.get('recurly_integration/hosted_management/');

export const useMembershipManagement = (): UseMembershipManagement => {
  const { hasActiveMembership } = useAuthState();

  const { data, isLoading } = useQuery({
    queryKey: ['management-link'],
    queryFn: getMembershipManagement,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: hasActiveMembership,
  });

  const hostedLink = data?.data?.hostedLink;
  const isIosSubscription = !!data?.data?.iosSubscription;
  const dateActiveUntil = data?.data?.dateActiveUntil;
  const planCode = data?.data?.planCode;
  const purchaseSource = data?.data?.purchaseSource;

  const isExpired = dateActiveUntil
    ? new Date(dateActiveUntil).getTime() < Date.now()
    : false;

  return {
    hostedLink,
    isIosSubscription,
    dateActiveUntil,
    planCode,
    purchaseSource,
    isLoading,
    isExpired,
  };
};

import i18n from 'i18next';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MapIcon from '@mui/icons-material/Map';
import PublicIcon from '@mui/icons-material/Public';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, Stack } from '@mui/material';
import { useInboxMessages } from 'hooks/useInboxMessages';
import { getResponsiveFontSize, isMobile } from 'shared/utils';
import { useAuthState } from 'state';
import { getPushTokenFromLocalStorage } from 'state/localStorage';
import { Language, setLanguage } from 'i18n/utils';
import DonateIcon from 'assets/donate.svg?react';
import VectorLogo from '../VectorLogo';
import TextLogo from '../TextLogo';
import DebugData from '../DebugData';
import {
  NOTIFICATIONS_REGIONS_QUERY_KEY,
  NOTIFICATIONS_SETTINGS_QUERY_KEY,
} from '../../constants';
import { persistedQueryClient } from '../CacheableQueryClientProvider';
import UserCard from './UserCard';
import GrayButton from '../GrayButton';

type DrawerNavProps = {
  open: boolean;
  toggleOpen: () => void;
};

const LANGUAGE_OPTIONS = [
  {
    value: 'en',
    transKey: 'drawerNav.language.values.en',
  },
  {
    value: 'es',
    transKey: 'drawerNav.language.values.es',
  },
  {
    value: 'tl',
    transKey: 'drawerNav.language.values.tl',
  },
  {
    value: 'vi',
    transKey: 'drawerNav.language.values.vi',
  },
  {
    value: 'zh',
    transKey: 'drawerNav.language.values.zh',
  },
];

const useStyles = makeStyles()((theme) => {
  const { spacing } = theme;

  return {
    menuLogoContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px',
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '22px',
    },
    menuLogo: {
      // hard-coding to prevent iOS Dynamic Type from resizing this
      width: 144, // 9rem
      marginLeft: 4,
    },
    menuItem: {
      display: 'flex',
      height: '48px',
      '& .MuiListItemText-root span': {
        fontSize: getResponsiveFontSize(theme.typography.pxToRem(16)),
        lineHeight: getResponsiveFontSize(theme.typography.pxToRem(19)),
        fontWeight: 500,
      },
      '& .MuiListItemIcon-root': {
        width: 24,
        minWidth: 24,
        marginRight: 27,
      },
    },
    menu: {
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
      paddingLeft: 'env(safe-area-inset-left)',
      width: '262px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'space-between',
      '& a': {
        textDecoration: 'none',
        color: 'black',
        fontWeight: 'bold',
      },
      '& a:hover': {
        color: '#000',
      },
    },
    badge: {
      marginRight: spacing(1),
    },
    languageItem: {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    footerContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
    },
    link: {
      textDecoration: 'underline !important',
      fontWeight: 'normal !important' as 'normal',
    },
  };
});

const DrawerNav = (props: DrawerNavProps): JSX.Element => {
  const { open, toggleOpen } = props;
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const {
    isAuthenticated,
    hasActiveMembership,
    user,
    permissions: { canModerate, isInternalUser },
  } = useAuthState();
  const { unreadMessages } = useInboxMessages();
  // We want the notifications page to fully refresh on any link from the leftnav. This allows updates of regions
  //  to propagate without disturbing the cache during normal user flows.
  // Not doing this on pageload because we toggle between the two views of edit and add counties.

  const clearNotificationSettingsCache = (): void => {
    const pushToken = getPushTokenFromLocalStorage();
    persistedQueryClient.invalidateQueries({
      queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY, pushToken],
    });
    persistedQueryClient.invalidateQueries({
      queryKey: [NOTIFICATIONS_REGIONS_QUERY_KEY],
    });
    toggleOpen();
  };

  const isMobileApp = isMobile();
  const showNotifications = isMobileApp;
  const showInbox = isMobileApp;
  const showAccount = isAuthenticated;
  // we want to see where people go with no pre-selection if they are not already members
  const donateLink = hasActiveMembership
    ? '/support_us/donate'
    : '/support_us/';

  return (
    <Drawer
      classes={{ paper: classes.menu }}
      anchor="left"
      open={open}
      onClose={toggleOpen}
      PaperProps={{ 'aria-label': t('drawerNav.label') }}
    >
      <Box className={classes.menuLogoContainer}>
        <VectorLogo size={28} />
        <TextLogo className={classes.menuLogo} />
      </Box>
      <List disablePadding>
        <Link to="/" className={classes.menuItem} onClick={toggleOpen}>
          <ListItemButton>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawerNav.map')} />
          </ListItemButton>
        </Link>

        {showInbox && (
          <Link to="/inbox" className={classes.menuItem} onClick={toggleOpen}>
            <ListItemButton>
              <ListItemIcon>
                <InboxOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t('drawerNav.inbox')} />
              <Badge
                badgeContent={unreadMessages}
                color="error"
                className={classes.badge}
              />
            </ListItemButton>
          </Link>
        )}

        <Link
          to={{ pathname: 'https://support.watchduty.org/' }}
          target="_blank"
          className={classes.menuItem}
          onClick={toggleOpen}
        >
          <ListItemButton>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawerNav.help')} />
          </ListItemButton>
        </Link>

        {!isInternalUser && (
          <ListItem>
            <ListItemText
              secondary={
                <Typography
                  role="heading"
                  variant="subtitle1"
                  color="secondary"
                >
                  {t('drawerNav.supportUs')}
                </Typography>
              }
            />
          </ListItem>
        )}

        {!isInternalUser && (
          <Link
            to="/support_us"
            className={classes.menuItem}
            onClick={toggleOpen}
          >
            <ListItemButton>
              <ListItemIcon>
                <StarIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('drawerNav.membership')} />
            </ListItemButton>
          </Link>
        )}

        {!isInternalUser && (
          <Link
            to={donateLink}
            className={classes.menuItem}
            onClick={toggleOpen}
          >
            <ListItemButton>
              <ListItemIcon sx={{ width: 24, height: 24, padding: '1px' }}>
                <DonateIcon />
              </ListItemIcon>
              <ListItemText primary={t('drawerNav.donate')} />
            </ListItemButton>
          </Link>
        )}

        {canModerate && (
          <>
            <ListItem>
              <ListItemText
                secondary={
                  <Typography
                    role="heading"
                    variant="subtitle1"
                    color="secondary"
                  >
                    {t('drawerNav.admin')}
                  </Typography>
                }
              />
            </ListItem>

            <Link
              to="/moderation"
              className={classes.menuItem}
              onClick={toggleOpen}
            >
              <ListItemButton>
                <ListItemIcon>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary={t('drawerNav.moderation')} />
              </ListItemButton>
            </Link>
          </>
        )}

        <ListItem>
          <ListItemText
            secondary={
              <Typography role="heading" variant="subtitle1" color="secondary">
                {t('drawerNav.settings')}
              </Typography>
            }
          />
        </ListItem>

        {showNotifications && (
          <Link
            to="/notifications"
            className={classes.menuItem}
            onClick={clearNotificationSettingsCache}
            aria-label={t('drawerNav.notifications')}
          >
            <ListItemButton>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary={t('drawerNav.notifications')} />
            </ListItemButton>
          </Link>
        )}

        <Link to="/places" className={classes.menuItem} onClick={toggleOpen}>
          <ListItemButton>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('drawerNav.places')} />
          </ListItemButton>
        </Link>

        <ListItem className={classes.languageItem}>
          <Select
            fullWidth
            value={i18n.resolvedLanguage}
            onChange={(e) => setLanguage(e.target.value as Language)}
            IconComponent={ExpandMoreIcon}
            renderValue={(value) => {
              const currentValue = LANGUAGE_OPTIONS.find(
                (el) => el.value === value,
              );
              return (
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <ListItemIcon sx={{ marginRight: '27px', minWidth: 0 }}>
                    <PublicIcon />
                  </ListItemIcon>
                  {currentValue && t(currentValue.transKey)}
                </Stack>
              );
            }}
            inputProps={{ 'aria-label': t('drawerNav.language.label') }}
            sx={{
              '.MuiInputBase-input': { padding: '10px 0' },
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
          >
            {LANGUAGE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.transKey)}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
      </List>

      <Box className={classes.footerContainer}>
        <List
          disablePadding
          sx={{ width: '100%', paddingBottom: isMobileApp ? 2 : 0 }}
        >
          {!isAuthenticated && (
            <ListItem>
              <GrayButton
                fullWidth
                component={Link}
                to="/login"
                className={cx(classes.menuItem)}
                onClick={toggleOpen}
                size="large"
                sx={{ textAlign: 'center' }}
              >
                <ListItemText primary={t('drawerNav.login')} />
              </GrayButton>
            </ListItem>
          )}

          {showAccount && (
            <UserCard
              user={user}
              toggleOpen={toggleOpen}
              isMember={hasActiveMembership}
            />
          )}

          <ListItem sx={{ display: 'block' }}>
            <MuiLink
              href="http://www.watchduty.org/"
              target="_blank"
              className={classes.link}
              variant="subtitle1"
              onClick={toggleOpen}
            >
              {t('drawerNav.about')}
            </MuiLink>
          </ListItem>
          <ListItem>
            <DebugData />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerNav;

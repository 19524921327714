import { App as CapacitorApp } from '@capacitor/app';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import * as SentryReact from '@sentry/react';
import * as Sentry from '@sentry/capacitor';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Snackbar from 'components/Snackbar';
import watchdutyTheme from 'theme';
import history from 'shared/history';
import useOnboardingState from 'state/useOnboardingState';
import { getDeviceInfo } from 'state/localStorageTyped';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { differenceInMinutes } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import {
  initPushNotifications,
  setCacheBusterTsFromNotifications,
} from '../shared/initPushNotifications';
import { useArePushNotificationsEnabled } from '../hooks/useNotificationsSettings';
import ErrorFallback from '../components/ErrorFallback';
import { useAuthState, useCacheState } from '../state';
import { CacheableQueryClientProvider } from '../components/CacheableQueryClientProvider';
import useForegroundState from '../hooks/useForegroundState';
import usePrevious from '../hooks/usePrevious';
import Navigation from './Navigation';

const PUSH_REREGISTER_FREQUENCY_MINUTES = 60;

const DeeplinkListener = (): null => {
  const myHistory = useHistory();
  const lastLaunchSlug = useRef<string | null>();
  const [workaroundPollCount, setWorkaroundPollCount] = useState<number>(0);

  const handleOpenUrl = useCallback(
    (url: string): void => {
      // Example url: https://app.watchduty.org/incident/1 > /incident/1
      const slug = url.split('watchduty.org').pop();
      if (slug && slug !== lastLaunchSlug.current) {
        lastLaunchSlug.current = slug;
        myHistory.push(slug);
      }
    },
    [myHistory],
  );

  CapacitorApp.addListener('appUrlOpen', (event) => {
    handleOpenUrl(event.url);
  });

  // This is a workaround for the issue where the appUrlOpen event is not fired
  // when the app is opened from a deeplink, after iOS has terminated the WebContent
  // process for the WKWebView for the app. This happens due to memory pressure on
  // the device. This is a known issue in Capacitor: TODO FILE A BUG WITH CAPACITOR
  //
  // It is only necessary to run this for the first N seconds after the app is initialized
  // in order to work around the issue described above.
  const checkForLaunchUrl = useCallback(() => {
    CapacitorApp.getLaunchUrl().then((appLaunchUrl) => {
      if (appLaunchUrl) {
        handleOpenUrl(appLaunchUrl.url);
      }
    });
    setWorkaroundPollCount((prevCount) => prevCount + 1);
  }, [handleOpenUrl]);
  useInterval(
    checkForLaunchUrl,
    // Run every second until the workaround period is over.
    workaroundPollCount < 30 ? 1000 : null,
  );

  return null;
};

const PushInitializer = (): null => {
  const myHistory = useHistory();
  const { setCacheState } = useCacheState();
  const { isPushEnabled } = useArePushNotificationsEnabled();
  const { onboardCompleted } = useOnboardingState();
  const deviceInfo = getDeviceInfo();
  const appState = useForegroundState();
  const prevAppStateActive = usePrevious(appState.isActive);
  const lastRunRef = useRef<Date>();
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    if (!isNative) return;
    // background OR cold-start -> foreground
    //   in the case of a cold-start, the async code running in setCacheBusterTsFromNotifications
    //   may not run before we start with queries, but it will have set the cacheBuster for subsequent queries
    if (!prevAppStateActive && appState.isActive) {
      setCacheBusterTsFromNotifications(setCacheState);
    }
  }, [prevAppStateActive, appState.isActive, setCacheState, isNative]);

  // this only needs to run once based on isPushEnabled every time the app is loaded
  // If the user hasn't given us permission yet, request this during the onboarding flow
  // without this useEffect, this is run every time the route changes.
  const platform = deviceInfo?.platform;
  useEffect(() => {
    if (!isPushEnabled) return;
    if (platform === 'android' && !onboardCompleted) {
      /**
       * Notifications are enabled by default on Android < 13, so we have to wait for the
       * onboarding flow to complete, if not we'll see a location permission request
       * as soon as we open the app (we get the user's position as part of the push
       * token registration). This does not causes issues with notifications because
       * the onboarding flow also inits and register a push token.
       */
      return;
    }

    // Re-run registration on app resume, limited to once an hour. initPushNotifications()
    // won't re-send the token to Watch Duty or localStorage if it's the same
    if (
      !appState.isActive ||
      (lastRunRef.current &&
        differenceInMinutes(new Date(), lastRunRef.current) <
          PUSH_REREGISTER_FREQUENCY_MINUTES)
    ) {
      return;
    }

    // cause re-initializing the push registrations
    initPushNotifications(myHistory, setCacheState).then(() => {
      lastRunRef.current = new Date();
    });
  }, [
    appState.isActive,
    isPushEnabled,
    platform,
    onboardCompleted,
    myHistory,
    setCacheState,
  ]);

  return null;
};

const SentryUserInitializer = (): null => {
  const { user } = useAuthState();
  if (user) {
    Sentry.setTag('user_id', user.id);
  }
  return null;
};

function App(): JSX.Element {
  return (
    <SentryReact.ErrorBoundary fallback={ErrorFallback}>
      <CacheableQueryClientProvider>
        <RecoilRoot>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* @ts-expect-error History expected error */}
            <Router history={history}>
              <PushInitializer />
              <SentryUserInitializer />
              <DeeplinkListener />
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={watchdutyTheme}>
                  <CssBaseline />
                  <Navigation />
                  <Snackbar />
                </ThemeProvider>
              </StyledEngineProvider>
            </Router>
          </LocalizationProvider>
        </RecoilRoot>
      </CacheableQueryClientProvider>
    </SentryReact.ErrorBoundary>
  );
}

export default App;

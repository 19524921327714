import { Button, Dialog, IconButton, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'state';
import { usePoisState } from 'state/usePoisState';
import useSearchDrawerState from 'state/useSearchDrawerState';
import { MAP_DOM_ELEMENT_ID } from '../../constants';
import { getMapDialogCommonStyles } from './utils';
import { useMapEntityDrawerState } from './MapEntityDrawer';

const useStyles = makeStyles<{
  isDrawerEntitySelected: boolean;
  isSearchDrawerOpen: boolean;
  interactive: boolean;
  minSnapPct: number;
}>()((
  theme,
  { isDrawerEntitySelected, isSearchDrawerOpen, interactive, minSnapPct },
) => {
  return {
    ...getMapDialogCommonStyles(theme, {
      isDrawerEntitySelected,
      isSearchDrawerOpen,
      interactive,
      minWidth: 362,
      minSnapPct,
    }),
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      pointerEvents: 'auto',
    },
  };
});

export const PoiDialog = (): JSX.Element | null => {
  const { open: isSearchDrawerOpen } = useSearchDrawerState();
  const {
    permissions: { canReport, isInternalUser },
  } = useAuthState();
  const { open: isDrawerEntitySelected, minSnapPct } =
    useMapEntityDrawerState();
  const { classes } = useStyles({
    isDrawerEntitySelected,
    isSearchDrawerOpen,
    interactive: canReport || isInternalUser,
    minSnapPct,
  });
  const {
    selectedPois,
    selectedPoi,
    activeIndex,
    clearSelectedPois,
    selectPreviousPoi,
    selectNextPoi,
  } = usePoisState();
  const { t } = useTranslation();

  return (
    <Dialog
      open={!!selectedPois.length}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root,
        container: classes.container,
        paper: classes.paper,
      }}
      hideBackdrop
      container={(): HTMLElement | null =>
        document.getElementById(MAP_DOM_ELEMENT_ID)
      }
    >
      {selectedPoi?.PoiDialogContent && <selectedPoi.PoiDialogContent />}
      {selectedPois.length > 1 && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'flex-end',
            mb: 1,
            mr: 1,
          }}
        >
          <Button
            variant="watchduty-text-uppercase"
            startIcon={<ChevronLeftIcon />}
            onClick={selectPreviousPoi}
            disabled={activeIndex === 0}
            sx={{
              color: 'text.primary',
              pointerEvents: 'auto',
            }}
          >
            {t('common.prev')}
          </Button>
          <Button
            variant="watchduty-text-uppercase"
            endIcon={<ChevronRightIcon />}
            onClick={selectNextPoi}
            disabled={activeIndex === selectedPois.length - 1}
            sx={{
              color: 'text.primary',
              pointerEvents: 'auto',
            }}
          >
            {t('common.next')}
          </Button>
        </Stack>
      )}
      <IconButton
        size="small"
        className={classes.closeButton}
        onClick={() => clearSelectedPois()}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

import { ReactNode } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
} from '@mui/material';
import { Membership } from 'shared/types';
import LegendIcon from './LegendIcon';
import UserStatusChip from '../UserStatusChip';

type LegendListItemProps =
  | {
      primaryText: string;
      secondary: ReactNode | string;
      mapLayer?: string;
      iconSrc?: string;
      iconSize?: number;
      minMembership?: Membership;
      iconBackground?: string;
    }
  | {
      primaryText: string;
      secondary: ReactNode | string;
      mapLayer?: string;
      iconColor?: string;
    };

const LegendListItem = (props: LegendListItemProps): JSX.Element => {
  const { primaryText, secondary } = props;

  if ('iconSrc' in props) {
    const {
      iconSrc,
      iconSize,
      minMembership = 'public',
      iconBackground,
    } = props;
    return (
      <ListItem alignItems="flex-start">
        {iconSrc && (
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar
              sx={{
                backgroundColor: iconBackground,
              }}
            >
              <LegendIcon src={iconSrc} alt={primaryText} size={iconSize} />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {primaryText}
              {minMembership !== 'public' && (
                <UserStatusChip
                  type={minMembership === 'pro' ? 'pro' : 'members'}
                  sx={{ marginLeft: 0.5 }}
                />
              )}
            </Box>
          }
          secondary={secondary}
          secondaryTypographyProps={{ component: 'span' }}
        />
      </ListItem>
    );
  }

  if ('iconColor' in props) {
    const { iconColor } = props;
    return (
      <ListItem alignItems="flex-start">
        {iconColor && (
          <ListItemAvatar>
            <Avatar>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  backgroundColor: iconColor,
                }}
              />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          primary={primaryText}
          secondary={secondary}
          secondaryTypographyProps={{ component: 'span' }}
        />
      </ListItem>
    );
  }

  return (
    <ListItem alignItems="flex-start">
      <ListItemText
        primary={primaryText}
        secondary={secondary}
        secondaryTypographyProps={{ component: 'span' }}
      />
    </ListItem>
  );
};

export default LegendListItem;

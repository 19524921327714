import { Chip, Stack, Theme, Typography } from '@mui/material';
import { FloodCategoryLevel } from '../../riverGauges.types';
import { floodCategoryColorsSolid } from '../../riverGauges.constants';

type CategoryChipsProps = {
  categoryLevels: FloodCategoryLevel[];
  units: string;
};

/**
 * Because chip backgrounds are not strictly MUI theme colors, we need to handle
 * text color accessibility manually.
 */
const backgroundSx = (categoryLevel: FloodCategoryLevel) => (theme: Theme) => {
  const backgroundColor = floodCategoryColorsSolid[categoryLevel.category];
  if (!backgroundColor) {
    return {};
  }

  return {
    backgroundColor,
    color: theme.palette.getContrastText(backgroundColor),
  };
};

export const CategoryChips = (props: CategoryChipsProps): JSX.Element => {
  const { categoryLevels, units } = props;

  return (
    <Stack spacing={1} direction="row">
      {categoryLevels.map((category) => (
        <Stack key={category.category} spacing={1}>
          <Chip
            label={
              <Typography textTransform="capitalize">
                {category.category}
              </Typography>
            }
            sx={backgroundSx(category)}
          />
          <Typography color="text.secondary" textAlign="center">
            {category.level} {units}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

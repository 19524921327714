import { RiverGauge, RiverGaugeObservation } from '../../riverGauges.types';
import { useRiverGauge } from '../useRiverGauge';
import { useRiverGaugeObservations } from '../useRiverGaugeObservations';

type UseRiverGaugeWithObservationsProps = {
  id: string;
  start: Date;
  end: Date;
  pollInterval?: number;
};

type UseRiverGaugeWithObservations = {
  riverGauge?: RiverGauge;
  observations?: RiverGaugeObservation[];
  forecasts?: RiverGaugeObservation[];
  isLoading: boolean;
  error: Error | null;
};

export const useRiverGaugeWithObservations = (
  props: UseRiverGaugeWithObservationsProps,
): UseRiverGaugeWithObservations => {
  const { id, start, end, pollInterval } = props;

  const {
    riverGauge,
    isLoading: isLoadingStation,
    error: riverGaugeError,
  } = useRiverGauge({ id });

  const {
    observations,
    forecasts,
    isLoading: isLoadingObservations,
    error: observationsError,
  } = useRiverGaugeObservations({ id, start, end, pollInterval });

  return {
    riverGauge,
    observations,
    forecasts,
    isLoading: isLoadingStation || isLoadingObservations,
    error: riverGaugeError || observationsError,
  };
};

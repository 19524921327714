import { useEffect, useMemo } from 'react';
import {
  useQuery,
  useMutation,
  QueryClient,
  UseMutateFunction,
} from '@tanstack/react-query';
import { InAppMessage, InboxMessage } from 'shared/types';
import { isMobile } from 'shared/utils';
import { getDateStringWithNoZeroTimeZone } from 'shared/dates';
import {
  LOCAL_STORAGE_KEY,
  getPushTokenFromLocalStorage,
} from 'state/localStorage';
import { API } from 'api';
import { persistedQueryClient } from 'components/CacheableQueryClientProvider';

type UseInboxMessagesProps = {
  refetchOnMount?: true;
};

type UseInboxMessagesHookResponse = {
  loading: boolean;
  error: boolean;
  setMessageAsRead: UseMutateFunction<number, unknown, number, unknown>;
  setMessagesAsRead: UseMutateFunction<number[], unknown, number[], unknown>;
  messages: InboxMessage[];
  unreadMessages: number;
  popupMessages: InboxMessage[];
};

/**
 * Returns merged inbox messages preserving the 'read' state of old messages,
 * in case messages are duplicated.
 */
const mergeMessages = (
  oldMessages: InboxMessage[],
  newMessages: InboxMessage[],
): InboxMessage[] => {
  return oldMessages.concat(newMessages).reduce((messages, message) => {
    /**
     * The client will not add messages that match a message already in their
     * local state if it matches on the ‘campaign' key. This is to avoid
     * duplicates when multiple sub campaigns may match the same user.
     */
    const existingMessage = messages.find(
      (msg) => msg.campaign === message.campaign,
    );
    if (!existingMessage) {
      messages.push(message);
    } else if (existingMessage.read || message.read) {
      existingMessage.read = true;
    }
    return messages;
  }, [] as InboxMessage[]);
};

const sortMessagesDescending = (messages: InboxMessage[]): InboxMessage[] => {
  return messages.sort((a, b) => {
    return (
      new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
    );
  });
};

const getLastFetchedDateString = (): string | null => {
  const dateString = localStorage.getItem(
    LOCAL_STORAGE_KEY.INBOX_LAST_FETCHED_DATE,
  );
  localStorage.setItem(
    LOCAL_STORAGE_KEY.INBOX_LAST_FETCHED_DATE,
    getDateStringWithNoZeroTimeZone(),
  );
  return dateString;
};

const fetchInAppMessages = async (
  pushToken: string | null,
): Promise<InAppMessage[]> => {
  const queryParams = new URLSearchParams();
  if (pushToken) queryParams.append('push_token', pushToken);
  const lastFetchedDate = getLastFetchedDateString();
  if (lastFetchedDate) queryParams.append('last_fetched_date', lastFetchedDate);
  const res = await API.get(`inbox_messages/?${queryParams.toString()}`);
  return res.data as InAppMessage[];
};

const fetchInboxMessages = async (
  queryClient: QueryClient,
  pushToken: string | null,
): Promise<InboxMessage[]> => {
  const inAppMessages = await fetchInAppMessages(pushToken);

  const oldMessages =
    queryClient.getQueryData<InboxMessage[]>(['inAppMessages']) ?? [];

  const newMessages: InboxMessage[] = (inAppMessages as InAppMessage[]).map(
    (message) => ({
      ...message,
      read: false,
    }),
  );

  return sortMessagesDescending(mergeMessages(oldMessages, newMessages));
};

export const useInboxMessages = (
  props: UseInboxMessagesProps = {},
): UseInboxMessagesHookResponse => {
  const { refetchOnMount = false } = props;
  const pushToken = getPushTokenFromLocalStorage();

  const query = useQuery(
    {
      queryKey: ['inAppMessages'],
      queryFn: () => fetchInboxMessages(persistedQueryClient, pushToken),
      refetchInterval: 1000 * 60 * 60 * 24, // 24 hours,
      staleTime: 1000 * 60 * 60 * 24, // 24 hours,
      gcTime: Infinity,
      enabled: isMobile(),
    },
    persistedQueryClient,
  );

  useEffect(() => {
    if (refetchOnMount) {
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: setMessageAsRead } = useMutation({
    mutationFn: async (messageId: number) => messageId,
    onSuccess: async (messageId) => {
      const cachedData = persistedQueryClient.getQueryData<InboxMessage[]>([
        'inAppMessages',
      ]);

      if (!cachedData) return;

      const messages = [...cachedData];

      const messageIdx = messages.findIndex(
        (message) => message.id === messageId,
      );

      if (messageIdx < 0) return;

      const message = messages[messageIdx];

      messages.splice(messageIdx, 1, {
        ...message,
        read: true,
      });

      persistedQueryClient.setQueryData(['inAppMessages'], messages);
    },
  });

  const { mutate: setMessagesAsRead } = useMutation({
    mutationFn: async (messageIds: number[]) => messageIds,
    onSuccess: async (messageIds) => {
      const cachedData = persistedQueryClient.getQueryData<InboxMessage[]>([
        'inAppMessages',
      ]);

      if (!cachedData) return;

      const messages = [...cachedData];

      messageIds.forEach((messageId) => {
        const messageIdx = messages.findIndex(
          (message) => message.id === messageId,
        );

        if (messageIdx >= 0) {
          const message = messages[messageIdx];

          messages.splice(messageIdx, 1, {
            ...message,
            read: true,
          });
        }
      });

      persistedQueryClient.setQueryData(['inAppMessages'], messages);
    },
  });

  const data = useMemo(() => {
    const messages: InboxMessage[] = [];
    let unreadMessages = 0;
    const popupMessages: InboxMessage[] = [];

    (query.data ?? []).forEach((message) => {
      if (!message.mapPopupOnly) {
        messages.push(message);
        if (!message.read) unreadMessages += 1;
      } else if (!message.read) {
        popupMessages.push(message);
      }
    });

    return {
      messages,
      unreadMessages,
      popupMessages,
    };
  }, [query.data]);

  return {
    loading: query.isLoading,
    error: query.isError,
    setMessageAsRead,
    setMessagesAsRead,
    ...data,
  };
};
